import React from 'react'
import FansLayout from '../../layouts/FansLayout'
import { Header, SideBar } from '../../components'

export const Fans = () => {
  return (
    <div>
      <Header/>
      <FansLayout/>
      <SideBar active={'fansList'}/>
    </div>
  )
}

