import React, { useState, useEffect } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { CardStaff, StaffSkelton } from "../../components";
import Pagination from "../../components/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { getStaffs } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";

export const StaffLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  // fetch all staffs
  const [paginatedStaffs, setPaginatedStaffs] = useState([]);
  const [allStaffs, setAllStaffs] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [searchedStaff, setSearchedStaff] = useState("");
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);
  // for pagination comp
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);

  let jobFilter = job;
  if (job === "") {
    jobFilter = null; // Remove the job filter when "All Staffs" is selected
  }
  const fetchPaginatedStaff = async (skip) => {
    setLoading(true);
    const { data, error, message } = await getStaffs(
      {
        take: pageSize,
        skip: Number(skip) ? Number(skip) * pageSize : 0,
        order: {
          createdAt: order,
        },
        where: {
          search: searchedStaff,
          job: jobFilter,
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch staffs failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setPaginatedStaffs(data?.allStaff);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaginatedStaff(currentPage);
  }, [order, searchedStaff, job, currentPage]);

  const fetchallStaffs = async (skip) => {
    setLoading(true);
    const { data, error, message } = await getStaffs({
      take: 1000,
      skip: 0,
    });
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch staffs failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setAllStaffs(data?.allStaff);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchallStaffs();
  }, []);

  return (
    <div className="layoutContainer">
      <div className="flex justify-between flex-wrap gap-10">
        <div className="text-[20px] flex justify-between items-center w-[125px]">
          {t("Team")} <DropDownIcon fill={"#133D6E"} /> {t("Staff")}
        </div>
        <div className="text-[12px]">
          <Link to={"/team/addNewStaff"}>
            <button className="h-[40px] w-[120px] sm:w-[220px] bg-[#2B9D96] rounded text-white">
              {t("Add new stuff")}
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
        <form className="flex justify-between flex-wrap gap-5 text-[12px]">
          <div className="text-[12px] flex flex-wrap gap-5 flex-1">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold">{t("Search")}</span>
              <input
                type="text"
                name="Search"
                placeholder="Search any"
                onChange={(e) => setSearchedStaff(e.target.value.toLowerCase())}
                className="w-[237px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] "
                required
              />
            </div>
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold">{t("Position")}</span>
              <select
                id="#"
                name="#"
                onChange={(e) => setJob(e.target.value)}
                value={job}
                className="w-[237px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px]"
              >
                <option value="">All Staffs </option>
                <option value="PRESIDENT">Presendent</option>
                <option value="SECTIONHEAD">Section Head</option>
                <option value="YOUTHLEADER">Youth Leader</option>
                <option value="OFFICIAL">Official</option>
                <option value="DEPARTEMENTMANAGER">Departement Manager</option>
                <option value="BOARDMEMBER">Board Member</option>
              </select>
            </div>
          </div>
        </form>
        <div className="mt-5 md:mt-10 flex flex-wrap items-center gap-5">
          <span className="font-bold">{t("Sort By")}</span>
          <div className="flex flex-wrap gap-5">
            <button
              className={`${
                order === "ASC" ? "active-button" : "disabled-button"
              }`}
              onClick={() => setOrder("DESC")}
            >
              {t("Added date (newest)")}
            </button>
            <button
              className={`${
                order === "DESC" ? "active-button" : "disabled-button"
              }`}
              onClick={() => setOrder("ASC")}
            >
              {t("Added date (oldest)")}
            </button>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 md:gap-3 lg:gap-4">
          {loading ? (
            <StaffSkelton />
          ) : paginatedStaffs && paginatedStaffs.length !== 0 ? (
            paginatedStaffs?.map((item, i) => (
              <Link to={`/team/staff/profile/${item.id}`}>
                <CardStaff
                  key={i}
                  imgSrc={item?.picture?.full_url}
                  name={item.firstName + " " + item.lastName}
                  Position={item.job}
                  phone={item.phoneNumber}
                  Email={item.email}
                  // handelViewProfileStuff={handelViewProfileStuff}
                />
              </Link>
            ))
          ) : (
            t("there is no staffs matched")
          )}
        </div>
        {/* pagination */}
          {(!job && allStaffs && allStaffs?.length > 0) || currentPage >= 1 ? (
            <div className="w-full flex justify-center mt-10">
              <Pagination
                count={allStaffs?.length}
                pagesize={pageSize}
                setPage={(e) => {
                  setCurrentPage(e);
                }}
                page={Number(currentPage) ? Number(currentPage) : 0}
                showPages={3}
              />
            </div>
          ) : null}
      </div>
    </div>
  );
};
