import React from 'react'
import { TrainerLayout } from '../../layouts/TrainerLayout'
import { Header, SideBar } from '../../components'
import { useParams } from "react-router-dom";

export const Trainer = () => {

  const { groupe } = useParams();
  
  return (
    <>
        <Header/>
        <TrainerLayout groupe = {groupe}/>
        <SideBar active={'trainer'}/>
    </>
  )
}
