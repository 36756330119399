import React from 'react'

const DropDownIcon = ({fill}) => {
  return (
    <svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.99991 2.99946L1.17192 5.82812L0.229248 4.88479L2.11458 2.99946L0.229248 1.11412L1.17191 0.170792L4.00058 2.99946L3.99991 2.99946Z" fill={fill}/>
    </svg>
  )
}

export default DropDownIcon