import React from 'react'
import { Skeleton } from '@mui/material'
import PaginationSkl from './PaginationSkl'

const GroupsSkeleton = () => {
  return (
    <>
      {[...Array(2)].map((item) => (
        <table className="flex flex-col text-[12px]">
        <tr className="flex">
          {[...Array(6)].map((item) => (
            <th>
                <Skeleton sx={{width:"100px"}}/>
            </th>
          ))}
        </tr>
        {[...Array(1)].map((item) => (
            <tr className="flex">
          {[...Array(6)].map((item) => (
            <th className='skleton group'>
                <Skeleton sx={{width:"100px"}}/>
            </th>
          ))}
        </tr>
        ))}
        </table>
      ))}
    </>
  )
}

export default GroupsSkeleton