import React from "react";
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
//Translation
import { useTranslation } from "react-i18next";
//parser
import parse from "html-react-parser";

const CardFeed = ({ title, description, imgSrc,isFeatured,buttonText }) => {
  const {t} = useTranslation();

  return (
    <div className=" relative w-full h-[265px] border border-[#C4C4C4] rounded-xl flex flex-col items-center  p-3">
      <div className="w-[75px] h-[105px] bg-[#F4F4F4] border-[0.5px] border-[#C4C4C4] rounded-full flex justify-center items-center">
        <div className="w-[60px] h-[60px] bg-[#D9D9D9] rounded-full overflow-hidden flex justify-between items-center ">
          <img className="w-full h-full" src={imgSrc} alt={title}/>
        </div>
        <div className="absolute top-2 right-2">
          {isFeatured && <DisplaySettingsIcon sx={{fontSize:"20px",color:"#aa8"}}/>}
        </div>
      </div>
        <div className="h-full w-full flex flex-col justify-between items-center text-center">
          <span className="text-[12px] mt-6 line-clamp-2 w-full">{title}</span>
          <span className="mt-2 text-[10px] text-[#7C858D] leading-[12px] line-clamp-3 w-full overflow-hidden">
            {parse(description)}
          </span>
            <button className="h-[25px] w-full bg-mainBlue rounded-sm text-[10px] text-white ">
              {buttonText ? buttonText : t("View article")}
            </button>
        </div>
    </div>
  );
};

export default CardFeed;
