import React from 'react'
import { Header, SideBar } from '../../components'
import FansListLayout from '../../layouts/FansListLayout'

export const FansList = () => {
  return (
    <>
        <Header/>
        <FansListLayout/>
        <SideBar active={'fansList'}/>
    </>
  )
}