import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TableLive from "../../components/TableLive";

//hooks
import { useAuth, useOnClickOutside, reorderGroupList } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// graphql
import {
  getAllGames,
  addNewGame,
  getGroups,
  getAllOpponents,
  addNewOpponent,
  uploadImage,
} from "../../graphql/services";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

//Translation
import { useTranslation } from "react-i18next";
import LiveSkeleton from "../../components/Skeleton/LiveSkeleton";

const LiveLayout = () => {
  const liveTableHeadItems = [
    "ID",
    "Group",
    "vs",
    "League/round",
    "Stadium",
    "Timing",
    "Status",
    "Edit",
  ];
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [games, setgames] = useState(null);
  const [loadingSkl, setLoadingSkl] = useState(false);
  const fetchGames = async () => {
    setLoadingSkl(true);
    const { data, error, message } = await getAllGames(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch lives failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoadingSkl(false);
      }
    } else {
      setgames(data?.games?.nodes);
      setLoadingSkl(false);
    }
  };

  const [opponents, setopponents] = useState(null);

  const fetchOpponents = async () => {
    const { data, error, message } = await getAllOpponents(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch opponents failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setopponents(data?.opponents?.nodes);
    }
  };

  const [groups, setgroups] = useState(null);
  const fetchGroups = async () => {
    const { data, error, message } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setgroups(reorderGroupList(data?.groups?.nodes));
    }
  };

  useEffect(() => {
    fetchGroups();
    fetchOpponents();
    fetchGames();
  }, []);

  const [form, setform] = useState({
    group: "",
    leagueAndRound: "",
    playingAgainst: "",
    stadium: "",
    timing: "",
  });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [opponent, setopponent] = useState(null);

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const checkfield = (form) => {
    let checked = false;
    if (form.group === "") {
      setErrorMessage(t("Select group"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (!opponent && form.playingAgainst === "") {
      setErrorMessage(t("Enter playing Against"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (form.leagueAndRound === "") {
      setErrorMessage(t("Enter league and round"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (form.stadium === "") {
      setErrorMessage(t("Enter stadium"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else {
      checked = true;
    }
    return checked;
  };

  const [loading, setloading] = useState(false);
  const handleCreatreLive = async (e) => {
    if (checkfield(form)) {
      if (form.playingAgainst === "") {
        if (!file) {
          setErrorMessage(t("Add apponent logo"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        } else {
          setloading(true);
          const resUpload = await uploadImage(file[0]);
          const {
            data: opponentData,
            error,
            message,
          } = await addNewOpponent(
            {
              createOpponentInput: {
                name: opponent,
                picture: parseInt(resUpload.id),
              },
            },
            TOKEN
          );
          if (error) {
            if (message === "Unauthorized") {
              dispatch(logout());
              navigate("/login");
            } else {
              setErrorMessage(t("Add opponent Failed"));
              setToastERROR(true);
              setToastType("error");
              handleClick();
              setloading(false);
            }
          } else {
            const { data, error, message } = await addNewGame(
              {
                input: {
                  group: parseInt(form.group),
                  leagueAndRound: form.leagueAndRound,
                  playingAgainst: parseInt(opponentData.id),
                  stadium: form.stadium,
                  timing: form.timing,
                  status: "PENDING",
                },
              },
              TOKEN
            );
            if (error) {
              if (message === "Unauthorized") {
                dispatch(logout());
                navigate("/login");
              } else {
                setErrorMessage(t("Add game Failed"));
                setToastERROR(true);
                setToastType("error");
                handleClick();
                setloading(false);
              }
            } else {
              setToastSuccess(t("Add game successfully"));
              setToastERROR(false);
              setToastType("success");
              handleClick();
              setloading(false);
              setform({
                group: "",
                leagueAndRound: "",
                playingAgainst: "",
                stadium: "",
                timing: "",
              });
              setfile(null);
              setopponent(null);
              fetchGames();
            }
          }
        }
      } else {
        setloading(true);
        const { data, error, message } = await addNewGame(
          {
            input: {
              group: parseInt(form.group),
              leagueAndRound: form.leagueAndRound,
              playingAgainst: parseInt(form.playingAgainst.id),
              stadium: form.stadium,
              timing: form.timing,
              status: "PENDING",
            },
          },
          TOKEN
        );
        if (error) {
          if (message === "Unauthorized") {
            dispatch(logout());
            navigate("/login");
          } else {
            setErrorMessage(t("Add game Failed"));
            setToastERROR(true);
            setToastType("error");
            handleClick();
            setloading(false);
          }
        } else {
          setToastSuccess(t("Add game successfully"));
          setToastERROR(false);
          setToastType("success");
          handleClick();
          setloading(false);
          fetchGames();
          setform({
            group: "",
            leagueAndRound: "",
            playingAgainst: "",
            stadium: "",
            timing: "",
          });
          setfile(null);
          setopponent(null);
        }
      }
    }
  };

  const [isOpponent, setisOpponent] = useState(false);
  const handleOpponent = (opponent) => {
    console.log(opponent);
    setform({ ...form, playingAgainst: opponent });
    setopponent(opponent.name);
    setfile(opponent.picture.full_url);
    setTimeout(() => {
      setisOpponent(false);
    }, 50);
  };

  const handlechangeOpponent = (value) => {
    let isOpponent = false;
    const searchText = value?.toLowerCase();
    if (searchText) {
      for (const opponent of opponents) {
        const text = opponent.name.toLowerCase();
        if (text.includes(searchText)) {
          isOpponent = true;
        }
        if (!opponents?.some((opp) => opp.name.toLowerCase() === searchText)) {
          setform({ ...form, playingAgainst: "" });
          setfile(null);
        }
      }
    } else {
      isOpponent = false;
    }
    setisOpponent(isOpponent);
  };

  useEffect(() => {
    handlechangeOpponent(opponent);
  }, [opponent]);

  const opponetList = useRef();
  useOnClickOutside(opponetList, () => {
    setisOpponent(false);
  });

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex justify-between">
          <div className="text-[20px] flex justify-between items-center w-[155px]">
            {t("Live Band")}
          </div>
          <div className="text-[12px]"></div>
        </div>
        <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
          <div className="flex flex-col text-[12px] w-full ">
            <div className="flex flex-wrap lg:justify-start justify-center gap-10 text-[12px] mb-10 ">
              <div className="flex flex-col text-[12px]">
                <span className="mb-1">{t("Group")}</span>
                <select
                  name="group"
                  value={form.group || ""}
                  onChange={handleChange}
                  className="w-full md:w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                >
                  {!adminGroup && <option value={""}>Select group</option>}
                  {groups &&
                    groups?.map((group, i) =>
                      adminGroup ? (
                        adminGroup?.some((obj) => obj.id === group.id) && (
                          <option value={group.id}>{group.name}</option>
                        )
                      ) : (
                        <option value={group.id}>{group.name}</option>
                      )
                    )}
                </select>
                <div className="flex flex-col">
                  <span className="mb-1">{t("Playing Against")} :</span>
                  <div className="flex gap-[.5rem]">
                    <div className="relative">
                      <input
                        type="text"
                        value={opponent || ""}
                        onChange={(e) => setopponent(e.target.value)}
                        autoComplete="off"
                        placeholder={t("Playing Against")}
                        className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                        required
                      />
                      <div
                        className={`absolute top-[40px] left-0 w-full ${
                          !isOpponent && "hidden"
                        }`}
                      >
                        <div
                          ref={opponetList}
                          className={`flex flex-col bg-white w-[230px] max-h-[300px] overflow-y-auto items-start border-[2px] border-[#ecedee] rounded-[0_0_5px_5px]`}
                        >
                          {opponents &&
                            opponents.map(
                              (opp, i) =>
                                opp.name
                                  .toLowerCase()
                                  .includes(opponent?.toLowerCase()) && (
                                  <button
                                    onClick={() => handleOpponent(opp)}
                                    className="p-[5px] flex items-center gap-[.5rem] w-full border-b border-b-[#ecedee]"
                                  >
                                    <div className="w-[25px] h-[25px] bg-[#ecedee] rounded-full overflow-hidden">
                                      <img
                                        src={opp.picture.full_url}
                                        className="object-cover"
                                        alt=""
                                      />
                                    </div>
                                    <span className="font-bold capitalize">
                                      {opp.name}
                                    </span>
                                  </button>
                                )
                            )}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => inputRef.current.click()}
                      className="h-[40px] w-[40px] rounded-full  overflow-hidden flex justify-center items-center cursor-pointer"
                    >
                      {file ? (
                        form.playingAgainst === "" ? (
                          <img
                            src={
                              typeof file === "object"
                                ? URL.createObjectURL(file[0])
                                : file[0]
                            }
                            alt="profile pic"
                            className="object-cover w-full h-full relative z-[0]"
                          />
                        ) : (
                          <img
                            src={file}
                            alt="profile pic"
                            className="object-cover w-full h-full relative z-[0]"
                          />
                        )
                      ) : (
                        <div className="flex flex-col items-center">
                          {" "}
                          <img
                            src="/svg/upload.svg"
                            alt="uplaod svg"
                            className="w-[22px] "
                          />
                          <span>Logo</span>
                        </div>
                      )}
                      <input
                        ref={inputRef}
                        type="file"
                        onChange={(e) => setfile(e.target.files)}
                        className="hidden"
                      />
                    </div>
                  </div>
                </div>
                <span className="mb-1">{t("League and round")} :</span>
                <input
                  type="text"
                  name="leagueAndRound"
                  value={form.leagueAndRound || ""}
                  onChange={handleChange}
                  placeholder={t("League and round")}
                  className="w-full md:w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                  required
                />
              </div>
              <div className="flex flex-col ml-0 lg:ml-10 text-[12px] w-full md:w-[230px]">
                <span className="mb-1">{t("Stadium")}</span>
                <input
                  type="text"
                  name="stadium"
                  value={form.stadium || ""}
                  onChange={handleChange}
                  placeholder="Stadium"
                  className=" w-full h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                  required
                />
                <span className="mb-1">{t("Match timing")}</span>
                <input
                  type="datetime-local"
                  name="timing"
                  value={form.timing || ""}
                  onChange={handleChange}
                  className=" w-full h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                />
              </div>
            </div>
            <button
              onClick={handleCreatreLive}
              className="h-[40px] md:w-[160px] w-full bg-black rounded text-white text-[12px]"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Add")}
            </button>
          </div>
          <div className="mt-10 flex flex-row flex-wrap overflow-scroll">
            <table className="flex flex-col ">
              <tr className="flex">
                {liveTableHeadItems.map((item) => (
                  <th className="live">{t(`${item}`)}</th>
                ))}
              </tr>
              {loadingSkl ? (
                <LiveSkeleton />
              ) : games && games.length !== 0 ? (
                games.map((live, i) => <TableLive i={i} game={live} />)
              ) : (
                t("There is no live game matched at the moment")
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveLayout;
