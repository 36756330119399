import React from 'react'
import { Header, SideBar } from '../../components'
import TrainerProfileLayout from '../../layouts/TrainerProfileLayout'

export const TrainerProfile = () => {
  return (
    <>
      <Header/>
      <TrainerProfileLayout/>
      <SideBar active={'trainer'}/>
    </>
  )
}