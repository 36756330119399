import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { useNavigate } from "react-router-dom";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import Editor from "../../components/Editor";

// graphql
import { addNewFeed, uploadImage } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";


const AddNewFeedLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    content: "",
    title: "",
    subtitle: "",
    isFeatured: false,
    picture: "",
  });
  const [textEditorvalue, setTextEditorvalue] = useState("");

  const handleChange = (e) => setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);

  const checkfield = (form) => {
    let checked = false;
    if (form.file === "") {
      setErrorMessage(t("Enter picture"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (form.title === "") {
      setErrorMessage(t("Enter title"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (textEditorvalue === "") {
      setErrorMessage(t("Enter content"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else {
      checked = true;
    }
    return checked;
  };

  const handleCreateFeed = async (e) => {
    if (checkfield(form)) {
      setloading(true);
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await addNewFeed(
        {
          input: {
            content: textEditorvalue,
            title: form.title,
            subtitle: form.subtitle,
            isFeatured: form.isFeatured,
            picture: parseInt(resUpload.id),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Add new feed Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
          navigate("/feeds/feedsList")
        }
      } else {
        setToastSuccess(t("Add new feed successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
        navigate("/feeds/feedsList")
      }
    }
  };



  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
          <div className="text-[20px] flex gap-1 items-center">
            <span>{t("Team")}</span>
            <div className="mx-2">
              <DropDownIcon fill={"#133D6E"} />
            </div>
            <span>{t("Add new feed")}</span>
          </div>
          <div className="text-[12px] flex items-center gap-[.5rem]">
            <button
              onClick={() => navigate(-1)}
              className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded mr-3"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleCreateFeed}
              className="h-[40px] w-[100px] sm:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white mt-10 min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-start  justify-center xl:justify-start p-10">
          <div className=" relative text-center h-fit mt-5  ">
            <button
              onClick={() => inputRef.current.click()}
              className="z-[5] absolute left-[50%] -translate-x-[50%] top-[-1rem]"
            >
              <img src="/svg/camera.svg" />
            </button>
            <div className=" flex justify-center items-center h-[137px] w-[206px] bg-[#D9D9D9] overflow-hidden">
              {file ? (
                <img
                  src={
                    typeof file === "object"
                      ? URL.createObjectURL(file[0])
                      : file[0]
                  }
                  alt="profile pic"
                  className=" w-full relative z-[0] "
                />
              ) : form.picture ? (
                <img
                  src={form.picture}
                  alt="profile pic"
                  className=" w-full relative z-[0] "
                />
              ) : (
                ""
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
            </div>
          </div>
          <div className="flex-1 flex flex-col lg:ml-2 text-[12px]">
            <span className="font-bold mb-2">{t("Article title")}</span>
            <input
              type="text"
              name="title"
              value={form.title || ""}
              onChange={handleChange}
              placeholder="Article title"
              className="w-[300px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              required
            />
            <span className="font-bold mb-2">{t("Content")}</span>
            <Editor
              value={textEditorvalue} // Pass value
              onChange={setTextEditorvalue} // Pass onChange function
              placeholder={t("Start typing...")}     
              heightEditor={"410px"}
            />
            <div className="mt-3 flex items-center">
              <input
                type="checkbox"
                onChange={(e) =>
                  setform({ ...form, isFeatured: e.target.checked })
                }
                value="Bike"
              />
              <span for="vehicle1" className="ml-2 font-bold">
                {t("Publish")}
              </span>
            </div>
          </div>
          <div className="hidden xl:block absolute h-[240px] w-[1px] left-[300px] top-[120px] bg-[#D8D8D8]">
            {/* this div for the vertical line */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewFeedLayout;
