import React from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';


const TableInbox = ({ i, message, changeMsgStat }) => {
  return (
    <tr>
      <td className="relative">
          {message.firstName}
          {message.lasttName}
        <span className=" absolute right-10 ">
          {!message?.seen && <MarkEmailUnreadRoundedIcon style={{fontSize:"14px",color:"#FF8A8A"}}/>}
        </span>
      </td>
      <td>
        <span className="flex justify-center py-3 px-2">
          <p className="line-clamp-3">
            {message.message}
          </p>
        </span>
      </td>
      <td>
        <div className="flex justify-center ">
          <span className=" mr-2">{message.phoneNumber}</span>
        </div>
      </td>
      <td>
        <div className="flex justify-center ">
          <span className=" mr-2">{message.email}</span>
        </div>
      </td>
      <td>
        <div className="flex justify-center ">
          <span className=" mr-2">
            {dayjs(message.createdAt).format("HH:mm A DD/MM/YYYY")}
          </span>
        </div>
      </td>
      <td>
        <div className="flex justify-center items-center">
          <Link to={`/inbox/slug/${message.id}`}>
            <button onClick={()=> changeMsgStat(message?.id)}>
              <VisibilityIcon />
            </button>
          </Link>
        </div>
      </td>
    </tr>
  );
};

export default TableInbox;
