import React from "react";
//Translation
import { useTranslation } from "react-i18next";

const TableFansList = ({ i, fan, setManage, setTableRow, setFansIniStatus }) => {
  const { t } = useTranslation();
  return (
    <tr key={i} className="">
      <td className="p-[1rem]">{fan.firstName}</td>
      <td>{fan.lastName}</td>
      <td>
        <div className="flex justify-center items-center">
          {fan.status === "PENDING" ? (
            <div className="flex justify-center items-center">
              <button
                className="mr-3 text-[#21861E] font-[600]"
                onClick={() => {setManage({ id: fan.id, status: "APPROVED" })}}
              >
                {t("Approve")}
              </button>
              <button
                className="text-[#C04337] font-[600]"
                onClick={() => setManage({ id: fan.id, status: "REFUSED" })}
              >
                {t("Refuse")}
              </button>
            </div>
          ) : (
            <button onClick={() => setTableRow({ id: fan.id })}>
              <img src="/svg/delete2.svg" />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default TableFansList;
