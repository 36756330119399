import React from 'react'
import TrainingTimeLayout from '../../layouts/TrainingTimeLayout'
import { Header, SideBar } from '../../components'
import { useParams } from "react-router-dom";

export const TrainingTime = () => {
  const { groupId } = useParams();
  return (
    <>
        <Header/>
        <TrainingTimeLayout groupId ={groupId}/>
        <SideBar active={'trainingTime'}/>
    </>
  )
}
