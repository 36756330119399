export const routes = [
  {
    title: "Dashboard",
    icon: "/svg/dashboardSideBar.svg",
    active: "dashboard",
    link: "/dashboard",
  },
  {
    title: "Club settings",
    icon: "/svg/ClubSideBar.svg",
    active: "clubSetting",
    link: "/clubSetting",
  },
  {
    title: "Carousel Slider",
    icon: "/svg/FeedsSideBar.svg",
    active: "carouselSlider",
    link: "/carouselSlider",
  },
  {
    title: "Team",
    icon: "/svg/TeamSideBar.svg",
    active: "groups",
    link: "/team/groups",
    items: [
      {
        title: "Groups",
        active: "groups",
        link: "/team/groups",
      },
      {
        title: "Add new Group",
        active: "addNewGroup",
        link: "/team/addNewGroup",
      },
      {
        title: "Players",
        active: "players",
        link: "/team/players",
      },
      {
        title: "Add new player",
        active: "addNewPlayer",
        link: "/team/addNewPlayer",
      },
      {
        title: "Staff",
        active: "staff",
        link: "/team/staff",
      },
      {
        title: "Add new staff",
        active: "addNewStaff",
        link: "/team/addNewStaff",
      },
      {
        title: "Trainer",
        active: "trainer",
        link: "/team/trainer",
      },
      {
        title: "Add new Trainer",
        active: "addNewTrainer",
        link: "/team/addNewTrainer",
      },
      {
        title: "Training Time",
        active: "trainingTime",
        link: "/team/trainingTime",
      },
      {
        title: "Add new training time",
        active: "addNewTrainingTime",
        link: "/team/addNewTrainingTime",
      },
    ],
  },
  {
    title: "Feeds",
    icon: "/svg/FeedsSideBar.svg",
    active: "feedsList",
    link: "/feeds/feedsList",
    items: [
      {
        title: "Feeds list",
        active: "feedsList",
        link: "/feeds/feedsList",
      },
      {
        title: "Add new feed",
        active: "addNewFeed",
        link: "/feeds/addNewFeed",
      },
    ],
  },
  {
    title: "Events",
    active: "events",
    link: "/events",
  },
  {
    title: "Sponsors",
    icon: "/svg/SponsorsSideBar.svg",
    active: "sponsorsMembers",
    link: "/sponsors/sponsorsMembers",
    items: [
      {
        title: "Sponsors members",
        active: "sponsorsMembers",
        link: "/sponsors/sponsorsMembers",
      },
      {
        title: "Add new sponsor",
        active: "addNewSponsor",
        link: "/sponsors/addNewSponsor",
      },
    ],
  },
  {
    title: "Fans",
    icon: "/svg/FansSideBar.svg",
    active: "fansList",
    link: "/fans/fansList",
    items: [
      {
        title: "Fans list",
        active: "fansList",
        link: "/fans/fansList",
      },
      // {
      //   title: "Manage Fans",
      //   active: "manageFans",
      //   link: "/fans/manageFans",
      // },
    ],
  },
  {
    title: "Inbox",
    icon: "/svg/InsboxSideBar.svg",
    active: "inbox",
    link: "/inbox",
  },
  {
    title: "Live",
    icon: "/svg/InsboxSideBar.svg",
    active: "live",
    link: "/live",
  },
  {
    title: "Opponent",
    icon: "/svg/opponent.svg",
    active: "opponent",
    link: "/opponent",
  },
  {
    title: "Shop",
    active: "shop",
    link: "/shop",
  },
  {
    title: "Kantine",
    active: "kantine",
    link: "/kantine",
  },
  {
    title: "Roles",
    icon: "/svg/roles.svg",
    active: "roles",
    link: "/roles",
  },
];
