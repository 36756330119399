import { client } from "../config";
import { ADD_NEW_FEED, UPDATE_FEED, DELETE_FEED, ADD_NEW_EVENT, UPDATE_EVENT, DELETE_EVENT } from "../mutations";
import { GET_FEED } from "../queries";
import { GET_ALL_EVENTS } from "../queries/events.query";

export const getAllEvents = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_EVENTS,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

/* export const getFeed = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_FEED,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
}; */

export const addNewEvent = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_NEW_EVENT,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createNews,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateEvent = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_EVENT,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updateNews,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteEvent = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_EVENT,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.deleteNews,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
