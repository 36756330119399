import React from "react";
import { useParams } from "react-router-dom";

import LiveDetailSlugLayout from "../../layouts/LiveDetailSlugLayout";
import { Header, SideBar } from "../../components";

const LiveDetailSlug = () => {
  const { liveDetailId } = useParams();
  return (
    <>
      <Header />
      <LiveDetailSlugLayout liveDetailId={liveDetailId} />
      <SideBar active={"live"} />
    </>
  );
};

export default LiveDetailSlug;
