import { gql } from "@apollo/client";

export const GET_ALL_INBOXES = gql`
  query Fans(
    $skip: Int!
    $take: Int!
    $order: SortByFilterArgs
    $where: FilterContactEntryInput
  ) {
    contacts(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      totalUnread
      nodes {
        id
        firstName
        lastName
        message
        phoneNumber
        email
        createdAt
        seen
      }
    }
  }
`;

export const GET_INBOX = gql`
  query Contact($contactId: Int!) {
    contact(id: $contactId) {
      id
      firstName
      lastName
      message
      phoneNumber
      email
      createdAt
    }
  }
`;
