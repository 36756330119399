import React from "react";
import { Header, SideBar } from "../../components";
import OpponentLayout from "../../layouts/OpponentListLayout";

export const Opponent = () => {
  return (
    <>
      <Header />
      <OpponentLayout />
      <SideBar active={"opponent"} />
    </>
  );
};
