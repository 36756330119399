import { Skeleton } from '@mui/material'
import React from 'react'

const InboxSlugSkel = () => {
  return (
    <div className="layoutContainer">
          <div className="flex flex-wrap justify-between gap-10">
            <Skeleton sx={{width:"150px"}}/>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <Skeleton variant='rectangular' sx={{width:"180px" , height:"40px"}}/>
            </div>
          </div>
          <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
            <div className=" flex justify-between">
              <div className=" flex flex-col">
                <Skeleton sx={{width:"150px" , height:"40px" , marginBottom:"1rem"}} />
                <Skeleton sx={{width:"160px"}} />
                <Skeleton sx={{width:"140px"}} />
              </div>
              <Skeleton sx={{width:"150px", height:"20px"}} />
            </div>
            <hr className=" my-5 text-[#979797]" />
            <p className="text-[14px] text-[#323334]"></p>
            <Skeleton sx={{width:"100%"}} />
            <Skeleton sx={{width:"100%"}} />
            <Skeleton sx={{width:"100%"}} />
          </div>
        </div>
  )
}

export default InboxSlugSkel