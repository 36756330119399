import { gql } from "@apollo/client";

export const GET_ALL_PODUCTS = gql`
query Query {
      products {
        nodes {
          id
          name
          pictures {
            id
            full_url
          }
          description
          price
        }
        totalCount
      }
    }
  
`;

/* export const GET_PRODUCT = gql`
  query PartnerById($partnerByIdId: Int!) {
    partnerById(id: $partnerByIdId) {
      id
      link
      description
      logo {
        full_url
      }
      name
    }
  }
`; */
