import React, { useState, useEffect } from "react";

import { Fragment } from "react";

//mui
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";

//mui icon
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";

//traduction
import { configLng } from "../../translations/config";
import { useTranslation } from "react-i18next";

export const TraductionButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation();
  const [languages, setLanguages] = useState(configLng?.languages);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (languages.length < 1) {
      if (
        languages.some((obj) => obj.code === i18n.language) &&
        configLng?.defaultlanguage === i18n.language
      ) {
      } else {
        i18n.changeLanguage(configLng?.defaultlanguages);
      }
    } else {
      if (!languages.some((obj) => obj.code === i18n.language)) {
        i18n.changeLanguage(configLng?.defaultlanguages);
      }
    }
  }, [languages]);
  return (
    <Fragment>
      <Box>
        <Tooltip title={`${i18n.language === "gr" ? "german" : "English"}`}>
          <button
            onClick={handleClick}
            className="inline-flex justify-center w-full rounded-md py-2  text-sm font-medium items-center text-gray-700 focus:outline-none"
          >
            <img
              loading="lazy"
              className="h-4 md:w-8"
              src={`https://flagcdn.com/w20/${languages
                .find((l) => l.code === i18n.language)
                ?.flagCode?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${languages
                .find((l) => l.code === i18n.language)
                ?.flagCode?.toLowerCase()}.png 2x`}
              alt=""
            />
            <ArrowDownIcon />
          </button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {languages.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              i18n.changeLanguage(item.code);
            }}
          >
            <a className={" text-sm flex gap-6 items-center cursor-pointer"}>
              <div className="border shadow-md border-gray-300 drop-shadow-md">
                <img
                  loading="lazy"
                  width="24"
                  src={`https://flagcdn.com/w20/${item?.flagCode?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${item?.flagCode?.toLowerCase()}.png`}
                  alt=""
                  className="border border-red-900 "
                />
              </div>
              {item?.code?.toUpperCase()}
            </a>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};
