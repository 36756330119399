import { Skeleton } from '@mui/material'
import React from 'react'

const LiveSkeleton = () => {
    return (
        <table className="flex flex-col text-[12px]">
            <tr className="flex">
              {[...Array(8)].map((item) => (
                <th className='LiveSkelton'>
                    <Skeleton sx={{width:"100px"}}/>
                </th>
              ))}
            </tr>
            {[...Array(1)].map((item) => (
                <tr className="flex">
              {[...Array(8)].map((item) => (
                <th className='LiveSkelton'>
                    <Skeleton sx={{width:"100px"}}/>
                </th>
              ))}
            </tr>
            ))}
        </table>
      )
}

export default LiveSkeleton