import React from "react";
import { useParams } from "react-router-dom";

import { Header, SideBar } from "../../components";
import OpponentUpdateLayout from "../../layouts/OpponentUpdateLayout.js";

const OppoentUpdate = () => {
  const { opponentId } = useParams();

  return (
    <>
      <Header />
      <OpponentUpdateLayout opponentId={opponentId} />
      <SideBar active={"opponent"} />
    </>
  );
};

export default OppoentUpdate;
