import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { useNavigate } from "react-router-dom";

//hooks
import { useAuth, reorderGroupList } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import { addNewPlayer, uploadImage, getGroups } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";
import resizeFile from "../../hooks/resizeFile";

const AddNewPlayerLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [groups, setgroups] = useState(null);
  const fetchGroups = async () => {
    const { data } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    setgroups(reorderGroupList(data?.groups?.nodes));
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const [form, setform] = useState({
    // dateOfBirth: "",
    firstName: "",
    group: "",
    lastName: "",
    number: "",
    picture: "",
    position: "",
  });

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleCreatrePlayer = async (e) => {
    setloading(true);
    if (file) {
      const resizedImage = await resizeFile(file[0], 210, 210)
      const resUpload = await uploadImage(resizedImage);
      const { data, error, message } = await addNewPlayer(
        {
          createPlayerInput: {
            // dateOfBirth: form.dateOfBirth,
            firstName: form.firstName,
            group: parseInt(form.group),
            lastName: form.lastName,
            number: parseInt(form.number),
            picture: parseInt(resUpload.id),
            position: form.position,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Add new player Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Add new player successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await addNewPlayer(
        {
          createPlayerInput: {
            // dateOfBirth: form.dateOfBirth,
            firstName: form.firstName,
            group: parseInt(form.group),
            lastName: form.lastName,
            number: parseInt(form.number),

            position: form.position,
          },
        },
        TOKEN
      );
      if (error) {
        setErrorMessage(t("Add new player Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      } else {
        setToastSuccess(t("Add new player successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };

  const positions = [
    "GK",
    "FB",
    "RB",
    "RL",
    "RWB",
    "RLB",
    "SW",
    "CB",
    "CDM",
    "CM",
    "CAM",
    "RM",
    "LM",
    "RW",
    "LW",
    "FWD",
  ];

  const [position, setposition] = useState(null);
  const [isPosition, setisPosition] = useState(false);
  const handlePosition = (position) => {
    setform({ ...form, position: position });
    setposition(position);
    setTimeout(() => {
      setisPosition(false);
    }, 50);
  };

  const handlechangePosition = (value) => {
    let isposition = false;
    const searchText = value?.toLowerCase();
    if (searchText) {
      for (const position of positions) {
        const text = position.toLowerCase();
        if (text.includes(searchText)) {
          isposition = true;
        }
      }
    } else {
      isposition = false;
    }
    setisPosition(isposition);
  };

  useEffect(() => {
    handlechangePosition(position);
  }, [position]);

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
          <div className="text-[20px] flex justify-between items-center">
            {t("Team")}
            <div className="mx-2">
              <DropDownIcon fill={"#133D6E"} />
            </div>
            {t("Add new player")}
          </div>
          <div className="text-[12px] flex items-center gap-[.5rem]">
            <button
              onClick={() => navigate(-1)}
              className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded "
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleCreatrePlayer}
              className="h-[40px] w-[100px] sm:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
          <div className=" relative text-center">
            <button
              onClick={() => inputRef.current.click()}
              className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-[5]"
            >
              <img src="/svg/camera.svg" />
            </button>
            <div className="h-[153px] w-[153px] rounded-full bg-[#D9D9D9] overflow-hidden flex justify-center items-center">
              {file ? (
                <img
                  src={
                    typeof file === "object"
                      ? URL.createObjectURL(file[0])
                      : file[0]
                  }
                  alt="profile pic"
                  className="object-cover w-full h-full relative z-[0]"
                />
              ) : form.picture ? (
                <img
                  src={form.picture}
                  alt="profile pic"
                  className="object-cover w-full h-full relative z-[0]"
                />
              ) : (
                ""
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-center gap-10 text-[12px] lg:ml-10 sm:mt-10 lg:mt-0">
            <div className="flex flex-col lg:ml-10 text-[12px]">
              <span className="mb-1">{t("First name")}</span>
              <input
                type="text"
                name="firstName"
                value={form.firstName || ""}
                onChange={handleChange}
                placeholder="First name"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
              <span className="mb-1">{t("Last name")}</span>
              <input
                type="text"
                name="lastName"
                value={form.lastName || ""}
                onChange={handleChange}
                placeholder="Last name"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
              {/* <span className="mb-1">{t("Date of Birth")}</span>
              <input
                type="date"
                id="start"
                name="dateOfBirth"
                value={form.dateOfBirth || ""}
                onChange={handleChange}
                min="1970-01-01"
                max="2020-10-31"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
              /> */}
              <span className="mb-1">{t("Member in group")}</span>
              <select
                name="group"
                value={form.group || ""}
                onChange={handleChange}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              >
                {!adminGroup && <option value={""}>Select group</option>}
                {groups &&
                  groups?.map((group, i) =>
                    adminGroup ? (
                      adminGroup?.some((obj) => obj.id === group.id) && (
                        <option value={group.id}>{group.name}</option>
                      )
                    ) : (
                      <option value={group.id}>{group.name}</option>
                    )
                  )}
              </select>
            </div>
            <div className="flex flex-col lg:ml-10 text-[12px]">
              <div className="relative flex flex-col">
                <span className="mb-1">{t("Position")}</span>
                <input
                  type="text"
                  name="position"
                  value={position || ""}
                  onChange={(e) => setposition(e.target.value)}
                  placeholder="Position"
                  autoComplete="off"
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                />
                <div
                  className={`absolute top-[62px] left-0 w-full ${
                    !isPosition && "hidden"
                  }`}
                >
                  <div
                    className={`flex flex-col bg-white w-[230px] items-start border-[2px] border-[#ecedee] rounded-[0_0_5px_5px]`}
                  >
                    {positions.map(
                      (pos, i) =>
                        pos.toLowerCase().includes(position?.toLowerCase()) && (
                          <button
                            onClick={() => handlePosition(pos)}
                            className="p-[5px] w-full border-b border-b-[#ecedee]"
                          >
                            {pos}
                          </button>
                        )
                    )}
                  </div>
                </div>
              </div>
              {/* <select
                name="position"
                value={form.position || ""}
                onChange={handleChange}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              >
                <option value="">{t("Position")}</option>
                <option value="GK">GK</option>
                <option value="FB">FB</option>
                <option value="RB">RB</option>
                <option value="RL">RL</option>
                <option value="RWB">RWB</option>
                <option value="RLB">RLB</option>
                <option value="SW">SW</option>
                <option value="CB">CB</option>
                <option value="CDM">CDM</option>
                <option value="CM">CM</option>
                <option value="CAM">CAM</option>
                <option value="RM">RM</option>
                <option value="LM">LM</option>
                <option value="RW">RW</option>
                <option value="LW">LW</option>
                <option value="FWD">FWD</option>
              </select> */}
              <span className="mb-1">{t("Number")}</span>
              <input
                type="number"
                min="1"
                max="99"
                name="number"
                value={form.number || ""}
                onChange={handleChange}
                placeholder="min 1 to max 99"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
          </div>
          <div className="hidden xl:block absolute h-[240px] w-[1px] left-[300px] top-[140px] bg-[#D8D8D8]">
            {/* this div for the vertical line */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewPlayerLayout;
