import React from 'react'
import AddNewSponsorLayout from '../../layouts/AddNewSponsorLayout'
import { Header, SideBar } from '../../components'

export  const AddNewSponsor = () => {
  return (
    <>
        <Header/>
        <AddNewSponsorLayout/>
        <SideBar active={'addNewSponsor'}/>
    </>
  )
}
