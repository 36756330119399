import { Skeleton } from '@mui/material';
import React from 'react'

const LiveDetailSkl = () => {
  return (
    <>
      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-5">
            <div className="text-[20px] flex gap-2 items-center">
                <Skeleton variant='rounded' sx={{width:'300px' ,height:"45px"}}/>
            </div>
          <div className="text-[12px] flex items-center gap-3">
            <Skeleton variant='rounded' sx={{width:'100px', height:"40px"}}/>
            <Skeleton variant='rounded' sx={{width:'100px', height:"40px"}}/>
          </div>
        </div>

        <div className="bg-white mt-10 h-fit flex flex-wrap justify-between  gap-[50px] lg:gap-10 items-center  lg:justify-start px-8 py-5">
          <div className="flex flex-wrap gap-4 justify-center xl:justify-between items-center w-full">
            <div className="flex flex-wrap gap-3">
              <div className="flex items-center">
                <Skeleton variant='rounded' sx={{width:"150px" , height:"25px"}}/>
                <span className="text-[#979797] text-[24px] ml-3">vs</span>
                <Skeleton variant='rounded' sx={{width:"150px" , height:"25px" , marginLeft:"15px"}}/>
              </div>
            </div>
            <div className="flex flex-wrap justify-center items-center gap-2">
              <div className="flex items-center">
                <span className="text-[12px] font-bold mr-3">Minute</span>
                <Skeleton variant='rounded' sx={{width:"200px" , height:"40px"}}/>
              </div>
              <div>
                  <Skeleton variant='rounded' sx={{width:"100px", height:"40px"}}/>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white text-[12px] mt-10 h-fit flex flex-wrap justify-between items-end px-8 py-4">
          <div className="flex flex-wrap justify-center items-center gap-5">
            <div className="flex flex-col gap-2">
              <span className="text-[12px]">Event</span>
              <Skeleton variant='rounded' sx={{width:"150px" , height:"40px"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-[12px]">Player</span>
              <Skeleton variant='rounded' sx={{width:"150px" , height:"40px"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-[12px]">Minute</span>
              <Skeleton variant='rounded' sx={{width:"150px" , height:"40px"}}/>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-[12px]">Comment</span>
              <Skeleton variant='rounded' sx={{width:"150px" , height:"40px"}}/>
            </div>
          </div>
          <Skeleton variant='rounded' sx={{width:"100px", height:"40px"}}/>
        </div>
        {[...Array(3)].map(()=>(
            <Skeleton sx={{width:"100%",height:"40px"}}/>
          ))}
      </div>
    </>
  )
}

export default LiveDetailSkl