import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { useNavigate } from "react-router-dom";
//hooks
import { useAuth, reorderGroupList } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

//services
import { addTrainer, getGroups, uploadImage } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const AddNewTrainerLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };
  const [groups, setgroups] = useState(null);
  const fetchGroups = async () => {
    const { data } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    setgroups(reorderGroupList(data?.groups?.nodes));
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const [form, setform] = useState({
    firstName: "",
    group: "",
    lastName: "",
    picture: "",
    position: "",
  });
  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleCreateTrainer = async (e) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await addTrainer(
        {
          createTrainerInput: {
            firstName: form.firstName,
            group: parseInt(form.group),
            lastName: form.lastName,
            picture: parseInt(resUpload.id),
            position: form.position,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Add trainer Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Trainer added successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await addTrainer(
        {
          createTrainerInput: {
            firstName: form.firstName,
            group: parseInt(form.group),
            lastName: form.lastName,
            picture: null,
            position: form.position,
          },
        },
        TOKEN
      );
      if (error) {
        setErrorMessage(t("Add trainer Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      } else {
        setToastSuccess(t("Trainer added successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
        navigate("/team/trainer");
      }
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
          <div className="text-[20px] flex justify-between items-center">
            {t("Team")}
            <div className="mx-2">
              <DropDownIcon fill={"#133D6E"} />
            </div>
            {t("Add new Trainer")}
          </div>
          <div className="text-[12px]">
            <button
              onClick={() => navigate(-1)}
              className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded mr-3"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleCreateTrainer}
              className="h-[40px] w-[100px] sm:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
          <div className=" relative text-center ">
            <button
              onClick={() => inputRef.current.click()}
              className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-[5]"
            >
              <img src="/svg/camera.svg" />
            </button>
            <div className="h-[153px] w-[153px] rounded-full bg-[#D9D9D9] overflow-hidden flex justify-center items-center">
              {file ? (
                <img
                  src={
                    typeof file === "object"
                      ? URL.createObjectURL(file[0])
                      : file[0]
                  }
                  alt="profile pic"
                  className="object-cover w-full h-full relative z-[0]"
                />
              ) : form.picture ? (
                <img
                  src={form.picture}
                  alt="profile pic"
                  className="object-cover w-full h-full relative z-[0]"
                />
              ) : (
                ""
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
            </div>
          </div>
          <form className="flex flex-wrap justify-center gap-10 text-[12px] lg:ml-10 sm:mt-10 lg:mt-0">
            <div className="flex flex-col lg:ml-10 text-[12px]">
              <span className="mb-1">{t("First name")}</span>
              <input
                type="text"
                name="firstName"
                value={form.firstName || ""}
                onChange={handleChange}
                placeholder={t("First name")}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
              <span className="mb-1">{t("Last name")}</span>
              <input
                type="text"
                name="lastName"
                value={form.lastName || ""}
                onChange={handleChange}
                placeholder={t("Last name")}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
            <div className="flex flex-col lg:ml-10 text-[12px]">
              <span className="mb-1">{t("Group")}</span>
              <select
                name="group"
                value={form.group || ""}
                onChange={handleChange}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              >
                {!adminGroup && <option value={""}>Select group</option>}
                {groups &&
                  groups?.map((group, i) =>
                    adminGroup ? (
                      adminGroup?.some((obj) => obj.id === group.id) && (
                        <option value={group.id}>{group.name}</option>
                      )
                    ) : (
                      <option value={group.id}>{group.name}</option>
                    )
                  )}
              </select>
              <span className="mb-1">{t("Trainer Position")}</span>
              <input
                type="text"
                name="position"
                placeholder={t("Trainer Position")}
                value={form.position || ""}
                onChange={handleChange}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px]"
              />
            </div>
          </form>
          <div className="hidden xl:block absolute h-[240px] w-[1px] left-[300px] top-[140px] bg-[#D8D8D8]">
            {/* THIS DIV IS FOR VERTICAL LINE */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewTrainerLayout;
