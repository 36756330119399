import { gql } from "@apollo/client";


export const CREATE_CAROUSEL = gql`
    mutation CreateCarousel($createCarouselInput: CreateCarouselInput!) {
        createCarousel(createCarouselInput: $createCarouselInput) {
            id
        }
    }
`;
export const DELETE_CAROSEL = gql`
mutation Mutation($removeCarouselId: Int!) {
    removeCarousel(id: $removeCarouselId)
  }
`;