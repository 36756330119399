import { client } from "../config";
import { ADD_NEW_PLAYER, UPDATE_PLAYER, DELETE_PLAYER } from "../mutations";
import {
  GET_ALL_PLAYERS,
  GET_PLAYER,
  GET__PLAYERS,
  GET__PLAYERS_BY_GR,
} from "../queries";

export const getAllPlayers = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_PLAYERS,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
export const AllPlayersByGr = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET__PLAYERS_BY_GR,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getPlayer = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_PLAYER,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const addNewPlayer = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_NEW_PLAYER,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createPlayer,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updatePlayer = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PLAYER,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updatePlayer,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deletePlayer = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_PLAYER,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.removePlayer,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
