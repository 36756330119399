import React from 'react'
import { Header, SideBar } from '../../components'
import ManageFansLayout from '../../layouts/ManageFansLayout'

export const ManageFans = () => {
  return (
    <>
        <Header/>
        <ManageFansLayout/>
        <SideBar active={'manageFans'}/>
    </>
  )
}