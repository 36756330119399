import React from "react";
import { useParams } from "react-router-dom";

import { Header, SideBar } from "../../components";
import PlayerProfileLayout from "../../layouts/PlayerProfileLayout";

export const PlayerProfile = () => {
  const { playerId } = useParams();

  return (
    <>
      <Header />
      <PlayerProfileLayout playerId={playerId} />
      <SideBar active={"players"} />
    </>
  );
};
