import React from "react";
//Translation
import { useTranslation } from "react-i18next";

const CardTrainingTime = ({
  key,
  //   handelViewProfileTrainer,
  team,
  trainingTime,
  day,
}) => {
  const {t} = useTranslation();
  return (
    <div
      key={key}
      className="w-full h-[145px] border border-[#C4C4C4] rounded-xl flex flex-col items-center p-3"
    >
      <span className="text-[12px] font-bold">{day}</span>
      <span className="text-[12px] font-[400] text-black/40 mt-2">
        {trainingTime}
      </span>
      <span className="text-mainBlue text-[8px] mt-4">{team}</span>
      <button
        // onClick={handelViewProfileTrainer}
        className="h-[25px] w-[100px] md:w-[135px] bg-mainBlue rounded-sm text-[10px] text-white mt-4"
      >
        {t("Edit or Delete")}
      </button>
    </div>
  );
};

export default CardTrainingTime;
