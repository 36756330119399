import { gql } from "@apollo/client";

export const GET_ALL_EVENTS = gql`
query Events($skip: Int!, $take: Int!, $order: SortByFilterArgs, $where: FilterEventInput) {
    events(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      nodes {
        id
        title
        content
        picture {
          id
          full_url
        }
        link
      }
    }
  }
`;

/* export const GET_FEED = gql`
  query NewsEntry($newsEntryId: Int!) {
    newsEntry(id: $newsEntryId) {
      picture {
        full_url
      }
      isFeatured
      id
      content
      subtitle
      title
    }
  }
`; */