import React from "react";
import { useParams } from "react-router-dom";

import PlayersLayout from "../../layouts/PlayersLayout";
import { Header, SideBar } from "../../components";

export const Players = () => {
  const { groupe } = useParams();
  console.log("groupeP",groupe);

  return (
    <div>
      <Header />
      <PlayersLayout groupe={groupe} />
      <SideBar active={"players"} />
    </div>
  );
};
