import { client } from "../config";
import { ADD_NEW_FEED, UPDATE_FEED, DELETE_FEED } from "../mutations";
import { GET_ALL_FEEDS, GET_FEED } from "../queries";

export const getAllFeeds = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_FEEDS,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getFeed = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_FEED,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const addNewFeed = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_NEW_FEED,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createNews,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateFeed = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_FEED,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updateNews,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteFeed = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_FEED,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.deleteNews,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
