import { client } from "../config";
import { ADD_NEW_SPONSOR, UPDATE_SPONSOR, DELETE_SPONSOR } from "../mutations";
import { GET_ALL_SPONSORS, GET_SPONSOR } from "../queries";

export const getAllSponsors = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_SPONSORS,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getSponsor = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_SPONSOR,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const addNewSponsor = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_NEW_SPONSOR,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createPartner,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateSponsor = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_SPONSOR,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updatePartner,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteSponsor = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_SPONSOR,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.deletePartner,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
