import React, { useEffect } from "react";

const Model = ({ isOpen, title, width, children,conHeight }) => {
  useEffect(() => {
    if (isOpen) {
      document.getElementById("container").style.overflow = "hidden";
    } else {
      document.getElementById("container").style.overflow = "";
    }
  }, [isOpen]);
  //h-[calc(100vh-80px)]

  return (
    <div
      className={`fixed md:w-[calc(100vw-262px)] w-full right-0  h-[calc(100vh-100px)] top-[100px] bg-[rgba(0,0,0,0.4)] z-[999] overflow-y-auto p-[2rem] hidden ${
        isOpen && " !flex items-center"
      }`}
    >
      <div
        className={`${conHeight ? conHeight : "h-fit" }  overflow-y-auto ${
          width ? width : "w-[100%]"
        } bg-[#ffffff] md:p-[4rem] p-4 mx-auto`}
      >
        {title !== "" && title !== undefined && (
          <div className="w-full flex items-center justify-between pb-[2rem]">
            <span className="font-bold text-[#222222] md:text-[2rem] text-[20px]">
              {title}
            </span>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Model;
