import React from 'react'
import { Header, SideBar } from '../../components'
import TrainingTimeProfileLayout from '../../layouts/TrainingTimeProfileLayout'

export const TrainingTimeProfile = () => {
  return (
    <>
        <Header/>
        <TrainingTimeProfileLayout/>
        <SideBar active={'trainingTime'}/>
    </>
  )
}