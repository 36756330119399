import { Skeleton } from '@mui/material'
import React from 'react'

const DashboardSkl = () => {
  return (
    <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
        <Skeleton sx={{width:"150px" ,height:"40px"}}/>
        <Skeleton sx={{width:"150px",height:"30px"}}/>
        </div>
        <div className=" relative bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10 overflow-hidden ">
          <div className="flex flex-col items-start justify-center gap-14 text-[12px]  sm:mt-10 lg:mt-0 overflow-hidden">
            <Skeleton variant='rounded' sx={{width:"100vw",height:"250px"}}/>
          </div>
        </div>
        <div className=" relative bg-white  mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10 overflow-hidden ">
        <Skeleton variant='rounded' sx={{width:"100vw",height:"100px"}}/>
          </div>
      </div>
  )
}

export default DashboardSkl