import { gql } from "@apollo/client";

export const ADD_NEW_PRODUCT = gql`
    mutation Mutation($createProductDto: CreateProductDto!) {
      createProduct(createProductDto: $createProductDto) {
        id
        name
        description
        price
      }
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation Mutation($productId: ID!, $updateProductInput: UpdateProductDto!) {
      updateProduct(productId: $productId, updateProductInput: $updateProductInput) {
        id
      }
    }
`;

export const DELETE_PRODUCT = gql`
    mutation DeleteProduct($productId: ID!) {
      deleteProduct(productId: $productId)
    }
`;