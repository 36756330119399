import { gql } from "@apollo/client";


export const UPDATE_KANTINE = gql`
    mutation Mutation($updateKantineInput: UpdateKantineInput!) {
      updateKantine(updateKantineInput: $updateKantineInput) {
        id
        title
        description
        pictures {
          id
          full_url
        }
      }
    }
`;