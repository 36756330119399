import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

//hooks
import { useAuth } from "../../hooks";
import Pagination from "../../components/Pagination";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// graphql
import {
  getAllOpponents,
  addNewOpponent,
  uploadImage,
} from "../../graphql/services";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import { CardOpponent, OponentSkeleton } from "../../components";

//Translation
import { useTranslation } from "react-i18next";

const OpponentLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [opponents, setopponents] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [opponentsTotCount, setOpponentsTotCount] = useState(null);
  const [loadingSkl, setLoadingSkl] = useState(false);
  const fetchOpponents = async (skip) => {
    setLoadingSkl(true);
    const { data, error, message } = await getAllOpponents(
      {
        take: pageSize,
        skip: Number(skip) ? Number(skip) * pageSize : 0,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch opponents failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoadingSkl(false);
      }
    } else {
      setopponents(data?.opponents?.nodes);
      setOpponentsTotCount(data?.opponents?.totalCount);
      setLoadingSkl(false);
    }
  };

  useEffect(() => {
    fetchOpponents(currentPage);
  }, [currentPage]);

  const [name, setname] = useState("");

  const inputRef = useRef();
  const [file, setfile] = useState(null);

  const checkfield = (name) => {
    let checked = false;
    if (name === "") {
      setErrorMessage(t("Enter oppoent name"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else {
      checked = true;
    }
    return checked;
  };

  const [loading, setloading] = useState(false);
  const handleCreatreOpponent = async (e) => {
    if (checkfield(name)) {
      if (!file) {
        setErrorMessage(t("Add apponent logo"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      } else {
        setloading(true);
        const resUpload = await uploadImage(file[0]);
        const { data, error, message } = await addNewOpponent(
          {
            createOpponentInput: {
              name: name,
              picture: parseInt(resUpload.id),
            },
          },
          TOKEN
        );
        if (error) {
          if (message === "Unauthorized") {
            dispatch(logout());
            navigate("/login");
          } else {
            setErrorMessage(t("Add opponent Failed"));
            setToastERROR(true);
            setToastType("error");
            handleClick();
            setloading(false);
          }
        } else {
          setToastSuccess(t("Add opponent successfully"));
          setToastERROR(false);
          setToastType("success");
          handleClick();
          setloading(false);
          setfile(null);
          setname("");
          fetchOpponents();
        }
      }
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex justify-between">
          <div className="text-[20px] flex justify-between items-center w-[155px]">
            {t("Opponents")}
          </div>
          <div className="text-[12px]"></div>
        </div>
        <div className="bg-white mt-10 px-[24px] py-[32px]">
          <div className="flex flex-wrap justify-between items-center text-[12px] w-full gap-2 ">
            <div className="flex flex-col">
              <span className="mb-1">{t("Name")} :</span>
              <div className="flex gap-[.5rem]">
                <div className="relative">
                  <input
                    type="text"
                    value={name || ""}
                    onChange={(e) => setname(e.target.value)}
                    autoComplete="off"
                    placeholder={t("Playing Against")}
                    className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                    required
                  />
                </div>
                <div
                  onClick={() => inputRef.current.click()}
                  className="h-[40px] w-[40px] rounded-full  overflow-hidden flex justify-center items-center cursor-pointer"
                >
                  {file ? (
                    <img
                      src={
                        typeof file === "object"
                          ? URL.createObjectURL(file[0])
                          : file[0]
                      }
                      alt="profile pic"
                      className="object-cover w-full h-full relative z-[0]"
                    />
                  ) : (
                    <div className="flex flex-col items-center">
                      {" "}
                      <img
                        src="/svg/upload.svg"
                        alt="uplaod svg"
                        className="w-[22px] "
                      />
                      <span>Logo</span>
                    </div>
                  )}
                  <input
                    ref={inputRef}
                    type="file"
                    onChange={(e) => setfile(e.target.files)}
                    className="hidden"
                  />
                </div>
              </div>
            </div>
            <button
              onClick={handleCreatreOpponent}
              className="h-[40px] sm:w-[160px] w-full md:w-[100px] bg-black rounded text-white text-[12px] mt-3"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Add")}
            </button>
          </div>
          <div className="mt-10 grid grid-cols-2 md:flex flex-row flex-wrap gap-5 w-full">
            {loadingSkl ? (
              <OponentSkeleton />
            ) : opponents && opponents.length !== 0 ? (
              opponents.map((opponent, i) => (
                <Link to={`/opponents/${opponent.id}`} key={i}>
                  <CardOpponent
                    i={i}
                    imgSrc={opponent?.picture?.full_url}
                    name={opponent.name}
                  />
                </Link>
              ))
            ) : (
              t("there is no opponent added at the moment")
            )}
          </div>
          {/* pagination */}
            {opponents && opponentsTotCount > 5 ? (
              <div className="w-full flex justify-center mt-10 mx-auto">
                <div className="mt-1 ">
                  <Pagination
                    count={opponentsTotCount}
                    pagesize={pageSize}
                    setPage={(e) => {
                      setCurrentPage(e);
                    }}
                    page={Number(currentPage) ? Number(currentPage) : 0}
                    showPages={3}
                  />
                </div>
              </div>
            ) : null}
        </div>
      </div>
    </>
  );
};

export default OpponentLayout;
