import Resizer from "react-image-file-resizer";

const resizeFile = (file, width, height) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "webp",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      width,
      height
    );
  });

export default resizeFile;
