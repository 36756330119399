import { client } from "../config";
import { GET_STATISTICS } from "../queries";

export const getStatistics = async () => {
  try {
    const result = await client.query({
      query: GET_STATISTICS,

      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
