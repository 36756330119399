import React from 'react'
import SponsorsLayout from '../../layouts/SponsorsLayout'
import { Header, SideBar } from '../../components'

export const Sponsors = () => {
  return (
    <div>
      <Header/>
      <SponsorsLayout/>
      <SideBar active={'sponsorsMembers'}/>
    </div>
  )
}
