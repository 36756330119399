import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { Navigate, useNavigate } from "react-router-dom";
//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import { addNewFan, manageFan } from "../../graphql/services";
//Translation
import { useTranslation } from "react-i18next";

const AddNewFanLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    firstName: "",
    lastName: "",
  });
  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleCreateStaff = async (e) => {
    setloading(true);
    const {
      data: fan,
      error,
      message,
    } = await addNewFan(
      {
        createFanInput: {
          firstName: form.firstName,
          lastName: form.lastName,
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Add Fan Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await manageFan(
        {
          updateFanInput: {
            status: "APPROVED",
            id: parseInt(fan.id),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Add Fan Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Fan added successfully"));
        setToastERROR(false);
        setToastType(t("success"));
        handleClick();
        setloading(false);
        setform({
          firstName: "",
          lastName: "",
        });
      }
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
          <div className="text-[20px] flex justify-between items-center">
            {t("Fans")}
            <div className="mx-2">
              <DropDownIcon fill={"#133D6E"} />
            </div>
            {t("Add new fan")}
          </div>
          <div className="text-[12px] flex items-center gap-[1rem]">
            <button
              onClick={() => navigate(-1)}
              className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded "
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleCreateStaff}
              className="h-[40px] w-[100px] sm:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
          <form className="flex flex-wrap justify-center gap-10 text-[12px] lg:ml-10 sm:mt-10 lg:mt-0">
            <div className="flex flex-col text-[12px]">
              <span className="mb-1">{t("First name")}</span>
              <input
                type="text"
                name="firstName"
                value={form.firstName || ""}
                onChange={handleChange}
                placeholder="First name"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
              <span className="mb-1">{t("Last name")}</span>
              <input
                type="text"
                name="lastName"
                value={form.lastName || ""}
                onChange={handleChange}
                placeholder="Last name"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddNewFanLayout;
