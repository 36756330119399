import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    counter: 0,
    refresh_token: "",
    access_token: "",
  },
  reducers: {
    inrement: (state) => {
      state.counter += 1;
    },
    decrement: (state) => {
      state.counter -= 1;
    },
    login: (state, action) => {
      state.refresh_token = action.payload.refresh_token;
      state.access_token = action.payload.access_token;
    },
    logout: (state) => {
      state.refresh_token = "";
      state.access_token = "";
    },

    updateToken: (state, action) => {
      state.access_token = action.payload.access_token;
      if (action.payload.refresh_token) {
        state.access_token = action.payload.refresh_token;
      }
    },
  },
});

export const { login, logout, updateToken, inrement, decrement } =
  authSlice.actions;

export default authSlice.reducer;
