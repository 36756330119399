import React from "react";
import { SettingsIcon } from "../../svg";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const TableLive = ({ i, game }) => {
  const isBeginGame = (date) => {
    const datetime1 = new Date();
    const datetime2 = new Date(date);
    if (datetime2 <= datetime1) {
      return true;
    }
    return false;
  };

  return (
    <tr className=" flex">
      <td className="flex justify-center items-center w-[140px]">{i + 1}</td>
      <td className="w-[140px]">
        <span className="flex justify-center items-center py-4 px-3">
          {game.group.name}
        </span>
      </td>
      <td className="w-[140px]">
        <div className="w-full flex items-center justify-center">
          <div className="w-[30px] rounded-full overflow-hidden">
            <img
              src={game.playingAgainst.picture.full_url}
              className="w-full"
              alt=""
            />
          </div>
          <span className="flex justify-center items-center py-4 px-3">
            {game.playingAgainst.name}
          </span>
        </div>
      </td>
      <td className="w-[140px]">
        <span className="flex justify-center items-center py-4 px-3">
          {game.leagueAndRound}
        </span>
      </td>
      <td className="w-[140px]">
        <span className="flex justify-center items-center py-4 px-3">
          {game.stadium}
        </span>
      </td>
      <td className="w-[140px]">
        <span className="flex justify-center items-center py-4 px-3">
          {dayjs(game.timing).format("DD-MM-YYYY H:m")}
        </span>
      </td>
      <td className="w-[140px]">
        <span className="flex justify-center items-center font-semibold py-4 px-3">
          {game.status === "PENDING"
            ? "Pending"
            : game.status === "PLAYING"
            ? "Playing"
            : game.status === "ENDED"
            ? "Finished"
            : ""}
        </span>
      </td>
      <td className="flex  items-center ">
        <div className="w-full flex items-center justify-center gap-2">
          <Link to={`/live/LiveDetail/${game.id}`} className="">
            <img src="/svg/live.svg" className="" />
          </Link>
          <Link to={`/live/slug/${game.id}`} className="">
            <SettingsIcon fill={"#133D6E"} width={24} height={24} />
          </Link>
        </div>
      </td>
    </tr>
  );
};

export default TableLive;
