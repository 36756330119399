import React from "react";
import { Link } from "react-router-dom";

const TableRolesList = ({ i, role, setTableRow }) => {
  return (
    <tr key={i}>
      <td>
        <span className=" mr-2">{role.fullName}</span>
      </td>
      <td>
        <span className="flex justify-center py-3 px-2">{role.email}</span>
      </td>
      <td>
        <div className="flex justify-center p-[.5rem]">
          <span className=" mr-2">
            {role.role === "ADMIN" ? (
              "ALL"
            ) : role.groups.length > 0 ? (
              <div className="flex flex-wrap justify-center gap-[.5rem]">
                {" "}
                {role.groups.map((group, i) => (
                  <span className="bg-[#ecedee] px-[.3rem] py-[.1rem]">
                    {group.name}
                  </span>
                ))}
              </div>
            ) : (
              "-"
            )}
          </span>
        </div>
      </td>
      <td>
        <div className="flex justify-center ">
          <span className=" mr-2">{role.role}</span>
        </div>
      </td>
      <td>
        <div className="flex justify-center items-center gap-[.5rem]">
          <Link className="p-[.5rem]" to={`/roles/${role.id}`}>
            <img src="/svg/editIcon.svg" />
          </Link>
          {role.email !== "admin@admin.com" && (
            <button
              className="p-[.5rem]"
              onClick={() => setTableRow({ id: role.id })}
            >
              <img src="/svg/delete2.svg" alt="" />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default TableRolesList;
