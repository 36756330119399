const customSort = (a, b) => {
  if (a.name === "KAMPFMANNSCHAFT") return 1;
  if (b.name === "KAMPFMANNSCHAFT") return -1;
  if (a.name === "U-6/Bambinis") return 1;
  if (b.name === "U-6/Bambinis") return -1;
  const aNumber = parseInt(a.name.split("-")[1]);
  const bNumber = parseInt(b.name.split("-")[1]);
  return bNumber - aNumber;
};

export const reorderGroupList = (groups) => {
  return groups.sort(customSort);
};
