import React from 'react'
import ClubSettingLayout from '../../layouts/ClubSettingLayout'
import { Header, SideBar } from '../../components'

export const ClubSetting = () => {
  return (
    <div>
      <Header/>
      <ClubSettingLayout/>
      <SideBar active={'clubSetting'}/>  
    </div>
  )
}

