import React from 'react'
import { Header, SideBar } from '../../components'

import ShopLayout from '../../layouts/ShopLayout'

const Shop = () => {
  return (
    <div>
        <Header/>
        <ShopLayout/>
        <SideBar active={'shop'}/>
    </div>
  )
}

export default Shop