import React, { useState, useEffect } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { CardTrainer, TrainerSkeleton } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  getALLTrainers,
  getGroups,
  getTrainersById,
} from "../../graphql/services";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";

//Translation
import { useTranslation } from "react-i18next";

// hooks
import { reorderGroupList, useAuth } from "../../hooks";
import Pagination from "../../components/Pagination";

export const TrainerLayout = ({ groupe }) => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  //get groups
  const [groups, setgroups] = useState([]);
  const fetchGroups = async () => {
    const { data, error, message } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setgroups(reorderGroupList(data?.groups?.nodes));
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  //get allTrainers with search feature
  const [trainers, setTrainers] = useState([]);
  const [trainersTotCount, setTrainersTotCount] = useState([]);
  const [searchedTrainers, setSearchedTrainers] = useState("");
  const [order, setOrder] = useState("ASC");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);

  const fetchAlltrainers = async (skip) => {
    setLoading(true);
    const { data, error, message } = await getALLTrainers({
      take: pageSize,
      skip: Number(skip) ? Number(skip) * pageSize : 0,
      order: {
        createdAt: order,
      },
    });
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch trainers failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      const filteredTrainers = () =>
        data?.trainers?.nodes.filter((trainer) => {
          const fullName = trainer?.firstName + trainer?.lastName;
          return fullName.toLowerCase().includes(searchedTrainers);
        });
      setTrainers(filteredTrainers);
      setTrainersTotCount(data?.trainers?.totalCount); //for pagination
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchedTrainers || order) fetchAlltrainers(currentPage);
  }, [searchedTrainers, order, currentPage]);

  //get trainer by group Id
  const [trainersByGrId, setTrainersByGrId] = useState([]);
  const [selectedGroup, setselectedGroup] = useState(null);
  const fetchTrainersByID = async (selectedGroup) => {
    setLoading(true);
    const { data, error, message } = await getTrainersById({
      teamId: parseInt(selectedGroup),
    });
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch trainer failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setTrainersByGrId(data?.teamTrainers);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      fetchTrainersByID(selectedGroup);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (adminGroup) {
      setselectedGroup(adminGroup[0]?.id);
    } else {
      setselectedGroup(groupe);
    }
  }, []);

  return (
    <div className="layoutContainer">
      <div className="flex justify-between flex-wrap gap-10">
        <div className="text-[20px] flex justify-between items-center w-[145px]">
          {t("Team")} <DropDownIcon fill={"#133D6E"} /> {t("Trainer")}
        </div>
        <div className="text-[12px]">
          <Link to={"/team/addNewTrainer"}>
            <button className="h-[40px] w-[120px] sm:w-[220px] bg-[#2B9D96] rounded text-white">
              {t("Add new trainer")}
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
        <form className="flex justify-between flex-wrap gap-5 text-[12px]">
          <div className="text-[12px] flex flex-wrap gap-5 flex-1">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold">{t("Search")}</span>
              <input
                type="text"
                name="Search"
                placeholder={t("Search any")}
                onChange={(e) =>
                  setSearchedTrainers(e.target.value.toLowerCase())
                }
                className="w-[237px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold">{t("Group")}</span>
              <select
                name="groupID"
                value={selectedGroup ? selectedGroup : ""}
                onChange={(e) => setselectedGroup(e.target.value)}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              >
                {!adminGroup && <option value={""}>all Groups</option>}
                {groups &&
                  groups?.map((group, i) =>
                    adminGroup ? (
                      adminGroup?.some((obj) => obj.id === group.id) && (
                        <option key={group?.id} value={group?.id}>
                          {group?.name}
                        </option>
                      )
                    ) : (
                      <option key={group?.id} value={group?.id}>
                        {group?.name}
                      </option>
                    )
                  )}
              </select>
            </div>
          </div>
        </form>
        <div className="mt-5 md:mt-10 flex flex-wrap items-center gap-5">
          <span className="font-bold">{t("Sort By")}</span>
          <div className="flex flex-wrap gap-5">
            <button
              className={`${
                order === "ASC" ? "active-button" : "disabled-button"
              }`}
              onClick={() => setOrder("DESC")}
            >
              {t("Added date (newest)")}
            </button>
            <button
              className={`${
                order === "DESC" ? "active-button" : "disabled-button"
              }`}
              onClick={() => setOrder("ASC")}
            >
              {t("Added date (oldest)")}
            </button>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 md:gap-3 lg:gap-4">
          {loading ? (
            <TrainerSkeleton />
          ) : !selectedGroup ? (
            trainers && trainers?.length > 0 && !groupe ? (
              trainers?.map((item, i) => (
                <Link to={`/team/trainer/profile/${item.id}`}>
                  <CardTrainer
                    key={item.id}
                    imgSrc={item?.picture?.full_url}
                    name={item?.firstName + " " + item?.lastName}
                    Position={item?.position}
                    team={item?.group?.name}
                    /* handelViewProfileTrainer={handelViewProfileTrainer} */
                  />
                </Link>
              ))
            ) : (
              "there is no trainers matched "
            )
          ) : trainersByGrId && trainersByGrId.length > 0 ? (
            trainersByGrId?.map((item, i) => (
              <Link to={`/team/trainer/profile/${item.id}`}>
                <CardTrainer
                  key={item.id}
                  imgSrc={item?.picture?.full_url}
                  name={item?.firstName + " " + item?.lastName}
                  Position={item?.position}
                  team={item?.group?.name}
                  /* handelViewProfileTrainer={handelViewProfileTrainer} */
                />
              </Link>
            ))
          ) : (
            "there is no trainers matched"
          )}
        </div>
        {/* pagination */}
        {!searchedTrainers &&
        !selectedGroup &&
        trainers &&
        trainersTotCount > 0 ? (
          <div className="w-full flex justify-center mt-10">
            <Pagination
              count={trainersTotCount}
              pagesize={pageSize}
              setPage={(e) => {
                setCurrentPage(e);
              }}
              page={Number(currentPage) ? Number(currentPage) : 0}
              showPages={3}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
