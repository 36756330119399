import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

import DropDownIcon from "../../svg/DropDownIcon";
//graphql
import { createGroup, uploadImage } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const AddNewGroupLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);
  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    groupName: null,
    picture: null,
  });

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const handleAddGroup = async (e) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      console.log(resUpload);

      const { data, error, message } = await createGroup(
        {
          createGroupInput: {
            name: form.groupName,
            picture: parseInt(resUpload?.id),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Group creation failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Group created successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    } else {
      setloading(true);
      const { data, error, message } = await createGroup(
        {
          createGroupInput: {
            name: form.groupName,
            picture: null,
          },
        },
        TOKEN
      );
      if (error && message === "Group With this name already exists") {
        setErrorMessage(message);
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      } else if (error && message !== "Group With this name already exists") {
        setErrorMessage(t("Group creation failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      } else {
        setToastSuccess(t("Group created successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };
  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />

      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
          <div className="text-[20px] flex justify-between items-center">
            {t("Team")}
            <div className="mx-2">
              <DropDownIcon fill={"#133D6E"} />
            </div>
            {t("Add new Group")}
          </div>
          <div className="text-[12px]">
            <button
              onClick={() => navigate(-1)}
              className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded mr-3"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={() => {
                handleAddGroup();
              }}
              className="h-[40px] w-[100px] sm:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white mt-10 min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-center justify-center lg:justify-start px-[24px] py-[32px]">
          <div className=" relative text-center ">
            <button
              onClick={() => inputRef.current.click()}
              className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-[1]"
            >
              <img src="/svg/camera.svg" />
            </button>
            <div className="h-[137px] w-[206px] bg-[#D9D9D9] overflow-hidden">
              {file && (
                <img
                  src={
                    typeof file === "object"
                      ? URL.createObjectURL(file[0])
                      : file[0]
                  }
                  alt="profile pic"
                  className="object-cover h-full  w-full relative z-[0]"
                />
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
            </div>
          </div>
          <form className="flex flex-col lg:ml-10 text-[12px]">
            <span className="mb-1">{t("Group")}</span>
            <input
              type="text"
              required
              list="groups"
              value={form.groupName || ""}
              onChange={handleChange}
              name="groupName"
              placeholder={t("Group Name")}
              className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
            />
            <datalist id="groups">
              <option value="Kampfmannschaft"></option>
              <option value="U-23"></option>
              <option value="U-18"></option>
              <option value="U-16"></option>
              <option value="U-14"></option>
              <option value="U-13"></option>
              <option value="U-9"></option>
              <option value="U-8"></option>
              <option value="U-7"></option>
              <option value="U-6"></option>
              <option value="Bambinis"></option>
            </datalist>
          </form>
          <div className="hidden lg:block absolute h-[240px] w-[1px] left-[300px] top-[120px] bg-[#D8D8D8]">
            {/* this div for the vertical line */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewGroupLayout;
