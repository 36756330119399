import React from 'react'
import { Header, SideBar } from '../../components'
import FeedsListLayout from '../../layouts/FeedsListLayout'

export const FeedsList = () => {
  return (
    <div>
      <Header/>
      <FeedsListLayout/>
      <SideBar active={'feedsList'}/>
    </div>
  )
}

