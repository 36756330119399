import React from 'react'

export const ClubSideBar = ({fill}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.99992 13.6663C6.08881 13.6663 5.2277 13.4913 4.41658 13.1413C3.60547 12.7913 2.89714 12.3136 2.29159 11.708C1.68603 11.1025 1.20825 10.3941 0.858252 9.58301C0.508252 8.7719 0.333252 7.91079 0.333252 6.99967C0.333252 6.07745 0.508252 5.21356 0.858252 4.40801C1.20825 3.60245 1.68603 2.8969 2.29159 2.29134C2.89714 1.68579 3.60547 1.20801 4.41658 0.858008C5.2277 0.508008 6.08881 0.333008 6.99992 0.333008C7.92214 0.333008 8.78603 0.508008 9.59159 0.858008C10.3971 1.20801 11.1027 1.68579 11.7083 2.29134C12.3138 2.8969 12.7916 3.60245 13.1416 4.40801C13.4916 5.21356 13.6666 6.07745 13.6666 6.99967C13.6666 7.91079 13.4916 8.7719 13.1416 9.58301C12.7916 10.3941 12.3138 11.1025 11.7083 11.708C11.1027 12.3136 10.3971 12.7913 9.59159 13.1413C8.78603 13.4913 7.92214 13.6663 6.99992 13.6663ZM10.4166 5.44967L11.5666 5.04967L11.8666 3.98301C11.4888 3.39412 11.0221 2.89134 10.4666 2.47467C9.91103 2.05801 9.28325 1.73856 8.58325 1.51634L7.49992 2.23301V3.39967L10.4166 5.44967ZM3.59992 5.44967L6.49992 3.39967V2.23301L5.43325 1.51634C4.73325 1.73856 4.10547 2.05801 3.54992 2.47467C2.99436 2.89134 2.5277 3.39412 2.14992 3.98301L2.51659 5.04967L3.59992 5.44967ZM2.69992 10.783L3.69992 10.6663L4.34992 9.58301L3.31659 6.39967L2.13325 5.99967L1.33325 6.64967C1.33325 7.44967 1.42214 8.18023 1.59992 8.84134C1.7777 9.50245 2.14436 10.1497 2.69992 10.783ZM6.99992 12.6663C7.29992 12.6663 7.6027 12.6386 7.90825 12.583C8.21381 12.5275 8.53325 12.4552 8.86658 12.3663L9.41658 11.1663L8.88325 10.2497H5.13325L4.59992 11.1663L5.14992 12.3663C5.43881 12.4552 5.74436 12.5275 6.06659 12.583C6.38881 12.6386 6.69992 12.6663 6.99992 12.6663ZM5.23325 9.24967H8.69992L9.71659 6.19967L6.99992 4.24967L4.23325 6.19967L5.23325 9.24967ZM11.3166 10.783C11.861 10.1497 12.2221 9.50245 12.3999 8.84134C12.5777 8.18023 12.6666 7.44967 12.6666 6.64967L11.8666 6.09967L10.6999 6.39967L9.66658 9.58301L10.2999 10.6663L11.3166 10.783Z" fill={fill}/>
</svg>

  )
}
