import { gql } from "@apollo/client";

export const GET_ALL_FEEDS = gql`
query News($skip: Int!, $take: Int!, $where: FilterNewsInput, $order: SortByFilterArgs) {
  news(skip: $skip, take: $take, where: $where, order: $order) {
    totalCount
    nodes {
      id
      isFeatured
      title
      content
      picture {
        full_url
      }
    }
  }
}
`;

export const GET_FEED = gql`
  query NewsEntry($newsEntryId: Int!) {
    newsEntry(id: $newsEntryId) {
      picture {
        full_url
      }
      isFeatured
      id
      content
      subtitle
      title
    }
  }
`;
