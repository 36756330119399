import React, { useEffect } from "react";
import { routes } from "../../lib";
import { Link, useLocation } from "react-router-dom";
import {
  ClubSideBar,
  DashboardSideBar,
  FansSideBar,
  FeedsSideBar,
  InsboxSideBar,
  LiveIcon,
  SponsorsSideBar,
  TeamSideBar,
  OpponentIcon,
  RolesIcon,
} from "../../svg";
import DropDownIcon from "../../svg/DropDownIcon";
import { useDispatch, useSelector } from "react-redux";
import { setDropDownIsOpened } from "../../store/slices/sideBarSlice";
import SideBarTablett from "../SideBarTablett";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import PublicIcon from '@mui/icons-material/Public';
//Translation
import { useTranslation } from "react-i18next";
import { setTotUnreadMsg } from "../../store/slices/inboxSlice";
import { getAllMessages } from "../../graphql/services";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';

export const SideBar = ({ active }) => {
  const Location = useLocation();
  const { pathname } = Location;
  const { t } = useTranslation();
  const totUnreadMsg = useSelector( (state) => state.inbox.totUnreadMsg)


  // group staff
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));

  /* get essentials from reduxStore */
  const dispatch = useDispatch();
  const teamDropDownIsOpened = useSelector(
    (state) => state.sideBar.teamDropDownIsOpened
  );
  const feedsDropDownIsOpened = useSelector(
    (state) => state.sideBar.feedsDropDownIsOpened
  );
  const SponsorsDropDownIsOpened = useSelector(
    (state) => state.sideBar.SponsorsDropDownIsOpened
  );
  const fansDropDownIsOpened = useSelector(
    (state) => state.sideBar.fansDropDownIsOpened
  );

  /* to open subMenu in side bar */
  const openSubMenu = (type) => {
    dispatch(setDropDownIsOpened({ type }));
  };
  /* check to open subMenus in sideBar */
  useEffect(() => {
    if (active === "groups" && pathname.includes("team")) {
      openSubMenu("SWITCH_TEAM");
    } else if (pathname.includes("team")) {
      openSubMenu("OPEN_TEAM");
    } else {
      openSubMenu("CLOSE_TEAM");
    }
    if (active === "feedsList" && pathname.includes("feeds")) {
      openSubMenu("SWITCH_FEEDS");
    } else if (pathname.includes("feeds")) {
      openSubMenu("OPEN_FEEDS");
    } else {
      openSubMenu("CLOSE_FEEDS");
    }
    if (active === "sponsorsMembers" && pathname.includes("sponsor")) {
      openSubMenu("SWITCH_SPONSORS");
    } else if (pathname.includes("sponsor")) {
      openSubMenu("OPEN_SPONSORS");
    } else {
      openSubMenu("CLOSE_SPONSORS");
    }
    if (active === "fansList" && pathname.includes("fans")) {
      openSubMenu("SWITCH_FANS");
    } else if (pathname.includes("fans")) {
      openSubMenu("OPEN_FANS");
    } else {
      openSubMenu("CLOSE_FANS"); /* <--------- */
    }
  }, [pathname]);

  /* fetch total count unread messages for inbox notification */
  const fetchMessages = async () => {
    const { data } = await getAllMessages({take: 0,skip: 1000});
    dispatch(setTotUnreadMsg(data?.contacts?.totalUnread))
  };
  useEffect(() => {fetchMessages()},[]);

  return (
    <>
      {/* Large screen */}
      <div
        className={`fixed hidden md:block top-[100px] left-0 h-[calc(100vh-100px)] w-[262px] bg-BgBlue overflow-y-auto`}
      >
        <div className="my-10 flex flex-col">
          {routes.map((route) =>
            adminGroup ? (
              <div
                key={route.title}
                onClick={
                  route.link.includes("team")
                    ? () =>
                        openSubMenu(
                          route.link.includes("team") && active === "groups"
                            ? "SWITCH_TEAM"
                            : route.link.includes("team")
                            ? "OPEN_TEAM"
                            : "CLOSE_TEAM"
                        )
                    : null
                }
              >
                {(route.title === "Team" || route.title === "Live") && (
                  <Link to={route.link}>
                    <div
                      className={`relative w-full py-[1rem] pl-[25px] flex items-center gap-[.8rem] font-[500]`}
                    >
                      {route.title === "Team" && (
                        <TeamSideBar
                          fill={
                            active === route.active ||
                            (teamDropDownIsOpened &&
                              route.link.includes("team"))
                              ? "#FFF"
                              : "#FFFFFF80"
                          }
                        />
                      )}
                      {route.title === "Live" && (
                        <LiveIcon
                          fill={active === route.active ? "#FFF" : "#FFFFFF80"}
                        />
                      )}
                      {/* render title with styling */}
                      <span
                        className={`${
                          active === route.active ||
                          (teamDropDownIsOpened && route.link.includes("team"))
                            ? "item-cliked text-white text-[13px]"
                            : "text-[#FFFFFF80] text-[13px]"
                        }`}
                      >
                        {`${t(route.title)}`}
                      </span>
                      {/* DropDownIcon for subMenu */}
                      <span
                        className={` absolute right-[5rem] ${
                          route.title === "Team" &&
                          teamDropDownIsOpened &&
                          route.link.includes("team") &&
                          "rotate-90 duration-300 ease-in-out"
                        }`}
                      >
                        {route.title === "Team" && (
                          <DropDownIcon
                            fill={
                              active === route.active ||
                              (teamDropDownIsOpened &&
                                route.link.includes("team"))
                                ? "#FFF"
                                : "#FFFFFF80"
                            }
                          />
                        )}
                      </span>
                    </div>
                  </Link>
                )}
                {/*(submenu) for Team - routed directly to '/players'*/}
                {route.link.includes("team") && teamDropDownIsOpened && (
                  <ul className="list-disc ml-[68px]">
                    {route.items.map(
                      (teamRoute) =>
                        teamRoute.active !== "staff" &&
                        teamRoute.active !== "addNewStaff" &&
                        teamRoute.active !== "addNewGroup" && (
                          <Link to={teamRoute.link}>
                            <li
                              className={`mb-2 text-[10px] ${
                                active === teamRoute.active
                                  ? "text-white"
                                  : "text-[#FFFFFF80]"
                              }`}
                            >
                              {`${t(teamRoute.title)}`}
                            </li>
                          </Link>
                        )
                    )}
                  </ul>
                )}
              </div>
            ) : (
              <div
                key={route.title}
                onClick={
                  route.link.includes("team")
                    ? () =>
                        openSubMenu(
                          route.link.includes("team") && active === "groups"
                            ? "SWITCH_TEAM"
                            : route.link.includes("team")
                            ? "OPEN_TEAM"
                            : "CLOSE_TEAM"
                        )
                    : route.link.includes("feeds")
                    ? () =>
                        openSubMenu(
                          route.link.includes("feeds") && active === "feedsList"
                            ? "SWITCH_FEEDS"
                            : route.link.includes("feeds")
                            ? "OPEN_FEEDS"
                            : "CLOSE_FEEDS"
                        )
                    : route.link.includes("sponsor")
                    ? () =>
                        openSubMenu(
                          route.link.includes("sponsor") &&
                            active === "sponsorsMembers"
                            ? "SWITCH_SPONSORS"
                            : route.link.includes("sponsor")
                            ? "OPEN_SPONSORS"
                            : "CLOSE_SPONSORS"
                        )
                    : route.link.includes("fans")
                    ? () =>
                        openSubMenu(
                          route.link.includes("fans") && active === "fansList"
                            ? "SWITCH_FANS"
                            : route.link.includes("fans")
                            ? "OPEN_FANS"
                            : "CLOSE_FANS"
                        )
                    : null
                }
              >
                <Link to={route.link}>
                  <div
                    className={`relative w-full py-[1rem] pl-[25px] flex items-center gap-[.8rem] font-[500]`}
                  >
                    {/* render the svg icon with its style depending on the item */}
                    {route.title === "Dashboard" && (
                      <DashboardSideBar
                        fill={active === route.active ? "#FFF" : "#FFFFFF80"}
                      />
                    )}
                    {route.title === "Club settings" && (
                      <ClubSideBar
                        fill={active === route.active ? "#FFF" : "#FFFFFF80"}
                      />
                    )}
                    {route.title === "Carousel Slider" && (
                      <WebStoriesIcon
                        style={{
                          fontSize: 16,
                          color: active === route.active ? "#FFF" : "#FFFFFF80",
                        }}
                      />
                    )}
                    {route.title === "Team" && (
                      <TeamSideBar
                        fill={
                          active === route.active ||
                          (teamDropDownIsOpened && route.link.includes("team"))
                            ? "#FFF"
                            : "#FFFFFF80"
                        }
                      />
                    )}
                    {route.title === "Feeds" && (
                      <FeedsSideBar
                        fill={
                          active === route.active ||
                          (feedsDropDownIsOpened &&
                            route.link.includes("feeds"))
                            ? "#FFF"
                            : "#FFFFFF80"
                        }
                      />
                    )}
                    {route.title === "Events" && (  
                      <EventAvailableIcon style={active === route.active ? {color:"white",width:"16px"} : {color:"#FFFFFF80",width:"16px"}}/>
                    )}
                    {route.title === "Sponsors" && (
                      <SponsorsSideBar
                        fill={
                          active === route.active ||
                          (SponsorsDropDownIsOpened &&
                            route.link.includes("sponsor"))
                            ? "#FFF"
                            : "#FFFFFF80"
                        }
                      />
                    )}
                    {route.title === "Fans" && (
                      <FansSideBar
                        fill={
                          active === route.active ||
                          (fansDropDownIsOpened && route.link.includes("fans"))
                            ? "#FFF"
                            : "#FFFFFF80"
                        }
                      />
                    )}
                    {route.title === "Inbox" && (
                      <InsboxSideBar
                        fill={active === route.active ? "#FFF" : "#FFFFFF80"}
                      />
                    )}
                    {route.title === "Live" && (
                      <LiveIcon
                        fill={active === route.active ? "#FFF" : "#FFFFFF80"}
                      />
                    )}
                    {route.title === "Opponent" && (
                      <OpponentIcon
                        fill={active === route.active ? "#FFF" : "#FFFFFF80"}
                      />
                    )}
                    {route.title === "Shop" && (  
                        <LocalMallIcon style={active === route.active ? {color:"white",width:"16px"} : {color:"#FFFFFF80",width:"16px"}}/>
                    )}

                    {route.title === "Kantine" && (  
                        <LocalCafeIcon style={active === route.active ? {color:"white",width:"16px"} : {color:"#FFFFFF80",width:"16px"}}/>
                    )}
                    {route.title === "Roles" && (
                      <RolesIcon
                        fill={active === route.active ? "#FFF" : "#FFFFFF80"}
                        width={"16px"}
                        height={"16px"}
                      />
                    )}

                    {/* {route.title === "Management" && (
                    <RolesIcon
                      fill={active === route.active ? "#FFF" : "#FFFFFF80"}
                    />
                  )} */}
                    {/* render title with styling */}
                    <span
                      className={`${
                        active === route.active ||
                        (teamDropDownIsOpened && route.link.includes("team")) ||
                        (route.title === "Feeds" &&
                          feedsDropDownIsOpened &&
                          route.link.includes("feeds")) ||
                        (route.title === "Sponsors" &&
                          SponsorsDropDownIsOpened &&
                          route.link.includes("sponsor")) ||
                        (route.title === "Fans" &&
                          fansDropDownIsOpened &&
                          route.link.includes("fans"))
                          ? "item-cliked text-white text-[13px]"
                          : "text-[#FFFFFF80] text-[13px]"
                      }`}
                    >
                      {`${t(route.title)}`}
                    </span>
                    {/* inbox notification */}
                      {totUnreadMsg && route.title === "Inbox"&& 
                          <span className= " absolute right-[70px]  w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[white] text-[black] text-[12px]">
                            {totUnreadMsg}
                          </span>}
                    {/* DropDownIcon for subMenu */}
                    <span
                      className={` absolute right-[5rem] ${
                        ((route.title === "Team" &&
                          teamDropDownIsOpened &&
                          route.link.includes("team")) ||
                          (route.title === "Feeds" &&
                            feedsDropDownIsOpened &&
                            route.link.includes("feeds")) ||
                          (route.title === "Sponsors" &&
                            SponsorsDropDownIsOpened &&
                            route.link.includes("sponsor")) ||
                          (fansDropDownIsOpened &&
                            route.link.includes("fans"))) &&
                        "rotate-90 duration-300 ease-in-out"
                      }`}
                    >
                      {(route.title === "Team" ||
                        route.title === "Feeds" ||
                        route.title === "Sponsors" ||
                        route.title === "Fans") && (
                        <DropDownIcon
                          fill={
                            active === route.active ||
                            (teamDropDownIsOpened &&
                              route.link.includes("team")) ||
                            (feedsDropDownIsOpened &&
                              route.link.includes("feeds")) ||
                            (SponsorsDropDownIsOpened &&
                              route.link.includes("sponsor")) ||
                            (fansDropDownIsOpened &&
                              route.link.includes("fans"))
                              ? "#FFF"
                              : "#FFFFFF80"
                          }
                        />
                      )}
                    </span>
                  </div>
                </Link>
                {/*(submenu) for Team - routed directly to '/players'*/}
                {route.link.includes("team") && teamDropDownIsOpened && (
                  <ul className="list-disc ml-[68px]">
                    {route.items.map((teamRoute) => (
                      <Link to={teamRoute.link}>
                        <li
                          className={`mb-2 text-[10px] ${
                            active === teamRoute.active
                              ? "text-white"
                              : "text-[#FFFFFF80]"
                          }`}
                        >
                          {`${t(teamRoute.title)}`}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}
                {/*(submenu) for Feeds - routed directly to '/feedsList'*/}
                {route.link.includes("feeds") && feedsDropDownIsOpened && (
                  <ul className="list-disc ml-[68px]">
                    {route.items.map((feedsRoute) => (
                      <Link to={feedsRoute.link}>
                        <li
                          className={`mb-2 text-[10px] ${
                            active === feedsRoute.active
                              ? "text-white"
                              : "text-[#FFFFFF80]"
                          }`}
                        >
                          {`${t(feedsRoute.title)}`}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}
                {/*(submenu) for Sponsors - routed directly to '/sponsorsMembers'*/}
                {route.link.includes("sponsor") && SponsorsDropDownIsOpened && (
                  <ul className="list-disc ml-[68px]">
                    {route.items.map((sponsorRoute) => (
                      <Link to={sponsorRoute.link}>
                        <li
                          className={`mb-2 text-[10px] ${
                            active === sponsorRoute.active
                              ? "text-white"
                              : "text-[#FFFFFF80]"
                          }`}
                        >
                          {`${t(sponsorRoute.title)}`}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}
                {/*(submenu) for Fans - routed directly to '/fansList'*/}
                {route.link.includes("fans") && fansDropDownIsOpened && (
                  <ul className="list-disc ml-[68px]">
                    {route.items.map((fansRoute) => (
                      <Link to={fansRoute.link}>
                        <li
                          className={`mb-2 text-[10px] ${
                            active === fansRoute.active
                              ? "text-white"
                              : "text-[#FFFFFF80]"
                          }`}
                        >
                          {`${t(fansRoute.title)}`}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}
              </div>
            )
          )}
        </div>
      </div>
      {/* Tablette screen */}
      <SideBarTablett active={active} totUnreadMsg={totUnreadMsg} />
    </>
  );
};
