import React from "react";
import { useParams } from "react-router-dom";

import { Header, SideBar } from "../../components";
import FeedsProfileLayout from "../../layouts/FeedsProfileLayout";

const FeedsProfile = () => {
  const { feedsId } = useParams();

  return (
    <>
      <Header />
      <FeedsProfileLayout feedsId={feedsId} />
      <SideBar active={"feedsList"} />
    </>
  );
};

export default FeedsProfile;
