import { client } from "../config";
import {
  CREATE_LIVE,
  UPDATE_LIVE,
  DELETE_LIVE,
  CREATE_GAME,
  UPDATE_GAME,
  DELETE_GAME,
  CREATE_OPPPONENT,
  UPDATE_OPPPONENT,
  DELETE_OPPPONENT,
} from "../mutations";
import {
  GET_ALL_LIVES,
  GET_LIVE,
  GET_ALL_GAMES,
  GET_GAME,
  GET_ALL_OPPONENTS,
  GET_OPPONENT,
} from "../queries";

export const getAllLives = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_LIVES,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getLive = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_LIVE,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const addNewLive = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_LIVE,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createLiveNews,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateLive = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_LIVE,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updateLiveNews,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteLive = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_LIVE,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.deleteLiveNews,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

//opponents
export const addNewOpponent = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_OPPPONENT,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createOpponent,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateOpponent = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_OPPPONENT,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updateOpponent,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteOpponent = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_OPPPONENT,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.removeOpponent,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getAllOpponents = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_OPPONENTS,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getOpponent = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_OPPONENT,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

// games
export const getAllGames = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_GAMES,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getGame = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_GAME,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const addNewGame = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_GAME,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createGame,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateGame = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_GAME,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updateGame,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteGame = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_GAME,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.deleteGame,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
