import React, { useState, useRef, useEffect } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { Model, SponsorsSlugSkl } from "../../components";
import { Link, useNavigate } from "react-router-dom";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import {
  getSponsor,
  updateSponsor,
  deleteSponsor,
  uploadImage,
} from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const SponsorSlugLayout = ({ sponsorId }) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };
  const [form, setform] = useState({
    name: "",
    logo: "",
    link: "",
    description:"",
  });

  const MAX_CHAR_LIMIT = 108; // Change this value to set the character limit IN TEXTAREA
  const handleChange = (e) => {
    const {name,value}= e.target
    if (name !=="description") {
      setform({ ...form, [name]: value }); 
    } else {
       // check
       if (value.length <= MAX_CHAR_LIMIT) {
         setform({ ...form, description: value });
       }
    }
  }

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleUpdateSponsor = async (id) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await updateSponsor(
        {
          input: {
            id: parseInt(id),
            name: form.name,
            link: form.link,
            logo: parseInt(resUpload.id),
            description:form.description,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update sponsor Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Update sponsor successfully"));
        setToastERROR(false);
        setToastType(t("success"));
        handleClick();
        setloading(false);
        navigate('/sponsors/sponsorsMembers')
      }
    } else {
      const { data, error, message } = await updateSponsor(
        {
          input: {
            id: parseInt(id),
            name: form.name,
            link: form.link,
            description:form.description,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update sponsor Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Update sponsor successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
        navigate('/sponsors/sponsorsMembers')
      }
    }
  };

  const fetchSponsor = async (id) => {
    setloading(true);
    const { data } = await getSponsor(
      {
        partnerByIdId: parseInt(id),
      },
      TOKEN
    );
    setform({
      name: data.partnerById?.name,
      link: data.partnerById?.link,
      logo: data.partnerById?.logo.full_url,
      description: data?.partnerById?.description || "",
    });
    setloading(false);
  };

  useEffect(() => {
    if (sponsorId) {
      fetchSponsor(sponsorId);
    }
  }, [sponsorId]);

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const handleDeleteSponsor = async () => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteSponsor(
      {
        deletePartnerId: parseInt(sponsorId),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Delete Sponsor Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setToastSuccess(t("Delete Sponsor successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      Navigate(-1);
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {loading ? (
        <SponsorsSlugSkl />
      ) : (
        <div className="layoutContainer">
          {/* trigger Model when Clicking on Delete */}
          <Model
            isOpen={deletePopupIsOpen}
            title={t("Are u sure to delete this Sponsor ?")}
            width={600}
          >
            <div className="w-full flex items-center gap-[1rem]">
              <button
                className="h-[40px] w-[180px] bg-[#C04337] rounded text-white mr-3 text-[12px]"
                onClick={handleDeleteSponsor}
              >
                {deleteLoading ? <Spinner c={"text-white"} /> : t("Delete")}
              </button>
              <button
                className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
                onClick={() => setDeletePopupIsOpen(false)}
              >
                {t("Discard")}
              </button>
            </div>
          </Model>
          <div className="flex flex-wrap justify-between gap-10">
            <div className="text-[16px] md:text-[20px] flex justify-between items-center ">
              <button onClick={() => Navigate(-1)}>
                <img src="/svg/backIcon.svg" />
              </button>
              <Link to="/sponsors/sponsorsMembers">
                <span className="mx-2">{t("Sponsors")}</span>
              </Link>
              <DropDownIcon fill={"#133D6E"} />
              <span className="mx-2">{form.name}</span>
            </div>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <button
                className="h-[40px] w-[100px] lg:w-[180px] border border-black rounded"
                onClick={() => Navigate(-1)}
              >
                {t("Cancel")}
              </button>
              <button
                className="h-[40px] w-[100px] lg:w-[180px] bg-[#C04337] rounded text-white"
                onClick={() => setDeletePopupIsOpen((prev) => !prev)}
              >
                {t("Delete")}
              </button>
              <button
                onClick={() => handleUpdateSponsor(sponsorId)}
                className="h-[40px] w-[100px] lg:w-[180px] bg-black rounded text-white"
              >
                {loading ? <Spinner c={"text-white"} /> : t("Save")}
              </button>
            </div>
          </div>
          <div className="bg-white mt-10 min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-center justify-center lg:justify-start px-[24px] py-[32px]">
            <div className=" relative text-center ">
              <button
                onClick={() => inputRef.current.click()}
                className="z-[5] absolute left-[50%] -translate-x-[50%] top-[-1rem]"
              >
                <img src="/svg/camera.svg" />
              </button>
              <div className="h-[137px] w-[206px] bg-[#D9D9D9] overflow-hidden flex justify-center items-center">
                {file ? (
                  <img
                    src={
                      typeof file === "object"
                        ? URL.createObjectURL(file[0])
                        : file[0]
                    }
                    alt="profile pic"
                  />
                ) : form.logo ? (
                  <img src={form.logo} alt="profile pic" />
                ) : (
                  ""
                )}
                <input
                  ref={inputRef}
                  type="file"
                  onChange={(e) => setfile(e.target.files)}
                  className="hidden"
                />
              </div>
            </div>
          <div className="flex flex-col lg:ml-10 text-[12px]">
            <span className="font-bold mb-2">{t("Company Name")}</span>
            <input
              type="text"
              name="name"
              value={form.name || ""}
              onChange={handleChange}
              placeholder={t("Company Name")}
              className="w-[237px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              required
            />
            <span className="font-bold mb-2">{t("Link")}</span>
            <input
              type="text"
              name="link"
              value={form.link || ""}
              onChange={handleChange}
              placeholder={t("link")}
              className="w-[237px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              required
            />
            <span className="font-bold mb-2">{t("Description")}</span>
            <textarea
              name="description"
              value={form?.description || ""}
              onChange={handleChange}
              placeholder={t("Description")}
              className="w-[237px] h-[90px] resize-none text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
            />
            <span className="text-[10px]"> maximum lines: 3 </span>
          </div>
            <div className="hidden xl:block absolute h-[240px] w-[1px] left-[300px] top-[120px] bg-[#D8D8D8]">
              {/* this div for the vertical line */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SponsorSlugLayout;
