import React from 'react'

const SponsorsLayout = () => {
  return (
    <div>
      SponsorsLayout
    </div>
  )
}

export default SponsorsLayout
