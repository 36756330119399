import React, { useState, useRef, useEffect } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { Link, useNavigate } from "react-router-dom";
import { FeedsSlugSkl, Model } from "../../components";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import Editor from "../../components/Editor";

// graphql
import {
  getFeed,
  updateFeed,
  deleteFeed,
  uploadImage,
} from "../../graphql/services";
//Translation
import { useTranslation } from "react-i18next";

const FeedsProfileLayout = ({ feedsId }) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };
  const [form, setform] = useState({
    content: "",
    title: "",
    subtitle: "",
    isFeatured: "",
    picture: "",
  });
  const [textEditorvalue, setTextEditorvalue] = useState("");

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleUpdateFeed = async (id) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await updateFeed(
        {
          input: {
            id: parseInt(id),
            content: textEditorvalue,
            title: form.title,
            subtitle: form.subtitle,
            isFeatured: form.isFeatured,
            picture: parseInt(resUpload.id),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update feed Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
          Navigate("/feeds/feedsList")
        }
      } else {
        setToastSuccess(t("Update feed successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await updateFeed(
        {
          input: {
            id: parseInt(id),
            content: textEditorvalue,
            title: form.title,
            subtitle: form.subtitle,
            isFeatured: form.isFeatured,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update feed Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
          Navigate("/feeds/feedsList")
        }
      } else {
        setToastSuccess(t("Update feed successfully"));
        setToastERROR(false);
        setToastType(t("success"));
        handleClick();
        setloading(false);
        Navigate("/feeds/feedsList")
      }
    }
  };

  const fetchFeed = async (id) => {
    setloading(true);
    const { data, error, message } = await getFeed(
      {
        newsEntryId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch feed failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      }
    } else {
      setform({
        title: data.newsEntry.title,
        subtitle: data.newsEntry.subtitle,
        isFeatured: data.newsEntry.isFeatured,
        picture: data?.newsEntry?.picture?.full_url,
      });
      setTextEditorvalue(data.newsEntry.content)
      setloading(false);
    }
  };

  useEffect(() => {
    if (feedsId) {
      fetchFeed(feedsId);
    }
  }, [feedsId]);

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const HandelDeleteFeeds = async () => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteFeed(
      {
        deleteNewsId: parseInt(feedsId),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Delete feed Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
        Navigate("/feeds/feedsList")
      }
    } else {
      setToastSuccess(t("Delete feed successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      Navigate("/feeds/feedsList")
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {loading ? (
        <FeedsSlugSkl />
      ) : (
        <div className="layoutContainer">
          <Model
            isOpen={deletePopupIsOpen}
            title={"Are u sure to delete this feed ?"}
            width={600}
          >
            <div className="w-full flex items-center gap-[1rem]">
              <button
                className="h-[40px] w-[180px] bg-[#C04337] rounded text-white text-[12px]"
                onClick={HandelDeleteFeeds}
              >
                {deleteLoading ? <Spinner c={"text-white"} /> : t("Delete")}
              </button>
              <button
                className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
                onClick={() => setDeletePopupIsOpen(false)}
              >
                {t("Discard")}
              </button>
            </div>
          </Model>
          <div className="flex flex-wrap justify-between gap-10">
            <div className="text-[16px] md:text-[20px] flex justify-between items-center ">
              <button onClick={() => Navigate(-1)}>
                <img src="/svg/backIcon.svg" alt="backIcon" />
              </button>
              <Link to="/feeds/feedsList">
                <span className="mx-2">{t("Feed")}</span>
              </Link>
              <DropDownIcon fill={"#133D6E"} />
              <span className="ml-2 w-full truncate">{form.title}</span>
            </div>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <button
                className="h-[40px] w-[100px] xl:w-[180px] border border-black rounded lg:mr-3"
                onClick={() => Navigate(-1)}
              >
                {t("Cancel")}
              </button>
              <button
                className="h-[40px] w-[100px] xl:w-[180px] bg-[#C04337] rounded text-white lg:mr-3"
                onClick={() => setDeletePopupIsOpen((prev) => !prev)}
              >
                {t("Delete")}
              </button>
              <button
                onClick={() => handleUpdateFeed(feedsId)}
                className="h-[40px] w-[100px] xl:w-[180px] bg-black rounded text-white"
              >
                {loading ? <Spinner c={"text-white"} /> : t("Save")}
              </button>
            </div>
          </div>
          <div className="bg-white mt-10 min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-start  justify-center xl:justify-start p-10">
            <div className=" relative text-center h-fit mt-5 ">
              <button
                onClick={() => inputRef.current.click()}
                className="z-[5] absolute left-[50%] -translate-x-[50%] top-[-1rem]"
              >
                <img src="/svg/camera.svg" />
              </button>
              <div className="flex justify-center items-center h-[137px] w-[206px] bg-[#D9D9D9] overflow-hidden">
                {file ? (
                  <img
                    src={
                      typeof file === "object"
                        ? URL.createObjectURL(file[0])
                        : file[0]
                    }
                    alt="profile pic"
                    className=" w-full  relative z-[0]"
                  />
                ) : form.picture ? (
                  <img
                    src={form.picture}
                    alt="profile pic"
                    className=" w-full  relative z-[0]"
                  />
                ) : (
                  ""
                )}
                <input
                  ref={inputRef}
                  type="file"
                  onChange={(e) => setfile(e.target.files)}
                  className="hidden"
                />
              </div>
            </div>
            <form className="flex-1 flex flex-col lg:ml-2 text-[12px]">
              <span className="font-bold mb-2">{t("Article title")}</span>
              <input
                type="text"
                name="title"
                value={form.title || ""}
                onChange={handleChange}
                placeholder="Article title"
                className="w-[300px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
              <span className="font-bold mb-2">{t("Content")}</span>
              <Editor
                value={textEditorvalue} // Pass value
                onChange={setTextEditorvalue} // Pass onChange function
                placeholder={t("Start typing...")}     
                heightEditor={"410px"}
              />
              <div className="mt-3 flex items-center">
                <input
                  id="pin"
                  type="checkbox"
                  onChange={(e) =>
                    setform({ ...form, isFeatured: e.target.checked })
                  }
                  checked={form.isFeatured}
                />
                <label for="pin" className="ml-2 font-bold">
                  {t("Publish")}
                </label>
              </div>
            </form>
            <div className="hidden xl:block absolute h-[240px] w-[1px] left-[300px] bg-[#D8D8D8]">
              {/* this div for the vertical line */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedsProfileLayout;
