import React from "react";
import { Header, SideBar } from "../../components";
import AddNewRoleLayout from "../../layouts/AddNewRoleLayout";

export const AddNewRole = () => {
  return (
    <>
      <Header />
      <AddNewRoleLayout />
      <SideBar active={"roles"} />
    </>
  );
};
