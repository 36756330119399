import React from "react";
//Translation
import { useTranslation } from "react-i18next";

const CardPlayer = ({
  name,
  birthdate,
  number,
  category,
  team,
  onCklick,
  imgSrc,
}) => {
  const {t} = useTranslation()
  return (
    <div className=" w-full  h-[265px] border border-[#C4C4C4] rounded-xl flex flex-col justify-between items-center p-3">
      <div className="w-[75px]  h-[75px] bg-[#F4F4F4] border-[0.5px] border-[#C4C4C4] rounded-full flex justify-center items-center overflow-hidden">
      {!imgSrc || imgSrc === "null" || imgSrc === null ? (
          <>
            <div className="w-[60px] h-[60px] bg-[#D9D9D9] rounded-full"></div>
          </>
        ) : (
          <img className="w-[60px] h-[60px] rounded-full" src={imgSrc} />
        )}
      </div>
      <span className="font-bold mt-2 text-[12px] text-center w-full truncate">{name}</span>
      <span className="text-[10px]">{birthdate}</span>
      <span className="text-[32px] font-[700]">{number}</span>
      <span className=" uppercase text-[black]/40 text-[12px]">{category}</span>
      <span className="text-mainBlue text-[8px]">{team.name}</span>
      <button
        onClick={onCklick}
        className="h-[25px] w-full bg-mainBlue rounded-sm text-[10px] text-white mt-4"
      >
        {t("View profile")}
      </button>
    </div>
  );
};

export default CardPlayer;
