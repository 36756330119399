import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

//hooks
import { useAuth } from "../../hooks";
import { logout } from "../../store/slices/authSlice";

//store
import { useSelector, useDispatch } from "react-redux";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import {
  uploadImage,
  updateSettings,
  getSettings,
} from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";
import ClubSettingsSkl from "../../components/Skeleton/ClubSettingsSkl";

const ClubSettingLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  // useEffect(() => {
  //   if (access_token === "" && (token === null || token === "")) {
  //     dispatch(logout());
  //     navigate("/login");
  //   }
  // }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const fetchSettings = async () => {
    setloading(true);
    const { data } = await getSettings(TOKEN);
    setform({
      clubName: data?.clubSettings?.clubName,
      tagline: data?.clubSettings?.tagline,
      picture: data?.clubSettings?.picture?.full_url,
      iban: data?.clubSettings?.iban,
      facebook:data?.clubSettings?.facebookLink,
      instagram:data?.clubSettings?.instagramLink,
      linkedin:data?.clubSettings?.linkedinLink,
      twitter:data?.clubSettings?.twitterLink,
    });
    setloading(false);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const [form, setform] = useState({
    clubName: null,
    picture: null,
    tagline: null,
    iban: null,
    facebook:null,
    instagram:null,
    linkedin:null,
    twitter:null,
  });

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleUpdateSettings = async (e) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await updateSettings(
        {
          updateSettingInput: {
            clubName: form.clubName,
            picture: parseInt(resUpload.id),
            tagline: form.tagline,
            iban: form.iban,
            facebookLink:form.facebook,
            instagramLink:form.instagram,
            linkedinLink:form.linkedin,
            twitterLink:form.twitter
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage("Update Settings Failed");
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess("Update Settings successfully");
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await updateSettings(
        {
          updateSettingInput: {
            clubName: form.clubName,
            tagline: form.tagline,
            iban: form.iban,
            facebookLink:form.facebook,
            instagramLink:form.instagram,
            linkedinLink:form.linkedin,
            twitterLink:form.twitter
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage("Update Settings Failed");
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess("Update Settings successfully");
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };
  console.log("form",form);

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {loading ? (
        <ClubSettingsSkl />
      ) : (
        <div className="layoutContainer">
          <div className="flex flex-wrap justify-between gap-10">
            <div className="text-[20px]">{t("Club settings")}</div>
            <div className="text-[12px]  flex items-center gap-[.5rem]">
              <button
                onClick={() => navigate("/dashboard")}
                className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded"
              >
                {t("Cancel")}
              </button>
              <button
                onClick={handleUpdateSettings}
                className="h-[40px] w-[100px] ml-3 lg:m-0 sm:w-[180px] bg-black rounded text-white"
              >
                {loading ? <Spinner c={"text-white"} /> : t("Save")}
              </button>
            </div>
          </div>
          <div className="bg-white mt-10 min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-center justify-center lg:justify-start px-[24px] py-[32px]">
            <div className=" relative text-center ">
              <button
                onClick={() => inputRef.current.click()}
                className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-[5]"
              >
                <img src="/svg/camera.svg" />
              </button>
              <div className="h-[137px] w-[206px] bg-[#D9D9D9] overflow-hidden">
                {file ? (
                  <img
                    src={
                      typeof file === "object"
                        ? URL.createObjectURL(file[0])
                        : file[0]
                    }
                    alt="profile pic"
                    className="object-contain h-full w-full relative z-[0]"
                  />
                ) : form.picture ? (
                  <img
                    src={form.picture}
                    alt="profile pic"
                    className="object-contain w-full h-full relative z-[0]"
                  />
                ) : (
                  ""
                )}
                <input
                  ref={inputRef}
                  type="file"
                  onChange={(e) => setfile(e.target.files)}
                  className="hidden"
                />
              </div>
            </div>
            <div className="grid lg:grid-cols-3 gap-5 lg:ml-10 text-[12px]">
            <div className="flex flex-col">
              <span className="my-2">{t("Club name")}</span>
              <input
                type="text"
                name="clubName"
                value={form.clubName || ""}
                onChange={handleChange}
                placeholder={t("Club name")}
                className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
              <span className="my-2">{t("Tagline")}</span>
              <input
                type="text"
                name="tagline"
                value={form.tagline || ""}
                onChange={handleChange}
                placeholder={t("Tagline")}
                className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
              <span className="my-2">{t("IBAN")}</span>
              <input
                type="text"
                name="iban"
                value={form.iban || ""}
                onChange={handleChange}
                placeholder={t("IBAN")}
                className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
            <div className="flex flex-col">
              <span className="my-2">{t("Facebook")}</span>
              <input
                type="text"
                name="facebook"
                value={form.facebook || ""}
                onChange={handleChange}
                placeholder={t("Facebook")}
                className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
              <span className="my-2">{t("Instagram")}</span>
              <input
                type="text"
                name="instagram"
                value={form.instagram || ""}
                onChange={handleChange}
                placeholder={t("instagram")}
                className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
              <span className="my-2">{t("Linkedin")}</span>
              <input
                type="text"
                name="linkedin"
                value={form.linkedin || ""}
                onChange={handleChange}
                placeholder={t("linkedin")}
                className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
            <div className=" flex flex-col">
              <span className="my-2">{t("Twitter")}</span>
              <input
                type="text"
                name="twitter"
                value={form.twitter || ""}
                onChange={handleChange}
                placeholder={t("twitter")}
                className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
            </div>
            <div className="hidden lg:block absolute h-[240px] w-[1px] left-[300px] top-[120px] bg-[#D8D8D8]">
              {/* this div for the vertical line */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClubSettingLayout;
