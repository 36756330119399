import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import Toast from "../../components/Toast";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

//Translation
import { useTranslation } from "react-i18next";
import {
  getSettings,
  getStatistics,
  updateSettings,
} from "../../graphql/services";
import { DashboardSkel } from "../../components";
import DashboardSkl from "../../components/Skeleton/DashboardSkl";

const DashboardLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");
  const [statistics, setStatistics] = useState(null);
  const [settings, setSettings] = useState(null);

  const [rank, setRank] = useState(settings?.teamRank);
  const [editRank, setEditRank] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  // translation
  const { t } = useTranslation();

  // chart
  // Defining data here
  const chartData = {
    options: {
      chart: {
        id: "static-chart",
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May"],
      },
    },
    series: [
      {
        name: "views",
        data: [30, 40, 45, 50, 49],
      },
    ],
  };

  const [loading, setLoading] = useState(false);
  const fetchStats = async () => {
    setLoading(true);
    const { data, error, message } = await getStatistics();
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch statistics failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setStatistics(data?.analytics);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchSettings = async () => {
    const { data, error, message } = await getSettings();
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch settings failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setSettings(data?.clubSettings);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleUpdateRank = async (e) => {
    const { data, error, message } = await updateSettings(
      {
        updateSettingInput: {
          teamRank: rank,
        },
      },
      token
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Update rank Failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setToastSuccess("rank updated successfully");
      setEditRank(false);
      fetchSettings();
      setToastERROR(false);
      setToastType("success");
      handleClick();
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {loading ? (
        <DashboardSkl />
      ) : (
        <div className="layoutContainer">
          <div className="flex justify-between">
            <h1 className=" text-[20px]">{t("Visitors")}</h1>
            {/* <div className="flex justify-between items-center flex-nowrap">
            <div className="text-[12px] mr-3">
              {t("Sort by")}:
              <span className="font-bold ml-3">{t("Select date")}</span>
            </div>
            <button>
              <img src="/svg/datePickerIcon.svg" />
            </button>
          </div> */}
          </div>
          <div className="flex flex-wrap mt-10 gap-8">
            <div className="peoples w-[355px] flex-1">
              <div className="views flex-1 h-[100px] bg-white mb-5 flex justify-between items-center px-10">
                <img src="/svg/viewsIcon.svg" />
                <div className="flex flex-col">
                  <span className="font-[500px] text-[#929292]">
                    {t("Views")}
                  </span>
                  <span className=" font-bold">512K</span>
                </div>
              </div>
              <div className="flex gap-4">
                {/* <div className="EmailSubmitted w-[50%] h-[100px] bg-white flex justify-between items-center px-5 gap-9">
                <img src="/svg/touchIcon.svg" />
                <div className="flex flex-col text-center gap-3  text-[13px]">
                  <span className="font-[500px] text-[#929292] ">
                    {t("Email submitted")}
                  </span>
                  <span className=" font-bold">512K</span>
                </div>
              </div>
              <div className="EmailSubmitted w-[50%] h-[100px] bg-white flex justify-between items-center px-5 gap-9">
                <img src="/svg/touchIcon.svg" />
                <div className="flex flex-col text-center gap-3  text-[13px]">
                  <span className="font-[500px] text-[#929292] ">
                    {t("Mitglied werden")}
                  </span>
                  <span className=" font-bold">512K</span>
                </div>
              </div> */}
              </div>
            </div>
            <div className="charts bg-white w-full h-fit order-first p-7">
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={350}
              />
            </div>
          </div>
          <h1 className=" text-[20px] mt-10">{t("Club Statics")}</h1>
          <div className="flex flex-wrap gap-4 mt-10">
            <div className="flex-1 h-[100px] bg-white flex justify-between items-center px-6  gap-3">
              <div className="w-[35px] md:w-[24px]">
                <img src="/svg/clubLogo-dash.svg" />
              </div>
              <div className="flex flex-col text-center gap-3  text-[13px]">
                <span className="font-[500px] text-[#929292]">
                  First team rank
                </span>
                {editRank ? (
                  <div className="flex gap-1">
                    <input
                      type="text"
                      name="rank"
                      value={rank || ""}
                      onChange={(e) => setRank(e.target.value)}
                      placeholder={"rank..."}
                      className="w-[100px] h-[30px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                    />
                    <button
                      onClick={handleUpdateRank}
                      className="h-[30px] w-[50px] bg-black tesxt-xs rounded text-white"
                    >
                      {t("Save")}
                    </button>
                  </div>
                ) : (
                  <span
                    onClick={() => setEditRank(true)}
                    className=" font-bold cursor-pointer"
                  >
                    {settings?.teamRank}
                  </span>
                )}
              </div>
            </div>
            <div className="flex-1 h-[100px] bg-white flex justify-between items-center px-6  gap-3">
              <div className="w-[20px] md:w-[24px]">
                <img src="/svg/team-dash-icon.svg" />
              </div>
              <div className="flex flex-col text-center gap-3  text-[13px]">
                <span className="font-[500px] text-[#929292]">
                  {t("Teams")}
                </span>
                <span className=" font-bold">{statistics?.groupsCount}</span>
              </div>
            </div>
            <div className="flex-1 h-[100px] bg-white flex justify-between items-center px-6  gap-3">
              <div className="w-[20px] md:w-[24px]">
                <img src="/svg/players-dash-icon.svg" />
              </div>
              <div className="flex flex-col text-center gap-3  text-[13px]">
                <span className="font-[500px] text-[#929292]">
                  {t("Players")}
                </span>
                <span className=" font-bold">{statistics?.playersCount}</span>
              </div>
            </div>
            <div className="flex-1 h-[100px] bg-white flex justify-between items-center px-6  gap-3">
              <div className="w-[20px] md:w-[24px]">
                <img src="/svg/heart-dash-icon.svg" />
              </div>
              <div className="flex flex-col text-center gap-3  text-[13px]">
                <span className="font-[500px] text-[#929292]">
                  {t("Fans list")}
                </span>
                <span className=" font-bold">{statistics?.fansCount}</span>
              </div>
            </div>
            <div className="flex-1 h-[100px] bg-white flex justify-between items-center px-6  gap-3">
              <div className="w-[20px] md:w-[24px]">
                <img src="/svg/sponsors-dash-icon.svg" />
              </div>
              <div className="flex flex-col text-center gap-3  text-[13px]">
                <span className="font-[500px] text-[#929292]">
                  {t("Sponsors & partners")}
                </span>
                <span className=" font-bold">{statistics?.partnersCount}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardLayout;
