import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Header, SideBar } from "../../components";

export const Dashboard = () => {
  return (
    <div>
      <Header />
      <DashboardLayout />
      <SideBar active={"dashboard"} />
    </div>
  );
};
