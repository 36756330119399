import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { CardPlayer, PlayersSkeleton } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

//hooks
import { useAuth } from "../../hooks";

// components
import Toast from "../../components/Toast";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// graphql
import { getAllPlayers, getGroups } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";

const PlayersLayout = ({ groupe }) => {
  /* Navigate dynamically to each playerProfile */
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  //fetch players by search or by navigated page by group from groupsLayout
  const [players, setplayers] = useState(null);
  const [searchedPalyers, setSearchPlayers] = useState("");
  const [order, setOrder] = useState("ASC");
  const [loading, setLoading] = useState(false);

  // for pagination comp
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);

  //fetchPlayers by groupe and pagination

  const fetchPlayers = async (groupe, skip) => {
    setLoading(true);
    const { data, error, message } = await getAllPlayers(
      {
        where: {
          group: parseInt(groupe),
          search: searchedPalyers,
        },
        take: pageSize,
        skip: Number(skip) ? Number(skip) * pageSize : 0,
        order: {
          createdAt: order,
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch players failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setplayers(data?.allPlayers);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlayers(selectedGroup, currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchPlayers(groupe);
  }, [groupe, searchedPalyers, order]);

  // fetch groups

  const [groups, setgroups] = useState(null);
  const fetchGroups = async () => {
    setLoading(true);
    const { data, error, message } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setgroups(data?.groups);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  //fetch players
  const [selectedGroup, setselectedGroup] = useState(null);
  useEffect(() => {
    if (selectedGroup) fetchPlayers(selectedGroup);
  }, [selectedGroup]);

  useEffect(() => {
    if (adminGroup) {
      setselectedGroup(adminGroup[0]?.id);
    } else {
      setselectedGroup(groupe);
    }
  }, []);

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center w-[150px]">
            {t("Team")} <DropDownIcon fill={"#133D6E"} /> {t("Players")}
          </div>
          <div className="text-[12px]">
            <Link to="/team/addNewPlayer">
              <button className="h-[40px] w-[120px] sm:w-[220px] bg-[#2B9D96] rounded text-white">
                {t("Add new player")}
              </button>
            </Link>
          </div>
        </div>
        <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
          <form className="flex justify-between flex-wrap gap-5 text-[12px]">
            <div className="text-[12px] flex flex-wrap gap-5 flex-1">
              <div className="flex justify-between items-center flex-wrap gap-4">
                <span className="font-bold">{t("Search")}</span>
                <input
                  type="text"
                  name="Search"
                  onChange={(e) =>
                    setSearchPlayers(e.target.value.toLowerCase())
                  }
                  placeholder={t("Search any")}
                  className="w-[237px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                  required
                />
              </div>
              <div className="flex justify-between items-center flex-wrap gap-4">
                <span className="font-bold">{t("Group")}</span>
                <select
                  id="#"
                  onChange={(e) => setselectedGroup(e.target.value)}
                  value={selectedGroup ? selectedGroup : null}
                  className="w-[237px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] "
                >
                  {!adminGroup && <option value={""}>all groups</option>}
                  {groups &&
                    groups?.nodes?.map((group, i) =>
                      adminGroup ? (
                        adminGroup?.some((obj) => obj.id === group.id) && (
                          <option value={group.id}>{group.name}</option>
                        )
                      ) : (
                        <option value={group.id}>{group.name}</option>
                      )
                    )}
                </select>
              </div>
            </div>
          </form>
          <div className="mt-5 md:mt-10 flex flex-wrap items-center gap-5">
            <span className="font-bold">{t("Sort By")}</span>
            <div className="flex flex-wrap gap-5">
              <button
                className={`${
                  order === "ASC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("DESC")}
              >
                {t("Added date (newest)")}
              </button>
              <button
                className={`${
                  order === "DESC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("ASC")}
              >
                {t("Added date (oldest)")}
              </button>
            </div>
          </div>
          <div className="mt-10 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 md:gap-3 lg:gap-4">
            {loading ? (
              <PlayersSkeleton />
            ) : players && players?.totalCount !== 0 ? (
              players?.nodes.map(
                (player, i) =>
                  player.firstName !== null &&
                  player.firstName !== "null" && (
                    <Link to={`/team/players/profile/${player.id}`} key={i}>
                      <CardPlayer
                        key={i}
                        imgSrc={player.picture?.full_url}
                        name={player.firstName + " " + player.lastName}
                        //birthdate={dayjs(player.dateOfBirth).format("DD MM YYYY")}
                        number={player.number}
                        category={player.position}
                        team={player.group || ""}
                        // handelViewProfile={handelViewProfile}
                      />
                    </Link>
                  )
              )
            ) : (
              t(" No players matched")
            )}
          </div>
          {/* pagination */}
          {(players && players?.totalCount > 0) || currentPage >= 1 ? (
            <div className=" w-full flex justify-center mt-10">
              <Pagination
                count={players?.totalCount}
                pagesize={pageSize}
                setPage={(e) => {
                  setCurrentPage(e);
                }}
                page={Number(currentPage) ? Number(currentPage) : 0}
                showPages={3}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PlayersLayout;