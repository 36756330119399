import React from "react";
//Translation
import { useTranslation } from "react-i18next";

const CardTrainer = ({
  key,
  name,
  Position,
  handelViewProfileTrainer,
  team,
  imgSrc,
}) => {

  const {t} = useTranslation();

  return (
    <div
      key={key}
      className="w-full h-[265px] border border-[#C4C4C4] rounded-xl flex flex-col justify-between items-center p-3"
    >
      <div className="w-[75px] h-[75px] bg-[#F4F4F4] border-[0.5px] border-[#C4C4C4] rounded-full flex justify-center items-center">
        {imgSrc && imgSrc !== null && imgSrc !== "null" ? (
          <img className="w-[60px] h-[60px] rounded-full" src={imgSrc} />
        ) : (
          <div className="w-[60px] h-[60px] bg-[#D9D9D9] rounded-full"></div>
        )}
      </div>
      <span className="font-bold mt-2 text-[12px] text-center w-[90%] truncate">
        {name}
      </span>
      {<span className=" text-[black]/40 text-center text-[12px] w-[50%] truncate">{Position ? Position : t("no phone/email added")}</span>}
      <span className="text-mainBlue text-[8px]  mt-[20px] ">
        {`${team}`}
      </span>
      <button
        onClick={handelViewProfileTrainer}
        className="h-[25px] w-full bg-mainBlue rounded-sm text-[10px] text-white mt-3"
      >
        {t("View profile")}
      </button>
    </div>
  );
};

export default CardTrainer;
