import React from 'react'
import LoginLayout from '../../layouts/LoginLayout'

export const Login = () => {
  return (
    <div>
        <LoginLayout />
    </div>
  )
}

