import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardSponsor, SponsorsSkeleton } from "../../components";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";

// graphql
import { getAllSponsors } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const SponsorsMembersLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [sponsors, setsponsors] = useState(null);
  const [order, setOrder] = useState("ASC");
  const [searchedSponsors, setSearchedSponsors] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchSponsors = async () => {
    setLoading(true);
    const { data, error, message } = await getAllSponsors(
      {
        order: {
          createdAt: order,
        },
        where: {
          search: searchedSponsors,
        },
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch sponsor failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setsponsors(data?.paginatedPartners.nodes);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSponsors();
  }, [order, searchedSponsors]);

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center w-[170px]">
            {t("Sponsors")}
          </div>
          <div className="text-[12px]">
            <Link to={"/sponsors/addNewSponsor"}>
              <button className="h-[40px] w-[120px] sm:w-[220px] bg-[#2B9D96] rounded text-white">
                {t("Add new sponsor")}
              </button>
            </Link>
          </div>
        </div>
        <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
          <form className="flex justify-between flex-wrap gap-5 text-[12px]">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold">{t("Search")}</span>
              <input
                type="text"
                name="Search"
                onChange={(e) => setSearchedSponsors(e.target.value)}
                placeholder="Search any"
                className="w-[237px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
          </form>
          <div className="mt-5 md:mt-10 flex flex-wrap items-center gap-5">
            <span className="font-bold">{t("Sort By")}</span>
            <div className="flex flex-wrap gap-5">
              <button
                className={`${
                  order === "ASC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("DESC")}
              >
                {t("Added date (newest)")}
              </button>
              <button
                className={`${
                  order === "DESC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("ASC")}
              >
                {t("Added date (oldest)")}
              </button>
            </div>
          </div>
          <div className="mt-10 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 overflow-scroll">
            {loading ? (
              <SponsorsSkeleton />
            ) : sponsors ? (
              sponsors.map((sponsor, i) => (
                <Link to={`/sponsors/sponsorsMembers/slug/${sponsor.id}`}>
                  <CardSponsor
                    key={i}
                    imgSrc={sponsor.logo?.full_url}
                    companyName={sponsor.name}
                  />
                </Link>
              ))
            ) : (
              t("there is no sponsors added at the moment")
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SponsorsMembersLayout;
