import React, { useState, useEffect } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { Link, useNavigate } from "react-router-dom";
import { InboxSlugSkel, Model } from "../../components";
import dayjs from "dayjs";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import { getMessage, deleteMessage } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const InboxSlugLayout = ({ inboxId }) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);

  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [message, setmessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchMessage = async (id) => {
    setLoading(true);
    const { data, error, message } = await getMessage(
      {
        contactId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch message failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setmessage(data.contact);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inboxId) {
      fetchMessage(inboxId);
    }
  }, [inboxId]);

  const [deleteLoading, setdeleteLoading] = useState(false);
  const handleDeletePlayer = async () => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteMessage(
      {
        softDeleteContactId: parseInt(inboxId),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Delete message Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setToastSuccess(t("Delete message successfully"));
      setToastERROR(false);
      setToastType(t("success"));
      handleClick();
      setdeleteLoading(false);
      Navigate(-1);
    }
  };

  return (
    <>
      {loading ? (
        <InboxSlugSkel />
      ) : (
        <div className="layoutContainer">
          {/* trigger this popup when clicking on delete icon */}
          <Model
            isOpen={deletePopupIsOpen}
            title={t("Are u sure to delete this mail ?")}
            width={600}
          >
            <div className="w-full flex items-center gap-[1rem]">
              <button
                className="h-[40px]  md:w-[180px] bg-[#C04337] rounded text-white mr-3 text-[12px]"
                onClick={handleDeletePlayer}
              >
                {deleteLoading ? <Spinner c={"text-white"} /> : t("Delete")}
              </button>
              <button
                className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
                onClick={() => setDeletePopupIsOpen(false)}
              >
                {t("Discard")}
              </button>
            </div>
          </Model>
          <div className="flex justify-between">
            <div className="text-[20px] flex justify-between items-center">
              <button onClick={() => Navigate(-1)}>
                <img src="/svg/backIcon.svg" />
              </button>
              <Link to={"/inbox"}>
                <span className="mx-3">{t("Inbox")}</span>
              </Link>
              <DropDownIcon fill={"#133D6E"} />
              <span className="mx-3">#{message && message.id}</span>
            </div>
            <div className="text-[12px] flex gap-3">
              <button
                className="h-[40px] w-[100px] md:w-[180px] bg-[#C04337] rounded text-white"
                onClick={() => setDeletePopupIsOpen((prev) => !prev)}
              >
                {t("Delete")}
              </button>
            </div>
          </div>
          {message ? (
            <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
              <div className=" flex justify-between">
                <div className=" flex flex-col ">
                  <h1 className="text-[24px]">
                    {message.firstName}
                    {message.lasttName}
                  </h1>
                  <span className=" text-[#757575] text-[14px]">
                    {message.email}
                  </span>
                  <span className="text-[#757575] text-[14px]">
                    {message.phoneNumber}
                  </span>
                </div>
                <div className="text-[9px]">
                  {dayjs(message.createdAt).format("HH:mm A DD/MM/YYYY")}
                </div>
              </div>
              <hr className=" my-5 text-[#979797]" />
              <p className="text-[14px] text-[#323334]">{message.message}</p>
            </div>
          ) : (
            t("no messages matched")
          )}
        </div>
      )}
    </>
  );
};

export default InboxSlugLayout;
