import React from 'react'
import { Header, SideBar } from '../../components'
import AddNewStaffLayout from '../../layouts/AddNewStaffLayout'

export  const AddNewStaff = () => {
  return (
    <>
        <Header/>
        <AddNewStaffLayout/>
        <SideBar active={'addNewStaff'}/>
    </>
  )
}