import React from 'react'
import { Header, SideBar } from '../../components'
import AddNewTrainerLayout from '../../layouts/AddNewTrainerLayout'

export const AddNewTrainer = () => {
  return (
    <>
        <Header/>
        <AddNewTrainerLayout/>
        <SideBar active={'addNewTrainer'}/>
    </>
  )
}