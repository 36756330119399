import React from "react";
import GroupsLayout from "../../layouts/GroupsLayout";
import { Header, SideBar } from "../../components";

export const Groups = () => {
  return (
    <>
      <div>
        <Header />
        <GroupsLayout />
        <SideBar active={"groups"} />
      </div>
    </>
  );
};
