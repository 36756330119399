import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardFeed from "../../components/CardFeed";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// graphql
import { addNewEvent, deleteEvent, getAllEvents, updateEvent, uploadImage } from "../../graphql/services";

// components
import Toast from "../../components/Toast";

//Translation
import { useTranslation } from "react-i18next";
import FeedsSkeleton from "../../components/Skeleton/FeedsSkeleton";
import Pagination from "../../components/Pagination";
import Model from "../../components/Model";
import Spinner from "../../components/Spinner";
import Editor from "../../components/Editor";

const EventsLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  // GET feeds
  const [events, setEvents] = useState(null);
  const [order, setOrder] = useState("DESC");
  const [searchedEvent, setsearchedEvent] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingD, setloadingD] = useState(false);//loading when delete
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(5);
  const [eventsTotCount, setEventsTotCount] = useState(null);
  const [file, setfile] = useState("");
  const inputRef = useRef();
  const [open, setOpen] = useState([{action:"", value:false}])//for Model [create - update]
  const [form, setform] = useState("");
  const [textEditorvalue, setTextEditorvalue] = useState("");
  const handleChange = (e) => setform({ ...form, [e.target.name]: e.target.value });
  /* getAllEvents */
  const fetchEvents = async (skip) => {
    setloading(true);
    const { data, error, message } = await getAllEvents(
      {
        take: pageSize,
        skip: Number(skip) ? Number(skip) * pageSize : 0,
        order: {
          updatedAt: order
        },
        where: {
          search: searchedEvent,
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch feeds failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      }
    } else {
      setEvents(data?.events?.nodes);
      setEventsTotCount(data?.events?.totalCount);
      setloading(false);
    }
  };
  useEffect(() => {
    fetchEvents(currentPage);
  }, [order, searchedEvent, currentPage]);

/* Create Event */
const createProduct = async () => {
  if (!form.title || !textEditorvalue || !file) {
      setErrorMessage(t("Please fill up all inputs with image"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
      setloading(false);
      return;
  }
  setloading(true)
  const resUpload = await uploadImage(file[0])
    const {error} = await addNewEvent(
      {
        input: {
          title: form.title,
          content: textEditorvalue,
          picture: resUpload?.id
        }
      },{}
    )
    if (error) {
      setErrorMessage(error);
      setToastERROR(true);
      setToastType("error");
      handleClick();
      setloading(false);
    } else {
      setToastSuccess(t("Event created successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setloading(false);
      setOpen(false);
      setform("");
      setfile(null);
      fetchEvents(currentPage);
  }
}
/* Remove Event */
  const removeEvent = async (id) => {
    setloadingD(true)
    const { error } = await deleteEvent({deleteEventId:parseInt(id)},TOKEN)
    if (error) {
      setErrorMessage(error);
      setToastERROR(true);
      setToastType("error");
      handleClick();
      setloadingD(false);
    } else {
      setToastSuccess(t("Event deleted successfully !"));
      fetchEvents(currentPage);
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setloadingD(false);
      setOpen(false);
      setform("");
      setfile(null);
    }
  }
/* Update Event */

const handelUpdateProduct = async (id) => {
  setloading(true);
  if (file) {
    const resUpload = await uploadImage(file[0])
    const { error } = await updateEvent(
      {
        input: {
          id: parseInt(id),
          title: form.title,
          content: textEditorvalue,
          picture: parseInt (resUpload.id)
        }
      }
      ,TOKEN)
    if (error) {
        setErrorMessage(error);
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
    } else {
        setToastSuccess(t("Event updated successfully !"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
        setOpen(false);
        setform("");
        setfile(null) 
        fetchEvents(currentPage);
    }
  } else {
    const { error } = await updateEvent(
      {
        input: {
          id: parseInt(id),
          title: form.title,
          content: textEditorvalue,
          picture: parseInt(form.picture.id)
        }
      }
      ,TOKEN)
    if (error) {
        setErrorMessage(error);
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
    } else {
        setToastSuccess(t("Event updated successfully !"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
        setOpen(false);
        setform("");
        setfile(null) 
        fetchEvents(currentPage);
    } 
  }
}


  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center w-[170px]">
            {t("Events List")}
          </div>
          <div className="text-[12px]">
              <button onClick={()=> {
                  setTextEditorvalue("")
                  setOpen({...open, action:"create",value:true});
                }}  
                className="h-[40px] w-[120px] sm:w-[220px] bg-[#2B9D96] rounded text-white"
              >
                {t("Add new Event")}
              </button>
          </div>
        </div>
        <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
          <form className="flex justify-between flex-wrap gap-5 text-[12px]">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold">{t("Search")}</span>
              <input
                type="text"
                name="Search"
                onChange={(e) => setsearchedEvent(e.target.value)}
                placeholder={t("Search any")}
                className="w-[237px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
          </form>
          <div className="mt-5 md:mt-10 flex flex-wrap items-center gap-5">
            <span className="font-bold">{t("Sort By")}</span>
            <div className="flex flex-wrap gap-5">
              <button
                className={`${
                  order === "ASC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("DESC")}
              >
                {t("Added date (newest)")}
              </button>
              <button
                className={`${
                  order === "DESC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("ASC")}
              >
                {t("Added date (oldest)")}
              </button>
            </div>
          </div>
          <div className="mt-10 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 md:gap-3 lg:gap-4">
            {loading ? (
              <FeedsSkeleton />
            ) : events && events.length !== 0 ? (
              events.map((item, i) => (
                <div key={i} className="cursor-pointer" onClick={()=>{setOpen({...open,action:"update",value:true});setform(item);setTextEditorvalue(item?.content)}}>
                  <CardFeed
                    i={i}
                    imgSrc={item?.picture?.full_url}
                    title={item.title}
                    description={item.content}
                    buttonText="View Event"
                  />
                </div>
              ))
            ) : (
              t("No events matched")
            )}
          </div>
          {/* pagination */}
            {!searchedEvent && events && eventsTotCount > 5 ? (
              <div className="w-full flex justify-center mt-10">
                <Pagination
                  count={eventsTotCount}
                  pagesize={pageSize}
                  setPage={(e) => {
                    setCurrentPage(e);
                  }}
                  page={Number(currentPage) ? Number(currentPage) : 0}
                  showPages={3}
                />
              </div>
            ) : null}
        </div>
      </div>
      <Model title={open.action==="create" ? t("Add new event") : t("Update event")} conHeight="h-full" isOpen={open.value}>
        <div className="bg-white mt-10 min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-start  justify-center xl:justify-start ">
          <div className=" relative text-center h-fit mt-5  ">
            <button
              onClick={() => inputRef.current.click()}
              className="z-[5] absolute left-[50%] -translate-x-[50%] top-[-1rem]"
            >
              <img src="/svg/camera.svg" />
            </button>
            <div className="flex justify-center items-center h-[137px] w-[206px] bg-[#D9D9D9] overflow-hidden">
              {file ? (
                <img
                  src={
                    typeof file === "object"
                      ? URL.createObjectURL(file[0])
                      : file[0]
                  }
                  alt="profile pic"
                  className=" w-full  relative z-[0]"
                />
              ) :  form.picture ? (
                <img
                  src={form.picture?.full_url}
                  alt="profile pic"
                  className=" w-full  relative z-[0]"
                />
              ) : (
                ""
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
              <div className="hidden xl:block absolute h-[240px] w-[1px] right-[-25px] bg-[#D8D8D8]">
                {/* this div for the vertical line */}
              </div>
            </div>
          </div>
          <form className="flex-1 flex flex-col lg:ml-2 text-[12px]">
            <span className="font-bold mb-2">{t("Event title")}</span>
            <input
              type="text"
              name="title"
              value={form.title || ""}
              onChange={handleChange}
              placeholder={t("Event title")}
              className="w-[300px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              required
            />
            <span className="font-bold mb-2">{t("Content")}</span>
            <Editor
              value={textEditorvalue} // Pass value
              onChange={setTextEditorvalue} // Pass onChange function
              placeholder={t("Start typing...")}     
              heightEditor={"410px"}
            />
          </form>
        </div>
        <div className="w-full md:text-sm text-xs py-[1.5rem] flex items-center justify-end gap-[1rem]">
              <button
                type="button"
                onClick={() => {
                  setOpen(false);
                  setform("")
                  setfile(null)
                }}
                className="w-[7rem] border border-[#E1E1E1] py-[.6rem] rounded-[4px]"
              >
                {t("Cancel")}
              </button>
              {open.action !== "create" &&
                <button
                  disabled={loadingD}
                  onClick={()=> removeEvent(form.id)}
                  className="w-[7rem] border py-[.6rem] text-white bg-[#C04337]  rounded-[4px]"
                >
                  {loadingD ? <Spinner c={"text-white"} /> : t("Delete")}
                </button>
              }
              <button
                disabled={loading}
                onClick={open.action === "create" ? () => createProduct() : ()=> handelUpdateProduct(form.id)}
                className="w-[7rem] border py-[.6rem] text-white bg-mainBlue  rounded-[4px]"
              >
                {loading ? <Spinner  c={"text-white"} /> : t("Save")}
              </button>
        </div>
      </Model>
    </>
  );
}

export default EventsLayout