
import React from 'react'
import { useNavigate, Link } from "react-router-dom";
//Translation
import { useTranslation } from "react-i18next";

const CardCarousel = ({ companyName, imgSrc,item ,handleDeleteCarousel }) => {
const {t} = useTranslation();
  return (
    <div className=" relative h-fit flex flex-col items-center justify-center " key={item?.id}>
      <div className="w-[200px] h-[100px] border border-[#C4C4C4] rounded-xl overflow-hidden flex justify-center items-center">
        <img src={imgSrc} alt={imgSrc} />
      </div>
      <span className="text-[14px] mt-6 capitalize">{companyName}</span>
        <button
          onClick={handleDeleteCarousel}
          className="h-[25px] w-[135px] bg-[#C04337] rounded-sm text-[10px] text-white mt-5"
        >
          {t("Delete")}
        </button>
    </div>
  )
}

export default CardCarousel