import { Skeleton } from '@mui/material'
import React from 'react'
import { RolesIcon } from '../../svg'

const OponentSlugSkl = () => {
  return (
    <div className="layoutContainer">
          <div className="flex flex-wrap justify-between gap-10">
            <Skeleton sx={{width:"250px"}}/>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
            </div>
          </div>
          <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
            <div className="flex items-center text-[12px] w-full gap-[3rem]">
              <div className="flex flex-col">
                <Skeleton variant='rounded' sx={{width:"75px" , height:"15px" , marginBottom:"10px"}}/>
                <Skeleton variant='rounded' sx={{width:"200px" , height:"40px" , marginBottom:"15px"}}/>
                <Skeleton variant='circular' sx={{width:"100px" , height:"100px" , marginBottom:"100px"  , marginLeft:"35px"}}/>
              </div>
            </div>
          </div>
    </div>
  )
}

export default OponentSlugSkl