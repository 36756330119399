import { gql } from "@apollo/client";

export const GET_GROUPS = gql`
  query Groups($skip: Int!, $take: Int!, $order: SortByFilterArgs, $where: FilterGroupDto) {
    groups(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      nodes {
        id
        name
        picture {
          full_url
        }
        trainingTime {
          id
        }
        trainers {
          id
        }
        players {
          id
        }
      }
    }
  }
`;

export const GET_ALL_STAFF = gql`
query AllStaff($skip: Int!, $take: Int!, $order: SortByFilterArgs, $where: FilterStaffInput) {
  allStaff(skip: $skip, take: $take, order: $order, where: $where) {
    id
    firstName
    lastName
    createdAt
    email
    job
    phoneNumber
    picture {
      full_url
    }
  }
}
`;

export const GET_ALL_TRAINERS = gql`
  query TeamTrainers($teamId: Float!) {
    teamTrainers(teamId: $teamId) {
      id
      group {
        id
        name
      }
      firstName
      lastName
      picture {
        full_url
      }
      position
    }
  }
`;

export const ALL_TRAINERS = gql `
query Trainers($skip: Int!, $take: Int!, $where: FilterTrainerInput, $order: SortByFilterArgs) {
  trainers(skip: $skip, take: $take, where: $where, order: $order) {
    totalCount
    nodes {
      id
    firstName
    lastName
    position
    group {
      id
      name
    }
    picture {
      full_url
    }
    }
  }
}
`

export const GET_TRAINING_TIMES = gql`
query TrainingTimes($skip: Int!, $take: Int!, $order: SortByFilterArgs) {
  trainingTimes(skip: $skip, take: $take, order: $order) {
    totalCount
    nodes {
      id
      day
      time
      group {
        id
        name
      }
    }
  }
}

`;
export const GET_TRAINING_TIMES_BY_TEAM = gql`
query TeamTrainingTime($teamId: Float!) {
  teamTrainingTime(teamId: $teamId) {
    id
    time
    day
    group {
      name
    }
  }
}
`;
export const GET_STAFF = gql`
  query StaffById($staffByIdId: Int!) {
    staffById(id: $staffByIdId) {
      email
      firstName
      id
      job
      lastName
      phoneNumber
      picture {
        id
        full_url
      }
    }
  }
`;

export const GET_TRAINER = gql`
  query Trainer($trainerId: Int!) {
    trainer(id: $trainerId) {
      id
      firstName
      lastName
      position
      picture {
        full_url
      }
      group {
        id
        name
      }
    }
  }
`;
/* group {
        id
        name
      } */

export const GET_GROUP = gql`
  query Group($groupId: Int!) {
    group(id: $groupId) {
      id
      name
      picture {
        full_url
      }
      players {
        id
      }
      trainingTime {
        id
      }
      trainers {
        id
      }
    }
  }
`;
export const GET_TRAINING_TIME = gql`
  query TrainingTime($trainingTimeId: Int!) {
    trainingTime(id: $trainingTimeId) {
      id
      group {
        name
        id
      }
      time
      day
    }
  }
`;
