import React from "react";

const Pagination = ({ count, pagesize, setPage, page, showPages = 3 }) => {

  const pages = (currentPage, last) => {
    var list = [];
    var Lastcell = null;

    for (var i = 1; i <= last; i++) {
      if (
        i === 1 ||
        (currentPage + 1 === 1 && i <= showPages) ||
        i === last ||
        (currentPage + 1 === last && i >= last - (showPages - 1)) ||
        i === currentPage + 1 ||
        (!(i <= currentPage + 1 - Math.ceil(showPages / 2)) &&
          i <= currentPage) ||
        (!(i >= currentPage + 1 + Math.floor(showPages / 2) + 1) &&
          i >= currentPage)
      ) {
        list.push(i);
        Lastcell = `${i}`;
      } else {
        if (Lastcell !== "...") {
          list.push("...");
          Lastcell = "...";
        }
      }
    }
    return list?.map((item, index) =>
      item === "..." && list[index + 1] - list[index - 1] === 2
        ? list[index - 1] + 1
        : item
    );
  };

  return (
    <div className="flex items-center border border-[#D4D4D8] h-[40px] rounded-[10px] w-fit overflow-hidden">
      <button
        type="button"
        onClick={() => {
          if (page + 1 > 1) setPage(page - 1);
        }}
        disabled={page > 0 ? false : true}
        className={`  h-full w-[40px] border-r-[2px] border-r-[#D4D4D8] flex items-center justify-center 
        ${page > 0 ? "flex" : "hidden"}
        `}
      >
        <img src="/svg/arrow-left-black.svg" alt="" />
      </button>

      <div className=" h-full flex flex-row items-center justify-center">
        {pages(page, Math.ceil(count / pagesize)).map((pageCell, index) => (
          <Cell key={index} setPage={setPage} page={page} number={pageCell} />
        ))}
      </div>

      <button
        type="button"
        onClick={() => {
          if (page + 1 < Math.ceil(count / pagesize)) setPage(page + 1);
        }}
        disabled={page + 1 < Math.ceil(count / pagesize) ? false : true}
        className={`  h-full w-[40px] border-l border-l-[#D4D4D8] flex items-center justify-center 
        ${page + 1 < Math.ceil(count / pagesize) ? "flex" : "hidden"}
        `}
      >
        <img src="/svg/arrow-right-black.svg" alt="" />
      </button>
    </div>
  );
};

export default Pagination;

const Cell = ({ setPage, page, number }) => {
  return (
    <button
      type="button"
      disabled={number === "..." || page + 1 === number ? true : false}
      onClick={() => {
        setPage(number - 1);
      }}
      className={`  text-[bg-blue] h-full w-[40px] border-r border-r-[#D4D4D8] flex items-center justify-center${
        page + 1 === number
          ? "h-full w-[40px] border-r border-r-[#D4D4D8] flex items-center justify-center bg-secondary-2 bg-mainBlue text-white"
          : number === "..."
          ? "h-full w-[40px] border-r border-r-[#D4D4D8] flex items-center justify-center"
          : "h-full w-[40px]  flex items-center justify-center"
      } `}
    >
      <span>{number}</span>
    </button>
  );
};