import React from "react";

const Spinner = ({ w, h, c }) => {
  return (
    <div
      className={`inline-block ${w ? w : "w-3"} ${h ? h : "h-3"} ${
        c ? c : "text-black"
      }  animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      role="status"
    >
      <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  );
};

export default Spinner;
