import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { useNavigate } from "react-router-dom";

//hooks
import { useAuth, reorderGroupList } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import {
  getRole,
  updateRole,
  assignRoleToGroup,
  getGroups,
} from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";
import { RolesIcon } from "../../svg";
import { RolesSlugSkl } from "../../components";

const UpdateRoleLayout = ({ role }) => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    fullName: "",
    role: "",
    email: "",
  });

  const [selectedGroups, setselectedGroups] = useState([]);
  const [groups, setgroups] = useState(null);
  const fetchGroups = async () => {
    const { data, error, message } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setgroups(reorderGroupList(data?.groups?.nodes));
    }
  };

  const getGroupsIds = (groups) => {
    let newGroups = [];
    groups.forEach((group) => {
      newGroups.push(parseInt(group.id));
    });
    return newGroups;
  };

  console.log(selectedGroups);

  const [laodingFetch, setlaodingFetch] = useState(false);
  const fetchRole = async (id) => {
    setlaodingFetch(true);
    const { data, error, message } = await getRole(
      {
        staffId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch role failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setlaodingFetch(false);
      }
    } else {
      setform({
        fullName: data?.StaffAccountById?.fullName,
        role: data?.StaffAccountById?.role,
        email: data?.StaffAccountById?.email,
      });
      setselectedGroups(getGroupsIds(data?.StaffAccountById?.groups));
      setlaodingFetch(false);
    }
  };

  useEffect(() => {
    if (role) {
      fetchRole(role);
      fetchGroups();
    }
  }, [role]);

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const checkfield = (form) => {
    let checked = false;
    if (form.fullName === "") {
      setErrorMessage(t("Enter full name"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (form.email === "") {
      setErrorMessage(t("Enter email"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (form.role === "") {
      setErrorMessage(t("Select role"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (form.role === "STAFF" && form.group === "") {
      setErrorMessage(t("Select group"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else {
      checked = true;
    }
    return checked;
  };

  const [loading, setloading] = useState(false);
  const handleUpdateRole = async (e) => {
    if (checkfield(form)) {
      setloading(true);
      const { data, error, message } = await updateRole(
        {
          input: {
            fullName: form.fullName,
            role: form.role,
            email: form.email,
          },
          userId: parseInt(role),
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update role Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        const { error, message } = await assignRoleToGroup(
          {
            userId: parseInt(role),
            groupIds: selectedGroups,
          },
          TOKEN
        );
        if (error) {
          if (message === "Unauthorized") {
            dispatch(logout());
            navigate("/login");
          } else {
            setErrorMessage(t("Update role Failed"));
            setToastERROR(true);
            setToastType("error");
            handleClick();
            setloading(false);
          }
        } else {
          setToastSuccess(t("Update role successfully"));
          setToastERROR(false);
          setToastType("success");
          handleClick();
          setloading(false);
        }
      }
    }
  };

  const addGroup = (group) => {
    let newArr = [...selectedGroups];
    newArr.push(parseInt(group));
    setselectedGroups(newArr);
  };

  const removeGroup = (id) => {
    let newArr = [...selectedGroups];
    newArr = newArr.filter((g, i) => i !== id);
    setselectedGroups(newArr);
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {laodingFetch ? (
        <RolesSlugSkl />
      ) : (
        <div className="layoutContainer">
          <div className="flex flex-wrap justify-between gap-10">
            <div className="text-[20px] flex justify-between items-center">
              {t("Roles")}
              <div className="mx-2">
                <DropDownIcon fill={"#133D6E"} />
              </div>
              {t("Update role")}
            </div>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <button
                onClick={() => navigate(-1)}
                className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded "
              >
                {t("Cancel")}
              </button>
              <button
                onClick={handleUpdateRole}
                className="h-[40px] w-[100px] sm:w-[180px] bg-black rounded text-white"
              >
                {loading ? <Spinner c={"text-white"} /> : t("Save")}
              </button>
            </div>
          </div>
          <div className=" relative bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10 overflow-hidden ">
            <div className="flex items-start justify-center gap-14 text-[12px]  sm:mt-10 lg:mt-0">
              <div className="flex flex-col text-[12px]">
                <span className="mb-1">{t("Full name")}</span>
                <input
                  type="text"
                  name="fullName"
                  value={form.fullName || ""}
                  onChange={handleChange}
                  placeholder="Full name"
                  className="w-[350px] h-[40px] text-[#757575] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                  required
                />
                <span className="mb-1">{t("Email")}</span>
                <input
                  type="email"
                  name="email"
                  value={form.email || ""}
                  onChange={handleChange}
                  placeholder="Email"
                  className="w-[350px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                  required
                />
                <span className="mb-1">{t("Role")}</span>
                <select
                  value={form.role || ""}
                  onChange={handleChange}
                  name="role"
                  className="w-[350px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                >
                  <option value="">select role</option>
                  <option value="ADMIN">Admin</option>
                  <option value="STAFF">Staff</option>
                </select>
                {form.role !== "" && form.role === "STAFF" && (
                  <>
                    <span className="mb-1">{t("Selected groups")}</span>
                    <div className="flex flex-col gap-[.3rem]">
                      <select
                        onChange={(e) => addGroup(e.target.value)}
                        className="w-[350px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px]"
                      >
                        <option value={""}>select group</option>
                        {groups &&
                          groups?.map(
                            (group, i) =>
                              !selectedGroups.includes(parseInt(group.id)) && (
                                <option value={group.id}>{group.name}</option>
                              )
                          )}
                      </select>
                      <div className="w-[350px] min-h-[40px] text-[#757575] bg-[#F8F8F8] flex flex-wrap p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2">
                        {selectedGroups.length > 0 ? (
                          selectedGroups.map((sg, i) =>
                            groups?.map(
                              (group) =>
                                parseInt(group.id) === sg && (
                                  <div className="flex items-center bg-[#e0e0e0] mb-[.3rem] mr-[.3rem] py-[.3rem] px-[.5rem] rounded-[.3rem] gap-[.3rem]">
                                    <span className="text-[.8rem] font-bold">
                                      {group.name}
                                    </span>
                                    <button
                                      onClick={() => removeGroup(i)}
                                      className="pl-[.3rem] flex item-center justify-center border-[1px] border-l-[#0000005c]"
                                    >
                                      <img
                                        src="/svg/cancelBlack.svg"
                                        width="14px"
                                        height="14px"
                                        alt=""
                                      />
                                    </button>
                                  </div>
                                )
                            )
                          )
                        ) : (
                          <span>Selected group</span>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="absolute hidden xl:block right-10 bottom-[-40px] text-[25px] text-[red]">
                <RolesIcon width="300px" height="300px" fill={"#d9e7ff"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateRoleLayout;
