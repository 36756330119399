import { useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import {
  AddNewStaff,
  AddNewTrainer,
  AddNewPlayer,
  AddNewTrainingTime,
  ClubSetting,
  Dashboard,
  Fans,
  Groups,
  Inbox,
  Login,
  Players,
  Sponsors,
  Staff,
  TrainingTime,
  AddNewFeed,
  FeedsList,
  SponsorsMembers,
  AddNewSponsor,
  FansList,
  ManageFans,
  Live,
  PlayerProfile,
  StuffProfile,
  TrainerProfile,
  TrainingTimeProfile,
  GroupProfile,
  FeedsProfile,
  SponsorSlug,
  InboxSlug,
  LiveSlug,
  Opponent,
  OppoentUpdate,
  Roles,
  AddNewRole,
  UpdateRole,
  AddNewFan,
  Shop,
  Events,
  Kantine,
} from "./pages";
import { Trainer } from "./pages/trainer";
import { AddNewGroup } from "./pages/addNewGroup";
import SponsorSlugLayout from "./layouts/SponsorSlugLayout";
import LiveDetailSlug from "./pages/liveDetailSlugLayout";
import CarouselSlider from "./pages/carouselSlider";


function App() {
  const navigate = useNavigate();
  const [isAdmin, setisAdmin] = useState(null);
  useEffect(() => {
    const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
    setisAdmin(adminGroup);
  }, [navigate]);

  return (
    <div className="App" id="container">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            !isAdmin ? <Dashboard /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/"
          element={
            !isAdmin ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/team/groups" replace />
            )
          }
        />
        <Route
          path="/clubSetting"
          element={
            !isAdmin ? <ClubSetting /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/fans"
          element={!isAdmin ? <Fans /> : <Navigate to="/team/groups" replace />}
        />
        <Route
          path="/inbox"
          element={
            !isAdmin ? <Inbox /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route path="/team/players" element={<Players />} />
        <Route path="/team/addNewPlayer" element={<AddNewPlayer />} />
        <Route
          path="/team/staff"
          element={
            !isAdmin ? <Staff /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route path="/team/addNewStaff" element={<AddNewStaff />} />
        <Route path="/team/trainer" element={<Trainer />} />
        <Route path="/team/addNewTrainer" element={<AddNewTrainer />} />
        <Route path="/team/trainingTime" element={<TrainingTime />} />
        <Route
          path="/team/addNewTrainingTime"
          element={<AddNewTrainingTime />}
        />
        <Route path="/team/groups" element={<Groups />} />
        <Route path="/team/addNewGroup" element={<AddNewGroup />} />
        <Route
          path="/feeds/feedsList"
          element={
            !isAdmin ? <FeedsList /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/feeds/addNewFeed"
          element={
            !isAdmin ? <AddNewFeed /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/events"
          element={
            !isAdmin ? (
              <Events />
            ) : (
              <Navigate to="/team/groups" replace />
            )
          }
        />
        <Route
          path="/sponsors"
          element={
            !isAdmin ? <Sponsors /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/sponsors/sponsorsMembers"
          element={
            !isAdmin ? (
              <SponsorsMembers />
            ) : (
              <Navigate to="/team/groups" replace />
            )
          }
        />
        <Route
          path="/sponsors/addNewSponsor"
          element={
            !isAdmin ? (
              <AddNewSponsor />
            ) : (
              <Navigate to="/team/groups" replace />
            )
          }
        />
        <Route
          path="/fans"
          element={!isAdmin ? <Fans /> : <Navigate to="/team/groups" replace />}
        />
        <Route
          path="/fans/addNewFan"
          element={
            !isAdmin ? <AddNewFan /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/fans/fansList"
          element={
            !isAdmin ? <FansList /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/fans/manageFans"
          element={
            !isAdmin ? <ManageFans /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route path="/live" element={<Live />} />
        <Route
          path="/opponent"
          element={
            !isAdmin ? <Opponent /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/carouselSlider"
          element={
            !isAdmin ? (
              <CarouselSlider />
            ) : (
              <Navigate to="/team/groups" replace />
            )
          }
        />
        <Route
          path="/shop"
          element={
            !isAdmin ? (
              <Shop />
            ) : (
              <Navigate to="/team/groups" replace />
            )
          }
        />
        <Route
          path="/kantine"
          element={
            !isAdmin ? (
              <Kantine />
            ) : (
              <Navigate  to="/team/groups" replace />
            )
          }
        />
        <Route
          path="/roles"
          element={
            !isAdmin ? <Roles /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/roles/addNewRole"
          element={
            !isAdmin ? <AddNewRole /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/team/players/profile/:playerId"
          element={<PlayerProfile />}
        />
        <Route path="/team/players/:groupe" element={<Players />} />
        <Route
          path="/team/staff/profile/:staffId"
          element={
            !isAdmin ? <StuffProfile /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/team/trainer/profile/:trainerId"
          element={<TrainerProfile />}
        />
        <Route
          path="/team/editTrainingTime/:trainingTimeId"
          element={<TrainingTimeProfile />}
        />
        <Route
          path="/team/editTrainingTime/groupe/:trainingTimeId"
          element={<TrainingTimeProfile />}
        />
        <Route path="/team/trainer/:groupe" element={<Trainer />} />
        <Route path="/team/trainingTime/:groupId" element={<TrainingTime />} />
        <Route
          path="/team/groups/profile/:groupsId"
          element={<GroupProfile />}
        />
        <Route
          path="/feeds/feedsList/profile/:feedsId"
          element={
            !isAdmin ? <FeedsProfile /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/sponsors/sponsorsMembers/slug/:sponsorId"
          element={
            !isAdmin ? <SponsorSlug /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route
          path="/inbox/slug/:inboxId"
          element={
            !isAdmin ? <InboxSlug /> : <Navigate to="/team/groups" replace />
          }
        />
        <Route path="/live/slug/:liveId" element={<LiveSlug />} />
        <Route
          path="/live/LiveDetail/:liveDetailId"
          element={<LiveDetailSlug />}
        />
        <Route
          path="/opponents/:opponentId"
          element={
            !isAdmin ? (
              <OppoentUpdate />
            ) : (
              <Navigate to="/team/groups" replace />
            )
          }
        />
        <Route
          path="/roles/:role"
          element={
            !isAdmin ? <UpdateRole /> : <Navigate to="/team/groups" replace />
          }
        />
        {/* end dynamic routes */}
      </Routes>
    </div>
  );
}

export default App;
