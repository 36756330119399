import React from "react";
import { Header, SideBar } from "../../components";
import AddNewFeedLayout from "../../layouts/AddNewFeedLayout";

export const AddNewFeed = () => {
  return (
    <>
      <Header />
      <AddNewFeedLayout />
      <SideBar active={"addNewFeed"} />
    </>
  );
};
