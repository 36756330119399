import React from 'react'
import { Header, SideBar } from '../../components'
import { StaffProfileLayout } from '../../layouts/StaffProfileLayout'

export const StuffProfile = () => {
  return (
    <>
        <Header/>
        <StaffProfileLayout/>
        <SideBar active={'staff'}/>
    </>
  )
}
