import React, { useState, useEffect, useRef } from "react";

// components
import { Model } from "../../components";
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

//Translation
import { useTranslation } from "react-i18next";

// graphql
import { deleteLive } from "../../graphql/services";

const RowLiveComment = ({ i, live, TOKEN, fetchLives }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const handleDeleteLive = async (id) => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteLive(
      {
        deleteLiveNewsId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      setErrorMessage(t("Delete comment Failed"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
      setdeleteLoading(false);
      setDeletePopupIsOpen(false);
    } else {
      setToastSuccess(t("Delete comment successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      setDeletePopupIsOpen(false);
      // Refresh the page after successfully deleting the comment
      window.location.reload();
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <Model
        isOpen={deletePopupIsOpen}
        title={t("Are u sure to delete this comment ?")}
        width={600}
      >
        <div className="w-full flex items-center gap-[1rem]">
          <button
            className="h-[40px] w-[180px] bg-[#C04337] rounded text-white mr-3 text-[12px]"
            onClick={() => handleDeleteLive(live.id)}
          >
            {deleteLoading ? <Spinner c={"text-white"} /> : t("Delete")}
          </button>
          <button
            className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
            onClick={() => setDeletePopupIsOpen(false)}
          >
            {t("Discard")}
          </button>
        </div>
      </Model>
      <div
        key={i}
        className="text-[12px] flex justify-between items-center w-full"
      >
        <div className="grow flex items-center">
          {/* <div className='mr-3 liveAfter'>{minute}</div>
            <div className='ml-3 flex gap-1'>
              <img src={eventIcon}/> {event} by {player} - {comment}
            </div> */}
          <p>{live.content}</p>
        </div>
        <button onClick={() => setDeletePopupIsOpen(true)}>
          <img src="/svg/delete2.svg" />
        </button>
      </div>
      <hr className="w-full h-[1px] bg-[#E7E7E7] my-3" />
    </>
  );
};

export default RowLiveComment;
