import React from "react";
import { useParams } from "react-router-dom";

import { Header, SideBar } from "../../components";
import LiveSlugLayout from "../../layouts/LiveSlugLayout";

export const LiveSlug = () => {
  const { liveId } = useParams();

  return (
    <>
      <Header />
      <LiveSlugLayout liveId={liveId} />
      <SideBar active={"live"} />
    </>
  );
};
