import { Skeleton } from '@mui/material'
import React from 'react'

const InboxSkeleton = () => {
    return (
        <table className="flex flex-col text-[12px]">
            <tr className="flex">
              {[...Array(6)].map((item) => (
                <th>
                    <Skeleton sx={{width:"100px"}}/>
                </th>
              ))}
            </tr>
            {[...Array(3)].map((item) => (
              <tr className="flex">
                {[...Array(6)].map((item) => (
                  <th className='inbox'>
                      <Skeleton sx={{width:"100px"}}/>
                  </th>
                ))}
              </tr>
            ))}
        </table>
      )
}

export default InboxSkeleton