import React from 'react'
import InboxLayout from '../../layouts/InboxLayout'
import { Header, SideBar } from '../../components'

export const Inbox = () => {
  return (
    <div>
      <Header/>
      <InboxLayout/>
      <SideBar active={'inbox'}/>  
    </div>
  )
}

