import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($input: LoginUserInput!) {
    login(input: $input) {
      access_token
      refresh_token
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      access
      refresh
    }
  }
`;
