import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { Navigate, useNavigate } from "react-router-dom";
//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import { addStaff, uploadImage } from "../../graphql/services";
//Translation
import { useTranslation } from "react-i18next";

const AddNewStaffLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    job: "",
    picture: "",
  });
  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleCreateStaff = async (e) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await addStaff(
        {
          createStaffInput: {
            firstName: form.firstName,
            email: form.email,
            lastName: form.lastName,
            picture: parseInt(resUpload.id),
            job: form.job,
            phoneNumber: form.phoneNumber,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Add staff Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Staff added successfully"));
        setToastERROR(false);
        setToastType(t("success"));
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await addStaff(
        {
          createStaffInput: {
            firstName: form.firstName,
            email: form.email,
            lastName: form.lastName,
            picture: null,
            job: form.job,
            phoneNumber: form.phoneNumber,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Add staff Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Staff added successfully"));
        setToastERROR(false);
        setToastType(t("success"));
        handleClick();
        setloading(false);
        navigate("/team/staff");
      }
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
          <div className="text-[20px] flex justify-between items-center">
            {t("Team")}
            <div className="mx-2">
              <DropDownIcon fill={"#133D6E"} />
            </div>
            {t("Add new staff")}
          </div>
          <div className="text-[12px]">
            <button
              onClick={() => navigate(-1)}
              className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded mr-3"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleCreateStaff}
              className="h-[40px] w-[100px] sm:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
          <div className=" relative text-center ">
            <button
              onClick={() => inputRef.current.click()}
              className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-[5]"
            >
              <img src="/svg/camera.svg" />
            </button>
            <div className="h-[153px] w-[153px] rounded-full bg-[#D9D9D9] overflow-hidden flex justify-center items-center">
              {file ? (
                <img
                  src={
                    typeof file === "object"
                      ? URL.createObjectURL(file[0])
                      : file[0]
                  }
                  alt="profile pic"
                  className="object-cover w-full h-full relative z-[0]"
                />
              ) : form.picture ? (
                <img
                  src={form.picture}
                  alt="profile pic"
                  className="object-cover w-full h-full relative z-[0]"
                />
              ) : (
                ""
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
            </div>
          </div>
          <form className="flex flex-wrap justify-center gap-10 text-[12px] lg:ml-10 sm:mt-10 lg:mt-0">
            <div className="flex flex-col lg:ml-10 text-[12px]">
              <span className="mb-1">{t("First name")}</span>
              <input
                type="text"
                name="firstName"
                value={form.firstName || ""}
                onChange={handleChange}
                placeholder="First name"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
              <span className="mb-1">{t("Last name")}</span>
              <input
                type="text"
                name="lastName"
                value={form.lastName || ""}
                onChange={handleChange}
                placeholder="Last name"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
              <span className="mb-1">{t("job")}</span>
              <input
                type="text"
                name="job"
                value={form.job || ""}
                onChange={handleChange}
                placeholder="Job"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
              {/* <select
                name="job"
                value={form.job || ""}
                onChange={handleChange}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              >
                <option value="">{t("job")}</option>

                <option value="PRESIDENT">{t("President")}</option>
                <option value="SECTIONHEAD">{t("sectionHead")}</option>
                <option value="YOUTHLEADER">{t("youthLeader")}</option>
                <option value="OFFICIAL">{t("official")}</option>
                <option value="DEPARTEMENTMANAGER">
                  {t("departmentManager")}
                </option>
                <option value="BOARDMEMBER">{t("BoardMember")}</option>
              </select> */}
            </div>
            <div className="flex flex-col lg:ml-10 text-[12px]">
              <span className="mb-1">Phone</span>
              <input
                type="text"
                name="phoneNumber"
                value={form.phoneNumber || ""}
                onChange={handleChange}
                placeholder="+43 777 777 7 777"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              />

              <span className="mb-1">Email</span>
              <input
                type="Email"
                name="email"
                value={form.email || ""}
                onChange={handleChange}
                placeholder="email@gmail.com"
                className="w-[230px] h-[40px] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
          </form>
          <div className="hidden xl:block absolute h-[240px] w-[1px] left-[300px] top-[140px] bg-[#D8D8D8]"></div>
        </div>
      </div>
    </>
  );
};
export default AddNewStaffLayout;
