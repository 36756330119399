import React from "react";
//Translation
import { useTranslation } from "react-i18next";

const CardStaff = ({
  key,
  name,
  Position,
  phone,
  Email,
  handelViewProfileStuff,
  imgSrc,
}) => {
  const { t } = useTranslation();
  return (
    <div
      key={key}
      className="w-full h-[265px] border border-[#C4C4C4] rounded-xl flex flex-col items-center p-3"
    >
      <div className="w-[75px] min-h-[75px] h-[75px] bg-[#F4F4F4] border-[0.5px] border-[#C4C4C4] rounded-full flex justify-center items-center">
        {!imgSrc || imgSrc === "null" || imgSrc === null ? (
          <>
            <div className="w-[60px] h-[60px] bg-[#D9D9D9] rounded-full"></div>
          </>
        ) : (
          <img className="w-[60px] h-[60px] rounded-full" src={imgSrc} />
        )}
      </div>
      <span className="font-bold my-2 text-[12px] text-center w-full truncate">
        {name}
      </span>
      <span className=" text-[black]/40 text-[12px] etxt-center">
        {/* {Position === "PRESIDENT"
          ? t("President")
          : Position === "SECTIONHEAD"
          ? t("sectionHead")
          : Position === "YOUTHLEADER"
          ? t("youthLeader")
          : Position === "OFFICIAL"
          ? t("official")
          : Position === "DEPARTEMENTMANAGER"
          ? t("departmentManager")
          : Position === "BOARDMEMBER"
          ? t("BoardMember")
          : ""} */}{" "}
        {Position}
      </span>
      <div className="flex flex-col items-center gap-1">
        <span className="text-center text-[8px] mt-2">
          <span className=" font-bold">phone: </span>
          {phone ? phone : "not added yet"}
        </span>
        <div className="text-center text-[8px] flex justify-center">
          <span className=" font-bold">Email: </span>
          <span className=" w-[60%] truncate">
            {Email ? Email : "not added yet"}
          </span>
        </div>
      </div>
      <button
        onClick={handelViewProfileStuff}
        className="h-[25px] w-full bg-mainBlue rounded-sm text-[10px] text-white mt-11"
      >
        {t("View profile")}
      </button>
    </div>
  );
};

export default CardStaff;
