import React from 'react'
import { Header, SideBar } from '../../components'
import GroupProfileLayout from '../../layouts/GroupProfileLayout'

export const GroupProfile = () => {
  return (
    <>
      <Header/>
      <GroupProfileLayout/>
      <SideBar active={'groups'}/>
    </>
  )
}