import React, { useState, useEffect } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { useNavigate } from "react-router-dom";
//hooks
import { useAuth, reorderGroupList } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import { addTrainingTime, getGroups } from "../../graphql/services";
//Translation
import { useTranslation } from "react-i18next";

const AddNewTrainingTimeLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [groups, setgroups] = useState(null);
  const fetchGroups = async () => {
    const { data } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    setgroups(reorderGroupList(data?.groups?.nodes));
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const [form, setform] = useState({
    group: "",
    day: "",
    time: "",
  });
  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const [loading, setloading] = useState(false);
  const handleCreateTrainingTime = async (e) => {
    setloading(true);
    {
      const { data, error, message } = await addTrainingTime(
        {
          createTrainingTimeInput: {
            day: form.day,
            group: parseInt(form.group),
            time: form.time,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage("Add training time Failed");
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess("training time added successfully");
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };
  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
          <div className="text-[20px] flex justify-between items-center">
            {t("Team")}
            <div className="mx-2">
              <DropDownIcon fill={"#133D6E"} />
            </div>
            {t("Add new Training time")}
          </div>
          <div className="text-[12px]">
            <button
              onClick={() => navigate(-1)}
              className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded mr-3"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleCreateTrainingTime}
              className="h-[40px] w-[100px] sm:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
          <form className="flex flex-wrap justify-center gap-10 text-[12px] lg:ml-10 sm:mt-10 lg:mt-0">
            <div className="flex flex-col lg:ml-10 text-[12px]">
              <span className="mb-1">{t("Day")}</span>
              <select
                name="day"
                value={form.day || ""}
                onChange={handleChange}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              >
                <option disabled value="">
                  {t("Day")}
                </option>
                <option value="SUNDAY">{t("Sunday")}</option>
                <option value="MONDAY">{t("Monday")}</option>
                <option value="TUESDAY">{t("Tuesday")}</option>
                <option value="WEDNESDAY">{t("Wednesday")}</option>
                <option value="THURSDAY">{t("Thursday")}</option>
                <option value="FRIDAY">{t("Friday")}</option>
                <option value="SATURDAY">{t("Saturday")}</option>
              </select>
              <span className="mb-1">{t("Time")}</span>
              <input
                type="time"
                name="time"
                value={form.time || ""}
                onChange={handleChange}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              />
            </div>
            <div className="flex flex-col">
              <span className="mb-1">{t("Group")}</span>
              <select
                name="group"
                value={form.group || ""}
                onChange={handleChange}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              >
                {!adminGroup && <option value={""}>Select group</option>}
                {groups &&
                  groups?.map((group, i) =>
                    adminGroup ? (
                      adminGroup?.some((obj) => obj.id === group.id) && (
                        <option value={group.id}>{group.name}</option>
                      )
                    ) : (
                      <option value={group.id}>{group.name}</option>
                    )
                  )}
              </select>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNewTrainingTimeLayout;
