import { gql } from "@apollo/client";

export const ADD_NEW_FEED = gql`
  mutation CreateNews($input: CreateNewsInput!) {
    createNews(input: $input) {
      id
    }
  }
`;

export const UPDATE_FEED = gql`
  mutation UpdateNews($input: UpdateNewsInput!) {
    updateNews(input: $input) {
      id
    }
  }
`;

export const DELETE_FEED = gql`
  mutation DeleteNews($deleteNewsId: Int!) {
    deleteNews(id: $deleteNewsId)
  }
`;
