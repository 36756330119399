import React from "react";
import AddNewPlayerLayout from "../../layouts/AddNewPlayerLayout";
import { Header, SideBar } from "../../components";

export const AddNewPlayer = () => {
  return (
    <>
      <Header />
      <AddNewPlayerLayout />
      <SideBar active={"addNewPlayer"} />
    </>
  );
};
