import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

//redux
import { store } from "./store";
import { Provider } from "react-redux";
import { persistStore } from "reduxjs-toolkit-persist";

//graphql
import { client } from "./graphql";
import { ApolloProvider } from "@apollo/client";

import "./translations/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
const persiteror = persistStore(store);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <div id="container">
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </div>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
