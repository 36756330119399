import React from 'react'
import { Header, SideBar } from '../../components'
import SponsorsMembersLayout from '../../layouts/SponsorsMembersLayout'

export const SponsorsMembers = () => {
  return (
    <div>
      <Header/>
      <SponsorsMembersLayout/>
      <SideBar active={'sponsorsMembers'}/>
    </div>
  )
}
