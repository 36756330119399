import { gql } from "@apollo/client";

export const CREATE_GROUP = gql`
  mutation CreateGroup($createGroupInput: CreateGroupDto!) {
    createGroup(createGroupInput: $createGroupInput) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($updateGroupInput: UpdateGroupDto!) {
    updateGroup(updateGroupInput: $updateGroupInput) {
      id
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation RemoveGroup($removeGroupId: Int!) {
    removeGroup(id: $removeGroupId) {
      deletedAt
    }
  }
`;

export const ADD_TRAINER = gql`
  mutation Mutation($createTrainerInput: CreateTrainerInput!) {
    createTrainer(createTrainerInput: $createTrainerInput) {
      id
    }
  }
`;

export const ADD_STAFF = gql`
  mutation CreateStaff($createStaffInput: CreateStaffInput!) {
    createStaff(createStaffInput: $createStaffInput) {
      id
    }
  }
`;

export const ADD_TRAINING_TIME = gql`
  mutation CreateTrainingTime(
    $createTrainingTimeInput: CreateTrainingTimeInput!
  ) {
    createTrainingTime(createTrainingTimeInput: $createTrainingTimeInput) {
      id
      day
      time
    }
  }
`;

export const UPDATE_STAFF = gql`
  mutation UpdateStaff($updateStaffInput: UpdateStaffInput!) {
    updateStaff(updateStaffInput: $updateStaffInput) {
      id
    }
  }
`;

export const DELETE_STAFF = gql`
  mutation RemoveStaff($removeStaffId: Int!) {
    removeStaff(id: $removeStaffId)
  }
`;

export const UPDATE_TRAINER = gql`
  mutation UpdateTrainer($updateTrainerInput: UpdateTrainerInput!) {
    updateTrainer(updateTrainerInput: $updateTrainerInput) {
      id
    }
  }
`;

export const DELETE_TRAINER = gql`
  mutation RemoveTrainer($removeTrainerId: Int!) {
    removeTrainer(id: $removeTrainerId)
  }
`;

export const UPDATE_TRAINING_TIME = gql`
  mutation UpdateTrainingTime(
    $updateTrainingTimeInput: UpdateTrainingTimeInput!
  ) {
    updateTrainingTime(updateTrainingTimeInput: $updateTrainingTimeInput) {
      group {
        id
        name
      }
    }
  }
`;

export const DELETE_TRAINING_TIME = gql`
  mutation RemoveTrainingTime($removeTrainingTimeId: Int!) {
    removeTrainingTime(id: $removeTrainingTimeId)
  }
`;
