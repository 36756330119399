import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { useNavigate } from "react-router-dom";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import { addNewSponsor, uploadImage } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const AddNewSponsorLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    name: "",
    logo: "",
    link: "",
    describtion:"",
  });

  
  const handleChange = (e) => {
    const {name,value}= e.target
    if (name !=="describtion") {
      setform({ ...form, [name]: value }); 
    } else {
        const MAX_CHAR_LIMIT = 110; // Change this value to set the character limit
       // check
       if (value.length <= MAX_CHAR_LIMIT) {
         setform({ ...form, describtion: value });
       }
    }
  }

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleCreateSponsor = async (e) => {
    setloading(true);
    const resUpload = await uploadImage(file[0]);
    const { data, error, message } = await addNewSponsor(
      {
        input: {
          name: form.name,
          link: form.link,
          logo: parseInt(resUpload.id),
          description:form.describtion
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Add sponsor Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      }
    } else {
      setToastSuccess(t("Add sponsor successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setloading(false);
      navigate('/sponsors/sponsorsMembers')
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
          <div className="text-[20px] flex gap-1 items-center">
            <span>{t("Sponsors")}</span>
            <div className="mx-2">
              <DropDownIcon fill={"#133D6E"} />
            </div>
            <span>{t("Add new Sponsor")}</span>
          </div>
          <div className="text-[12px] flex items-center gap-[.5rem]">
            <button
              onClick={() => navigate(-1)}
              className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleCreateSponsor}
              className="h-[40px] w-[100px] sm:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white mt-10 min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-center justify-center lg:justify-start px-[24px] py-[32px]">
          <div className=" relative text-center ">
            <button
              onClick={() => inputRef.current.click()}
              className="z-[5] absolute left-[50%] -translate-x-[50%] top-[-1rem]"
            >
              <img src="/svg/camera.svg" />
            </button>
            <div className="h-[137px] w-[206px] bg-[#D9D9D9] overflow-hidden flex justify-center items-center">
              {file ? (
                <img
                  src={
                    typeof file === "object"
                      ? URL.createObjectURL(file[0])
                      : file[0]
                  }
                  alt="profile pic"
                  className="object-cover w-full relative z-[0]"
                />
              ) : form.logo ? (
                <img
                  src={form.logo}
                  alt="profile pic"
                  className="object-cover w-full relative z-[0]"
                />
              ) : (
                ""
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
            </div>
          </div>
          <div className="flex flex-col lg:ml-10 text-[12px]">
            <span className="font-bold mb-2">{t("Company Name")}</span>
            <input
              type="text"
              name="name"
              value={form.name || ""}
              onChange={handleChange}
              placeholder={t("Company Name")}
              className="w-[237px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              required
            />
            <span className="font-bold mb-2">{t("Link")}</span>
            <input
              type="text"
              name="link"
              value={form.link || ""}
              onChange={handleChange}
              placeholder={t("link")}
              className="w-[237px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              required
            />
            <span className="font-bold mb-2">{t("Description")}</span>
            <textarea
              name="describtion"
              value={form.describtion || ""}
              onChange={handleChange}
              placeholder={t("Description")}
              className="w-[237px] h-[100px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
            />
            <span className="text-[10px]"> maximum lines: 3 </span>
          </div>
          <div className="hidden lg:block absolute h-[240px] w-[1px] left-[300px] top-[120px] bg-[#D8D8D8]">
            {/* this div for the vertical line */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewSponsorLayout;
