import * as React from "react";

//mui
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = ({
  open,
  type,
  message,
  handleClose,
  errorTxt,
  toastSuccess,
}) => {
  return (
    <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type}>
        {errorTxt ? message : toastSuccess}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
