import { Skeleton } from '@mui/material'
import React from 'react'
import { RolesIcon } from '../../svg'

const RolesSlugSkl = () => {
  return (
    <div className="layoutContainer">
          <div className="flex flex-wrap justify-between gap-10">
            <Skeleton sx={{width:"200px"}}/>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
            </div>
          </div>
          <div className=" relative bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10 overflow-hidden">
            <div className="flex items-center text-[12px] w-full gap-[3rem] ">
              <div className="flex flex-col ">
              {[...Array(3)].map(()=>(
                <>
                    <Skeleton variant='rounded' sx={{width:"75px" , height:"15px" , marginBottom:"10px"}}/>
                    <Skeleton variant='rounded' sx={{width:"200px" , height:"40px" , marginBottom:"15px"}}/>
                </>
              ))}
              <div className="absolute hidden xl:block right-10 bottom-[-40px] text-[25px] text-[red]">
                <RolesIcon width="300px" height="300px" fill={"#d9e7ff"}/>
              </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default RolesSlugSkl