import { gql } from "@apollo/client";

export const ADD_NEW_EVENT = gql`
    mutation Mutation($input: CreateEventInput!) {
      createEvent(input: $input) {
        id
      }
    }
`;

export const UPDATE_EVENT = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
      updateEvent(input: $input) {
        id
        title
        content
        link
        picture {
          id
          full_url
        }
      }
    }
`;

export const DELETE_EVENT = gql`
    mutation DeleteEvent($deleteEventId: Int!) {
      deleteEvent(id: $deleteEventId)
    }
`;
