import { client } from "../config";
import {
  ADD_NEW_ROLE,
  ASSIGN_ROLE_TO_GROUP,
  UPDATE_ROLE,
  DELET_ROLE,
} from "../mutations";
import { GET_ALL_ROLE_STAFF, GET_ROLE, GET_STAFF_GROUP } from "../queries";

export const getAllRoleStaff = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_ROLE_STAFF,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getRole = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ROLE,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getStaffGroup = async (accessToken) => {
  try {
    const result = await client.query({
      query: GET_STAFF_GROUP,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const addNewRole = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_NEW_ROLE,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createStaffAccount,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateRole = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_ROLE,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updateStaffAccount,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteRole = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELET_ROLE,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.deleteStaffAccount,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const assignRoleToGroup = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ASSIGN_ROLE_TO_GROUP,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
