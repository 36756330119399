import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";

import { useNavigate, useParams } from "react-router-dom";
//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import { Model } from "../../components";
// graphql
import {
  deleteGroup,
  getGroup,
  updateGroup,
  uploadImage,
} from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
import resizeFile from "../../hooks/resizeFile";

const GroupProfileLayout = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const param = useParams();

  const groupId = param.groupsId;

  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);
  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    groupName: "",
    picture: "",
  });

  const fetchGroup = async (id) => {
    setloading(true);
    const { data, error, message } = await getGroup(
      {
        groupId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch group failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      }
    } else {
      setform({
        groupName: data?.group.name,
        picture: data?.group?.picture?.full_url,
      });
      setloading(false);
    }
  };

  useEffect(() => {
    if (groupId) {
      fetchGroup(groupId);
    }
  }, [groupId]);

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleUpdateGroup = async (id) => {
    setloading(true);
    if (file) {
      const resizedImage = await resizeFile(file[0], 1250, 700)
      const resUpload = await uploadImage(resizedImage);
      const { data, error, message } = await updateGroup(
        {
          updateGroupInput: {
            id: parseInt(id),
            name: form.groupName,
            picture: parseInt(resUpload.id),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage("Update group Failed");
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess("Group updated successfully");
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await updateGroup(
        {
          updateGroupInput: {
            id: parseInt(id),
            name: form.groupName,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage("Update group Failed");
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess("Group updated successfully");
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const handleDeleteGroup = async () => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteGroup(
      {
        removeGroupId: parseInt(groupId),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Delete Group Failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setToastSuccess("Group deleted successfully");
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      Navigate(-1);
    }
  };
  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        {/* trigger Model when Clicking on Delete */}
        <Model
          isOpen={deletePopupIsOpen}
          title={t("Are you sure to delete this Groupe ?")}
        >
          <div className="flex gap-3">
            <button
              className="h-[40px] w-[100px] xl:w-[180px] bg-[#C04337] rounded text-white  text-[12px]"
              onClick={handleDeleteGroup}
            >
              {deleteLoading ? <Spinner c={"text-white"} /> : t("Delete")}
            </button>
            <button
              className="h-[40px] w-[100px] xl:w-[180px] bg-black rounded text-white text-[12px]"
              onClick={() => setDeletePopupIsOpen(false)}
            >
              {t("Discard")}
            </button>
          </div>
        </Model>
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center">
            <button onClick={() => Navigate(-1)}>
              <img src="/svg/backIcon.svg" />
            </button>
            <span className="mx-2">{t("Team")}</span>
            <DropDownIcon fill={"#133D6E"} />
            {loading ? (
              <Skeleton sx={{ width: "100px", marginLeft: "1rem" }} />
            ) : (
              <span className="ml-2 truncate text-ellipsis">
                {form.groupName}
              </span>
            )}
          </div>
          <div className="text-[12px] flex items-center gap-[.5rem] ">
            <button
              className="h-[40px] w-[100px] xl:w-[180px] border border-black rounded mr-3"
              onClick={() => Navigate(-1)}
            >
              {t("Cancel")}
            </button>
            <button
              className="h-[40px] w-[100px] xl:w-[180px] bg-[#C04337] rounded text-white mr-3"
              onClick={() => setDeletePopupIsOpen((prev) => !prev)}
            >
              {t("Delete")}
            </button>
            <button
              onClick={() => handleUpdateGroup(groupId)}
              className="h-[40px] w-[100px] xl:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white mt-10 min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-center justify-center lg:justify-start px-[24px] py-[32px]">
          {loading ? (
            <Skeleton sx={{ width: "206px", height: "209px" }} />
          ) : (
            <div className=" relative text-center ">
              <button
                onClick={() => inputRef.current.click()}
                className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-[1]"
              >
                <img src="/svg/camera.svg" />
              </button>
              <div className="h-[137px] w-[206px] bg-[#D9D9D9] overflow-hidden">
                {file ? (
                  <img
                    src={
                      typeof file === "object"
                        ? URL.createObjectURL(file[0])
                        : file[0]
                    }
                    alt="profile pic"
                    className="object-contain w-full h-full relative z-[0]"
                  />
                ) : form.picture ? (
                  <img
                    src={form.picture}
                    alt="profile pic"
                    className="object-contain w-full h-full  z-[0]"
                  />
                ) : (
                  ""
                )}
                <input
                  ref={inputRef}
                  type="file"
                  onChange={(e) => setfile(e.target.files)}
                  className="hidden"
                />
              </div>
            </div>
          )}
          <form className="flex flex-col lg:ml-10 text-[12px]">
            {loading ? (
              <>
                <Skeleton sx={{ width: "100px" }} />
                <Skeleton sx={{ width: "230px", height: "60px" }} />
              </>
            ) : (
              <>
                <span className="mb-1">{t("Group")}</span>
                <input
                  required
                  name="groupName"
                  value={form.groupName || ""}
                  onChange={handleChange}
                  placeholder="Group Name"
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                />
              </>
            )}
          </form>
          <div className="hidden lg:block absolute h-[240px] w-[1px] left-[300px] top-[120px] bg-[#D8D8D8]">
            {/* this div for the vertical line */}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupProfileLayout;
