import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

//jwt
import jwt from "jwt-decode";

//store
import { useDispatch } from "react-redux";
import { updateToken, logout } from "../store/slices/authSlice";

//services
import { refreshToken as refreshAccessToken } from "../graphql/services";

export const useAuth = (refreshToken, accessToken) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const didMount = useRef(false);

  const [token, setToken] = useState(null);

  const updateAcessTokenWhenExpire = async () => {
    try {
      const decodedAccess = jwt(accessToken);
      const decodedRefresh = jwt(refreshToken);

      const expAccess = new Date(decodedAccess.exp * 1000);
      const expRefresh = new Date(decodedRefresh.exp * 1000);
      const now = new Date();
      const isAccessTokenExpired = now > expAccess;
      const isRefreshTokenExpired = now > expRefresh;

      if (!isAccessTokenExpired) {
        return setToken(accessToken);
      }

      if (isRefreshTokenExpired) {
        console.log("refresh token expired", accessToken);
        navigate("/login");
        dispatch(logout());
        return setToken("");
      }

      const { data, error } = await refreshAccessToken({
        refreshToken: refreshToken,
      });

      if (!error) {
        console.log(
          "refresh token expire and was refreshed",
          data?.access_token
        );
        dispatch(updateToken({ access_token: data?.access_token }));
        return setToken(data.access_token);
      } else {
        console.log(
          "refresh token was expire and was not refreshed",
          accessToken,
          data
        );
        dispatch(logout());
        navigate("/login");
        return setToken("");
      }
    } catch (err) {
      console.log(err.message);
      dispatch(logout());
      navigate("/login");
      return setToken("");
    }
  };

  useEffect(() => {
    if (didMount.current) {
      if (accessToken?.length > 0) {
        updateAcessTokenWhenExpire();
      } else {
        return setToken("");
      }
    } else {
      didMount.current = true;
    }
  }, [didMount, accessToken]);

  return [token];
};
