import { client } from "../config";
import { CHANGE_MESSAGE_STATUS, DELETE_INBOX } from "../mutations";
import { GET_ALL_INBOXES, GET_INBOX } from "../queries";

export const getAllMessages = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_INBOXES,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getMessage = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_INBOX,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteMessage = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_INBOX,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.softDeleteContact,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
export const changeMsgStatus = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: CHANGE_MESSAGE_STATUS,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.softDeleteContact,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
