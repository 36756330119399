import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

//store
import { useSelector, useDispatch } from "react-redux";
import { login as loginStore } from "../../store/slices/authSlice";

//MUI
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import VisibilityIcon from "@mui/icons-material/Visibility";
//services
import { login, getStaffGroup } from "../../graphql/services";

// component
import Spinner from "../../components/Spinner";
import Toast from "../../components/Toast";

//Translation
import { useTranslation } from "react-i18next";

const LoginLayout = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [isLoading, setIsLoading] = useState(false);
  const { access_token } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form, setform] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { email, password } = form;
    const { data, error, message } = await login({
      input: {
        email,
        password,
      },
    });
    if (error) {
      setIsLoading(false);
      setErrorMessage(message);
      console.log("message ===> ", message);
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else {
      const {
        data: dataAdminGroup,
        error,
        message,
      } = await getStaffGroup(data?.access_token);
      if (error) {
        setIsLoading(false);
        setErrorMessage(message);
        console.log("message ===> ", message);
        setToastERROR(true);
        setToastType("error");
        handleClick();
      } else {
        setIsLoading(false);
        dispatch(
          loginStore({
            access_token: data?.access_token,
            refresh_token: data?.refresh_token,
          })
        );
        localStorage.setItem(
          "adminGroup",
          JSON.stringify(
            dataAdminGroup?.adminGroups?.length > 0
              ? dataAdminGroup?.adminGroups
              : null
          )
        );
        localStorage.setItem("isLogin", JSON.stringify(true));
        return navigate(
          dataAdminGroup?.adminGroups?.length > 0 ? "/team/groups" : "/"
        );
      }
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      if (access_token) {
        navigate("/");
      }
    };

    checkAuth();
  }, []);

  const [showPassword, setshowPassword] = useState(false);

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="h-[100vh] flex flex-col justify-between">
        <div className="lg:pl-[208px] lg:pr-[180px] px-[5%]  mt-10">
          <div className="headLogin text-center mt-[14px] lg:mt-[5rem]">
            <h1 className="text-mainBlue text-[20px] lg:text-[32px] font-bold mb-2">
              {t("Club Admin Panel")}
            </h1>
            <p className="text-[12px] lg:text-[20px]">
              {t("Welcome to your club dashboard management")}
            </p>
          </div>
          <div className="bodyLogin lg:flex justify-between items-center  h-[400px] mt-10 lg:mt-[5rem]">
            <div className="logo text-center">
              <div className="flex justify-center mb-3">
                <img
                  className="lg:w-[133px] lg:h-[174px] w-[130px] h-[105px]"
                  src="svg/1200px-SC_Columbia_Floridsdorf 2.svg"
                  alt="ss"
                />
              </div>
              <p className="text-[14.99px] font-[700]">
                sc columbia floridsdorf
              </p>
              <p className="text-[9.99px]">Traditionsverein WIEN seit 1908</p>
            </div>
            <div className="flex justify-center">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col justify-end gap-2 w-[350px] text-[12px] mt-[20px] lg:mt-0"
              >
                <span>{t("Username")}</span>
                <input
                  type="text"
                  name="email"
                  value={form.email || ""}
                  onChange={handleChange}
                  placeholder="please enter user name"
                  className="w-full h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-1"
                  required
                />
                <span>{t("Password")}</span>
                <div className="relative">
                  <input
                    type={!showPassword ? "password" : "text"}
                    name="password"
                    value={form.password || ""}
                    onChange={handleChange}
                    placeholder="please enter password"
                    className="w-full h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-3"
                    required
                  />
                  <span
                    onClick={() => setshowPassword((prev) => !prev)}
                    className=" absolute right-3 top-2 text-[#757575]"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </span>
                </div>
                <button
                  type="submit"
                  className="bg-mainBlue text-white rounded-[4px] h-[40px]"
                >
                  {isLoading ? <Spinner c={"text-white"} /> : t("Login")}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="relative footerLogin w-full h-[120px] lg:h-[128px] text-center bg-black text-[white]">
          <span className="flex items-center justify-center bg-mainBlue text-[12px] lg:text-[14px] px-[5%] w-full h-[88px]">
            © Copyright 2023 | Built by Smartinia GmbH | All Rights Reserved
          </span>
        </div>
        <div className="hidden 2xl:block absolute h-[200px] w-[1px] xl:left-[650px] 2xl:left-[780px] top-[400px] bg-[#D8D8D8]">
          {/* this div for the vertical line */}
        </div>
      </div>
    </>
  );
};

export default LoginLayout;
