import { Skeleton } from '@mui/material'
import React from 'react'

const TrainingTSlugSkl = () => {
  return (
    <div className="layoutContainer">
          <div className="flex flex-wrap justify-between gap-10">
            <Skeleton sx={{width:"250px"}}/>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
            </div>
          </div>
          <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
            <div className="flex flex-wrap justify-center gap-10 text-[12px] lg:ml-10 sm:mt-10 lg:mt-0">
              <div className="flex flex-col lg:ml-10 text-[12px]">
                <Skeleton sx={{width:"180px" , height:"60px"}}/>
                <Skeleton sx={{width:"180px" , height:"60px"}}/>
              </div>
              <div className="flex flex-col lg:ml-10 text-[12px]">
                <div className="relative flex flex-col">
                <Skeleton sx={{width:"180px" , height:"60px"}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
  )
}

export default TrainingTSlugSkl