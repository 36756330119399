import React from "react";
import AddNewGroupLayout from "../../layouts/AddNewGroupLayout";
import { Header, SideBar } from "../../components";

export const AddNewGroup = () => {
  return (
    <>
      <div id="container">
        <Header />
        <AddNewGroupLayout />
        <SideBar active={"addNewGroup"} />
      </div>
    </>
  );
};
