import { createSlice } from "@reduxjs/toolkit";

const sideBarSlice = createSlice({
  name: "sideBar",
  initialState: {
    teamDropDownIsOpened: false,
    feedsDropDownIsOpened: false,
    SponsorsDropDownIsOpened: false,
    fansDropDownIsOpened: false,
    tabletScreenIsIn: false,
  },
  reducers: {
    setDropDownIsOpened: (state, action) => {
      switch (action.payload.type) {
        case "OPEN_TABLET":
          return { ...state,tabletScreenIsIn : !state.tabletScreenIsIn };
        case "OPEN_TEAM":
          return { ...state, teamDropDownIsOpened: true,feedsDropDownIsOpened : false };
        case "CLOSE_TEAM":
          return { ...state, teamDropDownIsOpened: false};
        case "SWITCH_TEAM":
          return { ...state, teamDropDownIsOpened: !state.teamDropDownIsOpened,feedsDropDownIsOpened : false };
        case 'OPEN_FEEDS':
          return {...state, feedsDropDownIsOpened : true,teamDropDownIsOpened: false};
        case 'CLOSE_FEEDS':
          return { ...state,feedsDropDownIsOpened : false};
        case 'SWITCH_FEEDS':
          return { ...state,feedsDropDownIsOpened : !state.feedsDropDownIsOpened,teamDropDownIsOpened: false};
        case 'OPEN_SPONSORS':
          return {...state, SponsorsDropDownIsOpened : true,teamDropDownIsOpened: false,feedsDropDownIsOpened : false};
        case 'CLOSE_SPONSORS':
          return { ...state,SponsorsDropDownIsOpened : false};
        case 'SWITCH_SPONSORS':
          return { ...state,SponsorsDropDownIsOpened : !state.SponsorsDropDownIsOpened,teamDropDownIsOpened: false,feedsDropDownIsOpened : false};
        case 'OPEN_FANS':
          return {...state, fansDropDownIsOpened : true,teamDropDownIsOpened: false,feedsDropDownIsOpened : false,SponsorsDropDownIsOpened : false};
        case 'CLOSE_FANS':
          return { ...state,fansDropDownIsOpened : false};
        case 'SWITCH_FANS':
          return { ...state,fansDropDownIsOpened : !state.fansDropDownIsOpened,teamDropDownIsOpened: false,feedsDropDownIsOpened : false,SponsorsDropDownIsOpened : false};
        default:
          return state;
      }
    },
    setTabletteScreen: (state, action) => {
      switch (action.payload.type) {
        case "OPEN_TABLET":
          return { ...state,tabletScreenIsIn : !state.tabletScreenIsIn };
        default:
          return state;
      }
    },
  },
});

export const { setDropDownIsOpened,setTabletteScreen } = sideBarSlice.actions;

export default sideBarSlice.reducer;
