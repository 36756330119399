import React from 'react'
import CarouselSliderLay from '../../layouts/CarouselSliderLay'
import { Header, SideBar } from '../../components'

const CarouselSlider = () => {
  return (
    <>
      <Header/>
      <CarouselSliderLay/>
      <SideBar active={'carouselSlider'}/>  
    </>
  )
}

export default CarouselSlider