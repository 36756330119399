import { gql } from "@apollo/client";

export const ADD_NEW_ROLE = gql`
  mutation CreateStaffAccount($input: CreateStaffAccountInput!) {
    createStaffAccount(input: $input) {
      id
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateStaffAccount($input: UpdateUserInput!, $userId: Float!) {
    updateStaffAccount(input: $input, userId: $userId) {
      id
      fullName
    }
  }
`;

export const ASSIGN_ROLE_TO_GROUP = gql`
  mutation AssignAdminsToGroup($userId: Float!, $groupIds: [Float!]!) {
    assignAdminsToGroup(userId: $userId, groupIds: $groupIds)
  }
`;

export const DELET_ROLE = gql`
  mutation DeleteStaffAccount($userId: Float!) {
    deleteStaffAccount(userId: $userId)
  }
`;
