import { gql } from "@apollo/client";

export const GET_ALL_ROLE_STAFF = gql`
  query Nodes($options: FetchUsersArgs) {
    paginatedStaff(options: $options) {
      nodes {
        id
        email
        fullName
        role
        groups {
          id
          name
        }
      }
    }
  }
`;

export const GET_ROLE = gql`
  query StaffAccountById($staffId: Float!) {
    StaffAccountById(staffId: $staffId) {
      email
      id
      role
      fullName
      groups {
        id
        name
      }
    }
  }
`;

export const GET_STAFF_GROUP = gql`
  query AdminGroups {
    adminGroups {
      id
      name
    }
  }
`;
