import React from "react";
import { Header, SideBar } from "../../components";
import AddNewFanLayout from "../../layouts/AddNewFanLayout";

export const AddNewFan = () => {
  return (
    <>
      <Header />
      <AddNewFanLayout />
      <SideBar active={"addNewFan"} />
    </>
  );
};
