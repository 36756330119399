import { gql } from "@apollo/client";

export const GET_KANTINE = gql`
    query Kantine {
      kantine {
        id
        title
        description
        pictures {
          id
          full_url
        }
      }
    }
  
`;
