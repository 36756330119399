import { client } from "../config";
import { CREATE_CAROUSEL, DELETE_CAROSEL } from "../mutations";
import { GET_ALL_CAROUSEL_IMAGES } from "../queries";

export const createCarousel = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_CAROUSEL,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createCarousel,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
export const getAllCarouselImages = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_CAROUSEL_IMAGES,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
export const deleteCarousel = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_CAROSEL,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.deleteCarousel,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
