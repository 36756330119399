import { gql } from "@apollo/client";

export const GET_ALL_SPONSORS = gql`
  query PaginatedPartners(
    $order: SortByFilterArgs
    $where: FilterPartnerInput
    $take: Int!
    $skip: Int!
  ) {
    paginatedPartners(order: $order, where: $where, take: $take, skip: $skip) {
      nodes {
        id
        description
        link
        logo {
          full_url
          alt
        }
        name
      }
    }
  }
`;

export const GET_SPONSOR = gql`
  query PartnerById($partnerByIdId: Int!) {
    partnerById(id: $partnerByIdId) {
      id
      link
      description
      logo {
        full_url
      }
      name
    }
  }
`;
