import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { CardTrainingTime, TrainingTimeSkeleton } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  getTrainingTimes,
  getTrainingTimeByGrID,
  getGroups,
  getSettings,
  updateSettings,
  uploadImage,
} from "../../graphql/services";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

//Translation
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";

const TrainingTimeLayout = ({ groupId }) => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const [trainingTime, setTrainingTime] = useState([]);
  const [trainingTimeTotCount, setTrainingTimeTotCount] = useState(null);
  const [order, setOrder] = useState("DESC");
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingSkl, setLoadingSkl] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  //get training times
  const fetchTrainingTimes = async (skip) => {
    setLoadingSkl(true);
    const { data, error, message } = await getTrainingTimes({
      take: pageSize,
      skip: Number(skip) ? Number(skip) * pageSize : 0,
      order: {
        createdAt: order,
      },
    });
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch training time failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoadingSkl(false);
      }
    } else {
      setTrainingTime(data?.trainingTimes?.nodes);
      setTrainingTimeTotCount(data?.trainingTimes?.totalCount);
      setLoadingSkl(false);
    }
  };

  useEffect(() => {
    fetchTrainingTimes(currentPage);
  }, [order, currentPage]);

  //get groups
  const [groups, setgroups] = useState([]);
  const [selectedGroup, setselectedGroup] = useState(null);
  const fetchGroups = async () => {
    const { data, error, message } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setgroups(data?.groups?.nodes);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  // fetchTrainingTimesByTeam
  const [GroupTrainingT, setGroupTrainingT] = useState(null);
  const fetchTrainingTimesByTeam = async (id) => {
    setLoadingSkl(true);
    const { data, error, message } = await getTrainingTimeByGrID(
      {
        teamId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch group training time failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoadingSkl(false);
      }
    } else {
      setGroupTrainingT(data?.teamTrainingTime);
      setLoadingSkl(false);
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      fetchTrainingTimesByTeam(selectedGroup);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (adminGroup) {
      setselectedGroup(adminGroup[0]?.id);
    } else {
      setselectedGroup(groupId);
    }
  }, []);

  // Calenddar link
  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);
  const [loading, setloading] = useState(false);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [file, setfile] = useState(null);
  const inputRef = useRef();
  const [link, setLink] = useState("");
  const fetchCalendarLink = async () => {
    const { data, error, message } = await getSettings(TOKEN);
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch calendar link failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setLink(data?.clubSettings?.calendarLink);
    }
  };

  useEffect(() => {
    fetchCalendarLink();
  }, []);

  const handleUpdateCalendar = async (e) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await updateSettings(
        {
          updateSettingInput: {
            calendarLink: resUpload?.full_url,
          },
        },
        TOKEN
      );

      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update Calendar Link Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Calendar Link updated successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
        setfile(null);
        setLink(resUpload?.full_url);
      }
    } else {
      const { data, error, message } = await updateSettings(
        {
          updateSettingInput: {
            calendarLink: link,
          },
        },
        TOKEN
      );

      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update Calendar Link Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Calendar Link updated successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center w-[195px]">
            {t("Team")} <DropDownIcon fill={"#133D6E"} />
            {t("Training time")}
          </div>
          <div className="text-[12px]">
            <Link to={"/team/addNewTrainingTime"}>
              <button className="h-[40px] w-[150px] sm:w-[220px] bg-[#2B9D96] rounded text-white">
                {t("Add new training time")}
              </button>
            </Link>
          </div>
        </div>
        {!adminGroup && (
          <div className="bg-white flex flex-col gap-4 h-fit mt-10 px-[24px] py-[32px]">
            <span className="font-bold">{t("Calendar Link")}</span>
            <div className="w-full flex flex-col gap-4 items-center md:flex-row md:justify-start ">
              {!file && (
                <input
                  type="text"
                  value={link || ""}
                  onChange={(e) => setLink(e.target.value)}
                  autoComplete="off"
                  placeholder="Kalendar url"
                  className="w-[230px] lg:w-[250px] h-[40px] text-black bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px]"
                  required
                />
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
              {file && (
                <span className="text-md">
                  Your file has been uploaded successfully. Saving it will
                  convert it into a link...
                </span>
              )}
              <button
                onClick={handleUpdateCalendar}
                className="h-[40px] w-[106px] bg-mainBlue rounded text-white text-[12px]"
              >
                {loading ? <Spinner c={"text-white"} /> : t("Save")}
              </button>
              <button
                onClick={() => inputRef.current.click()}
                className="h-[40px] px-[.5rem] rounded text-black underline font-bold text-[14px]"
              >
                {t("Upload File")}
              </button>
            </div>
          </div>
        )}
        <div className="bg-white h-fit mt-5 px-[24px] py-[32px]">
          <form className="flex justify-between flex-wrap gap-5 text-[12px]">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold">{t("Group")}</span>
              <select
                id="#"
                name="#"
                value={selectedGroup ? selectedGroup : ""}
                onChange={(e) => setselectedGroup(e.target.value)}
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px]"
              >
                {!adminGroup && <option value={""}>all Groups</option>}
                {groups &&
                  groups?.map((group) =>
                    adminGroup ? (
                      adminGroup?.some((obj) => obj.id === group.id) && (
                        <option key={group?.id} value={group?.id}>
                          {group?.name}
                        </option>
                      )
                    ) : (
                      <option key={group?.id} value={group?.id}>
                        {group?.name}
                      </option>
                    )
                  )}
              </select>
            </div>
          </form>
          <div className="mt-5 md:mt-10 flex flex-wrap items-center gap-5">
            <span className="font-bold">{t("Sort By")}</span>
            <div className="flex flex-wrap gap-5">
              <button
                className={`${
                  order === "ASC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("DESC")}
              >
                {t("Added date (newest)")}
              </button>
              <button
                className={`${
                  order === "DESC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("ASC")}
              >
                {t("Added date (oldest)")}
              </button>
            </div>
          </div>
          <div className="mt-10 grid grid-cols-2 md:flex flex-row flex-wrap gap-5">
            {loadingSkl ? (
              <TrainingTimeSkeleton />
            ) : !selectedGroup ? (
              trainingTime && trainingTime.length > 0 ? (
                trainingTime?.map((item) => (
                  <Link to={`/team/editTrainingTime/${item?.id}`}>
                    <CardTrainingTime
                      key={item?.id}
                      trainingTime={item?.time}
                      day={item?.day}
                      team={item?.group?.name}
                      // handelViewProfileTrainer = { handelViewProfileTrainer }
                    />
                  </Link>
                ))
              ) : (
                "no training time matched"
              )
            ) : selectedGroup || groupId ? (
              GroupTrainingT &&
              GroupTrainingT.length > 0 &&
              GroupTrainingT.map((item) => (
                <Link to={`/team/editTrainingTime/${item?.id}`}>
                  <CardTrainingTime
                    key={item?.id}
                    trainingTime={item?.time}
                    day={item?.day}
                    team={item?.group?.name}
                    // handelViewProfileTrainer={handelViewProfileTrainer}
                  />
                </Link>
              ))
            ) : (
              "no training time matched"
            )}
          </div>
          {/* pagination */}
          {!selectedGroup && trainingTime && trainingTimeTotCount > 5 ? (
            <div className="w-full flex justify-center mt-2">
              <Pagination
                count={trainingTimeTotCount}
                pagesize={pageSize}
                setPage={(e) => {
                  setCurrentPage(e);
                }}
                page={Number(currentPage) ? Number(currentPage) : 0}
                showPages={3}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TrainingTimeLayout;
