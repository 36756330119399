import React, { useState, useRef, useEffect } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { Link, useNavigate } from "react-router-dom";
import { Model, OponentSlugSkl } from "../../components";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import {
  getOpponent,
  uploadImage,
  updateOpponent,
  deleteOpponent,
} from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

const OpponentUpdateLayout = ({ opponentId }) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [name, setname] = useState("");
  const [logo, setlogo] = useState("");

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);

  const handleUpdateOpponent = async (id) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await updateOpponent(
        {
          updateOpponentInput: {
            id: parseInt(id),
            name: name,
            picture: parseInt(resUpload.id),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update opponent Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Update opponent successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await updateOpponent(
        {
          updateOpponentInput: {
            id: parseInt(id),
            name: name,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update opponent Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Update opponent successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };

  const [loadingSkl, setLoadingSkl] = useState(false);

  const fetchOpponent = async (id) => {
    setLoadingSkl(true);
    const { data } = await getOpponent(
      {
        opponentId: parseInt(id),
      },
      TOKEN
    );
    setname(data.opponent.name);
    setlogo(data.opponent.picture.full_url);
    setLoadingSkl(false);
  };

  useEffect(() => {
    if (opponentId) {
      fetchOpponent(opponentId);
    }
  }, [opponentId]);

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const HandelDeleteOpponent = async () => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteOpponent(
      {
        removeOpponentId: parseInt(opponentId),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Delete opponent Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setToastSuccess(t("Delete opponent successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      Navigate(-1);
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {loadingSkl ? (
        <OponentSlugSkl />
      ) : (
        <div className="layoutContainer">
          <Model
            isOpen={deletePopupIsOpen}
            title={"Are u sure to delete this opponent ?"}
          >
            <div className="w-full flex items-center gap-[1rem]">
              <button
                className="h-[40px] w-[180px] bg-[#C04337] rounded text-white text-[12px]"
                onClick={HandelDeleteOpponent}
              >
                {deleteLoading ? <Spinner c={"text-white"} /> : t("Delete")}
              </button>
              <button
                className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
                onClick={() => setDeletePopupIsOpen(false)}
              >
                {t("Discard")}
              </button>
            </div>
          </Model>
          <div className="flex flex-wrap justify-between gap-10">
            <div className="text-[16px] md:text-[20px] flex justify-between items-center">
              <button onClick={() => Navigate(-1)}>
                <img src="/svg/backIcon.svg" />
              </button>
              <Link to="/opponents">
                <span className="mx-2">Opponents</span>
              </Link>
              <DropDownIcon fill={"#133D6E"} />
              <span className="ml-2">{name}</span>
            </div>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <button
                className="h-[40px] w-[100px] xl:w-[180px] border border-black rounded lg:mr-3"
                onClick={() => Navigate(-1)}
              >
                {t("Cancel")}
              </button>
              <button
                className="h-[40px] w-[100px] xl:w-[180px] bg-[#C04337] rounded text-white lg:mr-3"
                onClick={() => setDeletePopupIsOpen((prev) => !prev)}
              >
                {t("Delete")}
              </button>
              <button
                onClick={() => handleUpdateOpponent(opponentId)}
                className="h-[40px] w-[100px] xl:w-[180px] bg-black rounded text-white"
              >
                {loading ? <Spinner c={"text-white"} /> : t("Save")}
              </button>
            </div>
          </div>
          <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
            <div className="flex items-center text-[12px] w-full gap-[3rem] ">
              <div className="flex flex-col">
                <span className="mb-1">Name :</span>
                <div className="flex flex-col items-center gap-[.5rem]">
                  <input
                    type="text"
                    value={name || ""}
                    onChange={(e) => setname(e.target.value)}
                    autoComplete="off"
                    placeholder="Playing Against"
                    className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                    required
                  />
                  <div className="relative">
                    <div
                      onClick={() => inputRef.current.click()}
                      className="h-[100px] w-[100px] rounded-full  overflow-hidden flex justify-center items-center cursor-pointer"
                    >
                      {file ? (
                        <img
                          src={
                            typeof file === "object"
                              ? URL.createObjectURL(file[0])
                              : file[0]
                          }
                          alt="profile pic"
                          className="object-cover w-full h-full relative z-[0]"
                        />
                      ) : logo ? (
                        <img
                          src={logo}
                          alt="profile pic"
                          className="object-cover w-full h-full relative z-[0]"
                        />
                      ) : (
                        <div className="flex flex-col items-center">
                          {" "}
                          <img
                            src="/svg/upload.svg"
                            alt="uplaod svg"
                            className="w-[22px] "
                          />
                          <span>Logo</span>
                        </div>
                      )}
                      <input
                        ref={inputRef}
                        type="file"
                        onChange={(e) => setfile(e.target.files)}
                        className="hidden"
                      />
                    </div>
                    {(file || logo) && (
                      <button
                        onClick={() => {
                          setlogo("");
                          setfile(null);
                        }}
                        className="absolute top-0 right-0 w-[15px] h-[15px] rounded-full bg-[red] text-white flex items-center justify-center"
                      >
                        <img
                          src="/svg/cancel.svg"
                          className="w-[10px]"
                          alt=""
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OpponentUpdateLayout;
