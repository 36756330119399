import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { useNavigate, Link } from "react-router-dom";
import { Model, RolesSkeleton, TableRolesList } from "../../components";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import { getAllRoleStaff, deleteRole } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const RolesLayout = () => {
  const rolesTableHeadItems = ["Name", "Email", "Groups", "Role", "Edit"];

  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [roles, setroles] = useState([]);
  const [searchedRoles, setSearchedRoles] = useState("");
  const [loading, setLoading] = useState(false);
  const fetchRoles = async () => {
    setLoading(true);
    const { data, error, message } = await getAllRoleStaff(
      {
        options: {
          where: {
            search: searchedRoles,
          },
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch roles failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setroles(data?.paginatedStaff?.nodes);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [searchedRoles]);

  const [table, setTableRow] = useState({ id: null });
  const [deleteLoading, setdeleteLoading] = useState(false);
  const handleDeleteRole = async (id) => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteRole(
      {
        userId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Delete role Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setToastSuccess(t("Delete role successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      setTableRow({ id: null });
      fetchRoles();
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center w-[255px]">
            {t("Roles")} <DropDownIcon fill={"#133D6E"} />
            {t("Roles management")}
          </div>
          <div className="text-[12px]">
            <Link to={"/roles/addNewRole"}>
              <button className="h-[40px] w-[120px] sm:w-[220px] bg-[#2B9D96] rounded text-white">
                {t("Add new role")}
              </button>
            </Link>
          </div>
        </div>
        <div className="bg-white h-fit mt-10 px-[24px] py-[32px] ">
          <form className="flex justify-between flex-wrap gap-5 text-[12px]">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold mb-2">{t("Search")}</span>
              <input
                type="text"
                name="Search"
                onChange={(e) => setSearchedRoles(e.target.value)}
                placeholder="Search any"
                className="w-[237px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
          </form>
          <div className="mt-10 flex flex-row flex-wrap overflow-scroll">
            {loading ? (
              <RolesSkeleton />
            ) : (
              <table className="flex flex-col text-[12px]">
                <tr className="flex">
                  {rolesTableHeadItems.map((item) => (
                    <th>{t(item)}</th>
                  ))}
                </tr>
                {roles && roles.lenght !== 0
                  ? roles.map((role, i) => (
                      <>
                        <TableRolesList
                          i={i}
                          role={role}
                          setTableRow={setTableRow}
                        />
                      </>
                    ))
                  : t("there is no roles matched")}
              </table>
            )}
            <Model
              isOpen={table.id}
              title={t("Are u sure to delete this Role ?")}
              width={700}
            >
              <div className="w-full flex items-center gap-[1rem]">
                <button
                  className="h-[40px] w-[180px] bg-[#C04337] rounded text-white mr-3 text-[12px]"
                  onClick={() => handleDeleteRole(table.id)}
                >
                  {deleteLoading ? <Spinner c={"text-white"} /> : t("Delete")}
                </button>
                <button
                  className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
                  onClick={() => setTableRow({ id: null })}
                >
                  {t("Discard")}
                </button>
              </div>
            </Model>
          </div>
        </div>
      </div>
    </>
  );
};

export default RolesLayout;
