import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";

import { useNavigate, useParams, Link } from "react-router-dom";
//hooks
import { useAuth, reorderGroupList } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import { Model, PlayerSlugSkel } from "../../components";
//services
import {
  deleteStaff,
  getGroups,
  getStaff,
  updateStaff,
  uploadImage,
} from "../../graphql/services";
//Translation
import { useTranslation } from "react-i18next";

export const StaffProfileLayout = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const param = useParams();
  const staffId = param.staffId;

  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);
  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    job: "",
    picture: "",
  });

  const fetchStaff = async (id) => {
    setloading(true);
    const { data, error, message } = await getStaff(
      {
        staffByIdId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch staff failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setform({
        firstName: data.staffById.firstName,
        lastName: data.staffById.lastName,
        phoneNumber: data.staffById.phoneNumber,
        email: data.staffById.email,
        job: data.staffById.job,
        picture: data.staffById?.picture?.full_url,
      });
      setloading(false);
    }
  };

  useEffect(() => {
    if (staffId) {
      fetchStaff(staffId);
      /* console.log("form?.picture",form?.picture); */
    }
  }, [staffId]);

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleUpdateStaff = async (id) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await updateStaff(
        {
          updateStaffInput: {
            id: parseInt(id),
            firstName: form.firstName,
            lastName: form.lastName,
            phoneNumber: form.phoneNumber,
            email: form.email,
            job: form.job,
            picture: parseInt(resUpload.id),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update staff Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Staff updated successfully"));
        setToastERROR(false);
        setToastType(t("success"));
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await updateStaff(
        {
          updateStaffInput: {
            id: parseInt(id),
            firstName: form.firstName,
            lastName: form.lastName,
            phoneNumber: form.phoneNumber,
            email: form.email,
            job: form.job,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update staff Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Staff updated successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const handleDeleteStaff = async () => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteStaff(
      {
        removeStaffId: parseInt(staffId),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Delete staff Failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setToastSuccess("Delete staff successfully");
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      Navigate(-1);
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {loading ? (
        <PlayerSlugSkel />
      ) : (
        <div className="layoutContainer">
          {/* trigger Model when Clicking on Delete */}
          <Model
            isOpen={deletePopupIsOpen}
            title={t("Are you sure to delete this stuff ?")}
          >
            <button
              className="h-[40px] w-[180px] bg-[#C04337] rounded text-white mr-3 text-[12px]"
              onClick={handleDeleteStaff}
            >
              {deleteLoading ? <Spinner c={"text-white"} /> : t("Delete")}
            </button>
            <button
              className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
              onClick={() => setDeletePopupIsOpen(false)}
            >
              {t("Discard")}
            </button>
          </Model>
          <div className="flex flex-wrap justify-between gap-10">
            <div className="text-[16px] md:text-[20px] flex justify-between items-center ">
              <button onClick={() => Navigate(-1)}>
                <img src="/svg/backIcon.svg" />
              </button>
              <span className="mx-2">{t("Team")}</span>
              <DropDownIcon fill={"#133D6E"} />
              <span className="mx-2">{t("Staff")}</span>
              <DropDownIcon fill={"#133D6E"} />
              <span className=" ml-2 truncate text-ellipsis">
                {form.firstName} {form.lastName}
              </span>
            </div>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <button
                className="h-[40px] w-[100px] xl:w-[180px] border border-black rounded mr-3"
                onClick={() => Navigate(-1)}
              >
                {t("Cancel")}
              </button>
              <button
                className="h-[40px] w-[100px] xl:w-[180px] bg-[#C04337] rounded text-white mr-3"
                onClick={() => setDeletePopupIsOpen((prev) => !prev)}
              >
                {t("Delete")}
              </button>
              <button
                onClick={() => handleUpdateStaff(staffId)}
                className="h-[40px] w-[100px] xl:w-[180px] bg-black rounded text-white"
              >
                {loading ? <Spinner c={"text-white"} /> : t("Save")}
              </button>
            </div>
          </div>
          <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
            <div className=" relative text-center ">
              <button
                onClick={() => inputRef.current.click()}
                className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-[5]"
              >
                <img src="/svg/camera.svg" />
              </button>
              <div className="h-[153px] w-[153px] rounded-full bg-[#D9D9D9] flex justify-center items-center overflow-hidden">
                {file ? (
                  <img
                    src={
                      typeof file === "object"
                        ? URL.createObjectURL(file[0])
                        : file[0]
                    }
                    alt="profile pic"
                    className="object-cover w-full relative z-[0]"
                  />
                ) : form.picture ? (
                  <img
                    src={form.picture}
                    alt="profile pic"
                    className="  z-[0]"
                  />
                ) : (
                  ""
                )}
                <input
                  ref={inputRef}
                  type="file"
                  onChange={(e) => setfile(e.target.files)}
                  className="hidden"
                />
              </div>
            </div>
            <form className="flex flex-wrap justify-center gap-10 text-[12px] lg:ml-10 sm:mt-10 lg:mt-0">
              <div className="flex flex-col lg:ml-10 text-[12px]">
                <span className="mb-1">{t("First name")}</span>
                <input
                  type="text"
                  name="firstName"
                  value={form.firstName || ""}
                  onChange={handleChange}
                  placeholder="First name"
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                  required
                />
                <span className="mb-1">{t("Last name")}</span>
                <input
                  type="text"
                  name="lastName"
                  value={form.lastName || ""}
                  onChange={handleChange}
                  placeholder="Last name"
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                  required
                />
                <span className="mb-1">{t("job")}</span>
                <input
                  type="text"
                  name="job"
                  value={form.job || ""}
                  onChange={handleChange}
                  placeholder="Job"
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                  required
                />
              </div>
              <div className="flex flex-col lg:ml-10 text-[12px]">
                <span className="mb-1">{t("Phone Number")}</span>
                <input
                  type="text"
                  name="phoneNumber"
                  value={form.phoneNumber || ""}
                  onChange={handleChange}
                  placeholder="+43 777 777 7 777"
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                />

                <span className="mb-1">Email</span>
                <input
                  type="Email"
                  name="email"
                  value={form.email || ""}
                  onChange={handleChange}
                  placeholder="email@gmail.com"
                  className="w-[230px] h-[40px] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                  required
                />
              </div>
            </form>
            <div className="hidden xl:block absolute h-[240px] w-[1px] left-[300px] top-[140px] bg-[#D8D8D8]">
              {/* this div for the vertical line */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
