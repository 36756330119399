import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";

import { Link, useNavigate, useParams } from "react-router-dom";
//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import { Model, TrainingTSlugSkl } from "../../components";

//services
import {
  deleteTrainingTime,
  getGroups,
  getTrainingTime,
  updateTrainingTime,
} from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const TrainingTimeProfileLayout = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);
  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    groupID: "",
    groupName: "",
    day: "",
    time: "",
  });

  const [groups, setgroups] = useState(null);
  const fetchGroups = async () => {
    const { data, error, message } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setgroups(data?.groups?.nodes);
    }
  };
  useEffect(() => {
    fetchGroups();
  }, []);

  //fetch trainingTimeById
  const { trainingTimeId } = useParams();
  const fetchTrainingTime = async (id) => {
    setloading(true);
    const { data, error, message } = await getTrainingTime(
      {
        trainingTimeId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch traing times failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      }
    } else {
      setform({
        groupID: data?.trainingTime?.group?.id || "",
        day: data?.trainingTime?.day,
        time: data?.trainingTime?.time,
        groupName: data?.trainingTime?.group?.name || "",
      });
      setloading(false);
    }
  };

  useEffect(() => {
    if (trainingTimeId) {
      fetchTrainingTime(trainingTimeId);
    }
  }, [trainingTimeId]);

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const [loading, setloading] = useState(false);
  const handleUpdateTrainingTime = async (id) => {
    setloading(true);
    {
      const { data, error, message } = await updateTrainingTime(
        {
          updateTrainingTimeInput: {
            id: parseInt(id),
            group: parseInt(form.groupID),
            day: form.day,
            time: form.time,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update training time Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Training time updated successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const handleDeleteTrainingTime = async () => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteTrainingTime(
      {
        removeTrainingTimeId: parseInt(trainingTimeId),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Delete Training time Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setToastSuccess(t("Training time deleted successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      Navigate(-1);
    }
  };
  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {loading ? (
        <TrainingTSlugSkl />
      ) : (
        <div className="layoutContainer">
          {/* trigger Model when Clicking on Delete */}
          <Model
            isOpen={deletePopupIsOpen}
            title={"Are you sure you want to delete Training time ?"}
          >
            <button
              className="h-[40px] w-[180px] bg-[#C04337] rounded text-white mr-3 text-[12px]"
              onClick={handleDeleteTrainingTime}
            >
              {deleteLoading ? <Spinner c={"text-white"} /> : "Delete"}
            </button>
            <button
              className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
              onClick={() => setDeletePopupIsOpen(false)}
            >
              Discard
            </button>
          </Model>
          <div className="flex gap-10">
            <div className="text-[16px] md:text-[20px] flex items-center w-full truncate">
              <button onClick={() => Navigate(-1)}>
                <img src="/svg/backIcon.svg" />
              </button>
              <span className="mx-2">Team</span>
              <DropDownIcon fill={"#133D6E"} />
              <Link to="/trainigTime">
                <span className="mx-2">Training time</span>
              </Link>
              <DropDownIcon fill={"#133D6E"} />
              <span className="ml-2"> {form?.groupName} </span>
            </div>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <button
                className="h-[40px] w-[100px] xl:w-[180px] border border-black rounded "
                onClick={() => Navigate(-1)}
              >
                Cancel
              </button>
              <button
                className="h-[40px] w-[100px] xl:w-[180px] bg-[#C04337] rounded text-white "
                onClick={() => setDeletePopupIsOpen((prev) => !prev)}
              >
                Delete
              </button>
              <button
                onClick={() => handleUpdateTrainingTime(trainingTimeId)}
                className="h-[40px] w-[100px] xl:w-[180px] bg-black rounded text-white"
              >
                {loading ? <Spinner c={"text-white"} /> : "Save"}
              </button>
            </div>
          </div>
          <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
            <form className="flex flex-wrap justify-center gap-10 text-[12px] lg:ml-10 sm:mt-10 lg:mt-0">
              <div className="flex flex-col lg:ml-10 text-[12px]">
                <span className="mb-1">Day</span>
                <select
                  name="day"
                  value={form?.day || ""}
                  onChange={handleChange}
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                >
                  <option value="SUNDAY">SUNDAY</option>
                  <option value="MONDAY">MONDAY</option>
                  <option value="TUESDAY">TUESDAY</option>
                  <option value="WEDNESDAY">WEDNESDAY</option>
                  <option value="THRUSDAY">THRUSDAY</option>
                  <option value="FRIDAY">FRIDAY</option>
                </select>
                <span className="mb-1">Time</span>
                <input
                  type="time"
                  name="time"
                  value={form?.time || ""}
                  onChange={handleChange}
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                />
              </div>
              <div className="flex flex-col lg:ml-10 text-[12px]">
                <span className="mb-1">Group</span>
                <select
                  name="groupID"
                  value={form?.groupID || ""}
                  onChange={handleChange}
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                >
                  {!adminGroup && <option value={""}>Select group</option>}
                  {groups &&
                    groups?.map((group, i) =>
                      adminGroup ? (
                        adminGroup?.some((obj) => obj.id === group.id) && (
                          <option value={group.id}>{group.name}</option>
                        )
                      ) : (
                        <option value={group.id}>{group.name}</option>
                      )
                    )}
                </select>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
export default TrainingTimeProfileLayout;
