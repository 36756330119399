import { Skeleton } from '@mui/material'
import React from 'react'

const FeedsSkeleton = () => {
  return (
    <>
        {[...Array(5)].map(()=> (
            <div className="w-[165px] h-[265px] border border-[#C4C4C4] rounded-xl flex flex-col  justify-between items-center ">
            <Skeleton sx={{width:"70px",height:"110px",borderRadius:"50%",backgroundColor:"#F4F4F4"}} />
            <Skeleton sx={{width:"100px" , height:"15px",marginBottom:"40px"}}/>
            <Skeleton sx={{width:"110px", height:"10px"}}/>
            <Skeleton sx={{width:"110px", height:"10px"}}/>
            <Skeleton sx={{width:"110px", height:"10px",marginBottom:"10px"}}/>
            <Skeleton sx={{width:"130px",height:"40px", marginBottom:"10px"}}/>
        </div>))}
    </>
  )
}

export default FeedsSkeleton