import { gql } from "@apollo/client";

export const GET_ALL_CAROUSEL_IMAGES = gql`
    query Carousel {
        carousel {
          id
          picture {
            id
            full_url
          }
        }
    }
`;