import { client } from "../config";
import { UPDATE_CALENDAR_LINK, UPDATE_SETTINGS } from "../mutations";
import { GET_CALENDAR_LINK, GET_SETTINGS } from "../queries";

export const updateSettings = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_SETTINGS,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updateSetting,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getSettings = async (accessToken) => {
  try {
    const result = await client.query({
      query: GET_SETTINGS,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
