import React from 'react'
import { Header, SideBar } from '../../components'
import KantineLayout from '../../layouts/KantineLayout'

const Kantine = () => {
  return (
    <div>
        <Header/>
        <KantineLayout/>
        <SideBar active={'kantine'}/>
    </div>
  )
}

export default Kantine