import React, { useRef, useState } from "react";
import { SettingsIcon } from "../../svg";
import { useDispatch, useSelector } from "react-redux";
import { setSettingsMenuIsOpened } from "../../store/slices/settingsMenuSlice";
import SettingMenu from "../SettingMenu";
import useOnClickOutside from "../../hooks/outsideClick";
import MenuIcon from "@mui/icons-material/Menu";
import { setTabletteScreen } from "../../store/slices/sideBarSlice";

//Translation
import { TraductionButton } from "./TraductionButton";
import { useTranslation } from "react-i18next";

const Header = () => {
  const settingsMenuIsOpened = useSelector(
    (state) => state.settingsMenu.settingsMenuIsOpened
  );
  const [isManageAdmin, setManageAdminIsOpen] = useState(false);

  const dispatch = useDispatch();
  const handleToggleSettingsMenu = () => {
    dispatch(setSettingsMenuIsOpened());
  };
  /* click outSide settingsMenu */
  const settings = useRef();
  useOnClickOutside(settings, () => {
    dispatch(setSettingsMenuIsOpened());
  });

  const ToggleTablettSideBar = () => {
    dispatch(setTabletteScreen({ type: "OPEN_TABLET" }));
  };

  const { t } = useTranslation();

  return (
    <div className="fixed top-0 left-0 z-20 w-full bg-black h-[100px] text-white flex justify-between  items-center px-10">
      <div className="flex items-center justify-between w-full md:w-[430px]">
        <div className="block md:hidden">
          <button onClick={ToggleTablettSideBar}>
            <MenuIcon className=" hidden lg:block" />
          </button>
        </div>
        <div className="logo hidden md:flex items-center">
          <img src="/svg//Dashboard-logo.svg" alt="logo" />
          <div className="flex flex-col ml-3">
            <span className="text-[8.7px] uppercase font-[700] leading-[11.85px]">
              sc columbia floridsdorf
            </span>
            <span className="text-[5.8px]">
              Traditionsverein WIEN seit 1908
            </span>
          </div>
        </div>
        <div className="hidden md:block absolute w-[1.5px] h-[60px] bg-[#FFFFFF33] left-[262px]">
          {/* this div is for the vertical line */}
        </div>
        <div className="w-[30px] h-[30px] block md:hidden w-full flex justify-center items-center">
          <img src="/svg/Dashboard-logo.svg" alt="logo" />
        </div>
        {<span className="capitalize md:text-[13px] text-[12px] hidden md:block md:relative absolute translate-x-[-50%] md:translate-x-0 left-[50%] md:left-0  ">
          {t("Welcome to your Club Panel")}
         </span>}
      </div>
      <div className="flex gap-3">
        <TraductionButton />
        <button
          onClick={() => {
            handleToggleSettingsMenu();
            setManageAdminIsOpen(false);
          }}
          className="md:block hidden"
        >
          <SettingsIcon fill="#ffffff" width="24" height="24" />
        </button>
      </div>
      {/* Toggle Settings Menu when clicking ouside - referenced as 'settings' */}
      <SettingMenu
        settings={settings}
        isOpen={settingsMenuIsOpened}
        setManageAdminIsOpen={setManageAdminIsOpen}
      />   
    </div>
  );
};

export default Header;
