import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enTranslations from "./en.json";
import deTranslations from "./de.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "de",
    resources: {
      de: {
        translations: deTranslations,
      },
      en: {
        translations: enTranslations,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
    detection: {
      order: ["localStorage"],
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"],
      excludeCacheFor: [],
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

i18n.languages = ["en", "de"];

export default i18n;
