import React, { useEffect, useState } from "react";
import { RolesIcon, SettingsIcon } from "../../svg";
import { useNavigate } from "react-router-dom";

//store
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/authSlice";
import { setSettingsMenuIsOpened } from "../../store/slices/settingsMenuSlice";

//Translation
import { useTranslation } from "react-i18next";

const SettingMenu = ({ settings, isOpen, setManageAdminIsOpen }) => {
  const { t } = useTranslation();

// group staff
const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
console.log("adminGroup",adminGroup);

  const handleToggleSettingsMenu = () => {
    dispatch(setSettingsMenuIsOpened());
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    localStorage.setItem("adminGroup", JSON.stringify(null));
    navigate("/login");
  };

  return (
    <>
      {isOpen && (
        <div
          ref={settings}
          className="absolute z-10 top-[70px] right-8 w-[209px] bg-white shadow-xl"
        >
          <div className="w-full flex flex-col justify-center items-center bg-mainBlue py-1">
            <h1 className="text-[15px] font-[600] mb-2">{adminGroup ? "STAFF" : t("Admin")} </h1>
            <span className="text-[10px] text-white/50">{adminGroup ? adminGroup?.map((admin)=> <span className=" block mb-2 ">{admin?.name}</span>) : t("Owner")}</span>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div className="flex justify-center items-center mt-4">
              {!adminGroup && 
                <div className="my-5">
                  <button
                    onClick={() => {
                      handleToggleSettingsMenu();
                      navigate("/roles")
                    }}
                    className="flex items-center"
                  >
                    <RolesIcon fill="#000000" width="16" height="16" />
                    <span className="ml-3 text-[13px] text-black">
                      {t("Manage Profile")}
                    </span>
                  </button>
                </div>}
            </div>
            <button
              onClick={handleLogout}
              className="h-[40px] w-[191px] text-white text-[12px] bg-[#C04337] rounded mb-4"
            >
              {t("SIGN OUT")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default SettingMenu;
