import { gql } from "@apollo/client";

export const MANAGE_FAN = gql`
  mutation UpdateFan($updateFanInput: UpdateFanInput!) {
    updateFan(updateFanInput: $updateFanInput) {
      id
    }
  }
`;

export const ADD_NEW_FAN = gql`
  mutation CreateFan($createFanInput: CreateFanInput!) {
    createFan(createFanInput: $createFanInput) {
      id
    }
  }
`;

export const DELETE_FAN = gql`
  mutation RemoveFan($removeFanId: Int!) {
    removeFan(id: $removeFanId)
  }
`;
