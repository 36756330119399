import { gql } from "@apollo/client";

export const DELETE_INBOX = gql`
  mutation SoftDeleteContact($softDeleteContactId: Int!) {
    softDeleteContact(id: $softDeleteContactId) 
  }
`;
export const CHANGE_MESSAGE_STATUS = gql`
  mutation Mutation($updateContactStatusId: Float!, $updateContactEntryInput: UpdateContactEntryInput!) {
    updateContactStatus(id: $updateContactStatusId, updateContactEntryInput: $updateContactEntryInput) {
      id
    }
  }
`;
