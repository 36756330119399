import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "reduxjs-toolkit-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";

//slices
import authSlice from "../slices/authSlice";
import sideBarSlice from "../slices/sideBarSlice";
import settingsMenuSlice from "../slices/settingsMenuSlice";
import inboxSlice from "../slices/inboxSlice";

const reducers = combineReducers({
  auth: authSlice,
  sideBar: sideBarSlice,
  settingsMenu : settingsMenuSlice,
  inbox:inboxSlice,
});

const persistConfig = {
  key: "root",
  timeout: 500,
  storage: storage,
  stateReconciler: hardSet,
  blacklist: ["navigation"],
};

const _persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: _persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware({
    serializableCheck: {
      /* ignore persistance actions */
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
