import { client } from "../config";
import { UPDATE_KANTINE } from "../mutations/kantine.mutation";
import { GET_KANTINE } from "../queries";

export const getKantine = async (variables, accessToken) => {
    try {
      const result = await client.query({
        query: GET_KANTINE,
        variables,
        context: { accessToken },
        fetchPolicy: "no-cache",
      });
      return result;
    } catch (error) {
      return {
        data: null,
        error: true,
        message: error.message,
      };
    }
  };
export const updateKantine = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_KANTINE,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};