import React from 'react'
import { StaffLayout } from '../../layouts/StaffLayout'
import { Header, SideBar } from '../../components'

export const Staff = () => {
  return (
    <>
        <Header/>
        <StaffLayout/>
        <SideBar active={'staff'}/>
    </>
  )
}
