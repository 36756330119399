import { client } from "../config";
import { ADD_NEW_PRODUCT, DELETE_PRODUCT, UPDATE_PRODUCT } from "../mutations";
import { GET_ALL_PODUCTS } from "../queries";



export const getAllProducts = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_PODUCTS,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

/* export const getSponsor = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_SPONSOR,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
}; */

export const addNewProduct = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_NEW_PRODUCT,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.createPartner,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateProduct = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PRODUCT,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.updatePartner,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteProduct = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_PRODUCT,
      variables,
      context: { accessToken },
    });
    return {
      data: data?.deletePartner,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
