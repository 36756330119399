import { gql } from "@apollo/client";

export const GET_ALL_FANS = gql`
  query ExampleQuery($skip: Int!, $take: Int!, $where: FilterFanInput) {
  fans(skip: $skip, take: $take, where: $where) {
    totalCount
    nodes {
      id
      lastName
      firstName
      status
    }
  }
}

`;
