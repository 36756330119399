import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";

import { Link, useNavigate, useParams } from "react-router-dom";
//hooks
import { useAuth, reorderGroupList } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import { Model, PlayerSlugSkel, TrainerSlugSkl } from "../../components";

//services
import {
  deleteTrainer,
  getGroups,
  getTrainer,
  updateTrainer,
  uploadImage,
} from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const TrainerProfileLayout = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const navigate = useNavigate();
  const param = useParams();
  const trainerId = param.trainerId;

  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);
  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [form, setform] = useState({
    firstName: "",
    group: "",
    lastName: "",
    picture: "",
    position: "",
  });

  const [groups, setgroups] = useState(null);
  const fetchGroups = async () => {
    const { data, error, message } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setgroups(reorderGroupList(data?.groups?.nodes));
    }
  };

  const fetchTrainer = async (id) => {
    setloading(true);
    const { data, error, message } = await getTrainer(
      {
        trainerId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch trainer failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      }
    } else {
      setform({
        firstName: data?.trainer?.firstName,
        lastName: data?.trainer?.lastName,
        picture: data?.trainer?.picture?.full_url,
        group: data?.trainer?.group?.id,
        position: data?.trainer?.position,
      });
      setloading(false);
    }
  };
  useEffect(() => {
    if (trainerId) {
      fetchGroups();
      fetchTrainer(trainerId);
    }
  }, [trainerId]);

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [loading, setloading] = useState(false);
  const handleUpdateTrainer = async (id) => {
    setloading(true);
    if (file) {
      const resUpload = await uploadImage(file[0]);
      const { data, error, message } = await updateTrainer(
        {
          updateTrainerInput: {
            id: parseInt(id),
            group: parseInt(form.group),
            firstName: form.firstName,
            lastName: form.lastName,
            position: form.position,
            picture: parseInt(resUpload.id),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update trainer Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Trainer updated successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    } else {
      const { data, error, message } = await updateTrainer(
        {
          updateTrainerInput: {
            id: parseInt(id),
            group: parseInt(form.group),
            firstName: form.firstName,
            lastName: form.lastName,
            position: form.position,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Update trainer Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Trainer updated successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
      }
    }
  };

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const handleDeleteTrainer = async () => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteTrainer(
      {
        removeTrainerId: parseInt(trainerId),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Delete Trainer Failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setToastSuccess("Delete Trainer successfully");
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      Navigate(-1);
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {/* trigger Model when Clicking on Delete */}
      <Model
        isOpen={deletePopupIsOpen}
        title={"Are you sure you want to delete this Trainer ?"}
      >
        <button
          className="h-[40px] w-[180px] bg-[#C04337] rounded text-white mr-3 text-[12px]"
          onClick={handleDeleteTrainer}
        >
          {deleteLoading ? <Spinner c={"text-white"} /> : "Delete"}
        </button>
        <button
          className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
          onClick={() => setDeletePopupIsOpen(false)}
        >
          Discard
        </button>
      </Model>
      {loading ? (
        <TrainerSlugSkl />
      ) : (
        <div className="layoutContainer">
          <div className="flex flex-wrap justify-between gap-10">
            <div className="flex flex-wrap justify-between gap-10">
              <div className="text-[16px] md:text-[20px] flex justify-between items-center ">
                <button onClick={() => Navigate(-1)}>
                  <img src="/svg/backIcon.svg" />
                </button>
                <span className="mx-3">Team</span>
                <DropDownIcon fill={"#133D6E"} />
                <Link to="/team/trainer">
                  <span className="mx-2 ">Trainers</span>
                </Link>
                <DropDownIcon fill={"#133D6E"} />
                <span className="ml-3 truncate text-ellipsis">
                  {form.firstName + " " + form.lastName}
                </span>
              </div>
            </div>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <button
                className="h-[40px] w-[100px] xl:w-[180px] border border-black rounded mr-3"
                onClick={() => Navigate(-1)}
              >
                Cancel
              </button>
              <button
                className="h-[40px] w-[100px] xl:w-[180px] bg-[#C04337] rounded text-white mr-3"
                onClick={() => setDeletePopupIsOpen((prev) => !prev)}
              >
                Delete
              </button>
              <button
                onClick={() => handleUpdateTrainer(trainerId)}
                className="h-[40px] w-[100px] xl:w-[180px] bg-black rounded text-white"
              >
                {loading ? <Spinner c={"text-white"} /> : "Save"}
              </button>
            </div>
          </div>
          <div className="bg-white min-h-[316px] mt-10 flex flex-wrap gap-10 items-center justify-center xl:justify-start p-10">
            <div className=" relative text-center">
              <button
                onClick={() => inputRef.current.click()}
                className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-10 "
              >
                <img src="/svg/camera.svg" />
              </button>
              <div className="h-[153px] w-[153px] rounded-full bg-[#D9D9D9] overflow-hidden flex justify-center items-center">
                {file ? (
                  <img
                    src={
                      typeof file === "object"
                        ? URL.createObjectURL(file[0])
                        : file[0]
                    }
                    alt="profile pic"
                    className=" object-cover relative h-full w-full"
                  />
                ) : form.picture ? (
                  <img src={form.picture} alt="profile pic" />
                ) : (
                  ""
                )}
                <input
                  ref={inputRef}
                  type="file"
                  onChange={(e) => setfile(e.target.files)}
                  className="hidden"
                />
              </div>
            </div>
            <form className="flex flex-wrap justify-center gap-10 text-[12px] lg:ml-10 sm:mt-10 lg:mt-0">
              <div className="flex flex-col lg:ml-10 text-[12px]">
                <span className="mb-1">First name</span>
                <input
                  type="text"
                  name="firstName"
                  value={form.firstName || ""}
                  onChange={handleChange}
                  placeholder="First name"
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                  required
                />
                <span className="mb-1">Last name</span>
                <input
                  type="text"
                  name="lastName"
                  value={form.lastName || ""}
                  onChange={handleChange}
                  placeholder="Last name"
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                  required
                />
              </div>
              <div className="flex flex-col lg:ml-10 text-[12px]">
                <span className="mb-1">Group</span>
                <select
                  name="group"
                  value={form.group || ""}
                  onChange={handleChange}
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                >
                  {!adminGroup && <option value={""}>Select group</option>}
                  {groups &&
                    groups?.map((group, i) =>
                      adminGroup ? (
                        adminGroup?.some((obj) => obj.id === group.id) && (
                          <option value={group.id}>{group.name}</option>
                        )
                      ) : (
                        <option value={group.id}>{group.name}</option>
                      )
                    )}
                </select>
                <span className="mb-1">{t("Phone or Email")}</span>
                <input
                  type="text"
                  name="position"
                  placeholder={t("phone or email")}
                  value={form.position || ""}
                  onChange={handleChange}
                  className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                />
              </div>
            </form>
            <div className="hidden xl:block absolute h-[240px] w-[1px] left-[300px] top-[140px] bg-[#D8D8D8]">
              {/* this div for the vertical line */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrainerProfileLayout;
