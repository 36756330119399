import { Skeleton } from '@mui/material'
import React from 'react'

const SponsorsSlugSkl = () => {
  return (
    <div className="layoutContainer">
          <div className="flex flex-wrap justify-between gap-10">
            <Skeleton sx={{width:"250px"}}/>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
              <Skeleton sx={{width:"180px" , height:"60px"}}/>
            </div>
          </div>
          <div className="bg-white mt-10 min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-start  justify-center xl:justify-start p-10">
          <div className=" relative text-center h-fit mt-5 ">
            <Skeleton variant="rectangular" sx={{width:"206px" , height:"137px"}}/>
          </div>
          <form className="flex flex-col lg:ml-10 text-[12px] sm:w-full md:w-[550px]">
            <Skeleton variant="rectangular" sx={{width:"206px" , height:"40px",marginTop:"60px"}}/>
          </form>
          <div className="hidden xl:block absolute h-[240px] w-[1px] left-[300px] top-[150px] bg-[#D8D8D8]">
            {/* this div for the vertical line */}
          </div>
        </div>
    </div>
  )
}

export default SponsorsSlugSkl