import React, { useState, useEffect } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { Link, useNavigate } from "react-router-dom";
import { deleteGroup, getGroup, getGroups } from "../../graphql/services";
import Toast from "../../components/Toast";
import { GroupsSkeleton, TableGroupe } from "../../components";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

//Translation
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination";

const GroupsLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const delete_Group = async (id) => {
    setLoading(true);
    const { data, error, message } = await deleteGroup(
      {
        removeGroupId: id,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("remove Group failed");
        setToastERROR(true);
        setToastType("error");
        setLoading(false);
        handleClick();
      }
    } else {
      setToastSuccess("Group deleted successfully");
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setLoading(false);
      setDeletePopupIsOpen(false);
      fetchGroups();
    }
  };

  //get allGroups

  const [groups, setGroups] = useState([]);
  const [PaginatedGroups, setPAgGroups] = useState([]);
  const [orderGroup, setOrderGroup] = useState("ASC");
  // for pagination comp
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(2);
  const fetchGroups = async (skip) => {
    setLoading(true);
    const { data, error, message } = await getGroups({
      take: pageSize,
      skip: Number(skip) ? Number(skip) * pageSize : 0,
      order: {
        createdAt: orderGroup,
      },
    });
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setLoading(false);
      setGroups(data?.groups);
    }
  };

  useEffect(() => {
    fetchGroups(currentPage);
  }, [orderGroup, currentPage]);

  const fetchPaginatedGroups = async () => {
    setLoading(true);
    const { data, error, message } = await getGroups({
      take: 100,
      skip: 0,
    });
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setLoading(false);
      setPAgGroups(data?.groups);
    }
  };
  useEffect(() => {
    fetchPaginatedGroups();
  }, []);
  // console.log("PaginatedGroups",PaginatedGroups?.length);

  //get GroupById
  const [group, setGroup] = useState(null);
  const [selectedGroup, setselectedGroup] = useState(null);
  const fetchGroupById = async (id) => {
    setLoading(true);
    const { data, error, message } = await getGroup({
      groupId: parseInt(id),
    });
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch group failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setLoading(false);
      setGroup(data?.group);
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      fetchGroupById(selectedGroup);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (adminGroup) setselectedGroup(adminGroup[0]?.id);
  }, []);

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />

      <div className="layoutContainer">
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center w-[145px]">
            {t("Team")} <DropDownIcon fill={"#133D6E"} /> {t("Groups")}{" "}
          </div>
          <div className="text-[12px]">
            <Link to={"/team/addNewGroup"}>
              <button className="h-[40px] w-[120px] sm:w-[220px] bg-[#2B9D96] rounded text-white">
                {t("Add new Group")}
              </button>
            </Link>
          </div>
        </div>
        <div className="bg-white h-[90vh] mt-10 px-[24px] py-[32px]">
          <form className="flex justify-between flex-wrap gap-5 text-[12px]">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold">{t("Group")}</span>
              <select
                id="#"
                name="#"
                className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] "
                value={selectedGroup ? selectedGroup : ""}
                onChange={(e) => {
                  setselectedGroup(parseInt(e.target.value));
                }}
              >
                {!adminGroup && <option value={""}>all groups</option>}
                {PaginatedGroups &&
                  PaginatedGroups?.nodes?.map((group) =>
                    adminGroup ? (
                      adminGroup?.some((obj) => obj.id === group.id) && (
                        <option
                          name="group"
                          id="group"
                          value={group?.id}
                          key={group?.id}
                        >
                          {group?.name}
                        </option>
                      )
                    ) : (
                      <option
                        name="group"
                        id="group"
                        value={group?.id}
                        key={group?.id}
                      >
                        {group?.name}
                      </option>
                    )
                  )}
              </select>
            </div>
          </form>
          <div className="mt-10 flex flex-wrap items-center gap-7">
            <span className="font-bold">{t("Sort By")}</span>
            <div className="flex flex-wrap gap-5">
              <button
                className={`${
                  orderGroup === "ASC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrderGroup("DESC")}
              >
                {t("Added date (newest)")}
              </button>
              <button
                className={`${
                  orderGroup === "DESC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrderGroup("ASC")}
              >
                {t("Added date (oldest)")}
              </button>
            </div>
          </div>
          <div className="mt-10 flex flex-row flex-wrap gap-5 overflow-scroll">
            {loading ? (
              <GroupsSkeleton />
            ) : !selectedGroup ? (
              groups && groups?.nodes?.length !== 0 ? (
                groups?.nodes?.map((item, i) =>
                  adminGroup ? (
                    adminGroup.some((obj) => obj.id === item.id) && (
                      <TableGroupe
                        i={i}
                        group={item?.name}
                        groupId={parseInt(item?.id)}
                        clubImg={item?.picture}
                        playersNumber={item?.players?.length}
                        trainerNumber={item?.trainers?.length}
                        trainingTimeNumber={item?.trainingTime?.length}
                        trainingTimeID={item?.trainingTime?.id}
                        /* editGroup={editGroup} */
                        deleteGroup={delete_Group}
                        loading={loading}
                        setLoading={setLoading}
                        deletePopupIsOpen={deletePopupIsOpen}
                        setDeletePopupIsOpen={setDeletePopupIsOpen}
                      />
                    )
                  ) : (
                    <TableGroupe
                      i={i}
                      group={item?.name}
                      groupId={parseInt(item?.id)}
                      clubImg={item?.picture}
                      playersNumber={item?.players?.length}
                      trainerNumber={item?.trainers?.length}
                      trainingTimeNumber={item?.trainingTime?.length}
                      trainingTimeID={item?.trainingTime?.id}
                      /* editGroup={editGroup} */
                      deleteGroup={delete_Group}
                      loading={loading}
                      setLoading={setLoading}
                      deletePopupIsOpen={deletePopupIsOpen}
                      setDeletePopupIsOpen={setDeletePopupIsOpen}
                    />
                  )
                )
              ) : (
                "there is no groups matched at the moment"
              )
            ) : group ? (
              group && adminGroup ? (
                adminGroup.some((obj) => obj.id === group?.id) && (
                  <TableGroupe
                    group={group?.name}
                    groupId={parseInt(group?.id)}
                    clubImg={group?.picture}
                    playersNumber={group?.players?.length}
                    trainerNumber={group?.trainers?.length}
                    trainingTimeNumber={group?.trainingTime?.length}
                    /* editGroup={editGroup} */
                    deleteGroup={delete_Group}
                    loading={loading}
                    setLoading={setLoading}
                    deletePopupIsOpen={deletePopupIsOpen}
                    setDeletePopupIsOpen={setDeletePopupIsOpen}
                  />
                )
              ) : (
                <TableGroupe
                  group={group?.name}
                  groupId={parseInt(group?.id)}
                  clubImg={group?.picture}
                  playersNumber={group?.players?.length}
                  trainerNumber={group?.trainers?.length}
                  trainingTimeNumber={group?.trainingTime?.length}
                  /* editGroup={editGroup} */
                  deleteGroup={delete_Group}
                  loading={loading}
                  setLoading={setLoading}
                  deletePopupIsOpen={deletePopupIsOpen}
                  setDeletePopupIsOpen={setDeletePopupIsOpen}
                />
              )
            ) : (
              "there is no groups matched at the moment"
            )}
          </div>
          {/* pagination */}
          {!selectedGroup &&
            PaginatedGroups &&
            PaginatedGroups?.nodes?.length > 0 ? (
              <div className="w-full flex justify-center mt-10">
                  <Pagination
                    count={PaginatedGroups?.nodes?.length}
                    pagesize={pageSize}
                    setPage={(e) => {
                      setCurrentPage(e);
                    }}
                    page={Number(currentPage) ? Number(currentPage) : 0}
                    showPages={3}
                  />
              </div>
            ) : null}
        </div>
      </div>
    </>
  );
};
export default GroupsLayout;
