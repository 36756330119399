import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { useTranslation } from 'react-i18next';

const Editor = ({ value, onChange, placeholder,heightEditor }) => {
  const { t } = useTranslation();
  const editor = useRef(null);
  const [content, setContent] = useState(value || ''); // Set initial content from value prop

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || t("Start typing..."),
      sizeLG: 1200,
      sizeMD: 700,
      sizeSM: 400,
      //height: '345px',
      height: heightEditor,
    }),
    [placeholder]
  );

  // Update local state when value prop changes
  useEffect(() => {
    setContent(value || '');
  }, [value]);

  const handleBlur = (newContent) => {
    setContent(newContent);
    if (onChange) {
      onChange(newContent); // Pass the new content up to the parent component
    }
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1}
      onBlur={handleBlur}
      onChange={() => {}}
    />
  );
};

export default Editor;
