import axios from "axios";

export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);

  try {
    const response = await axios.post(
      "https://staged-api-floridsdorf.smartiniaclient.com/upload",
      formData
      // {
      //   onUploadProgress,
      // }
    );
    return console.log(response), response.data;
  } catch (error) {
    console.log(error);
    console.error(error);
  }
};
