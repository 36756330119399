import { gql } from "@apollo/client";

export const GET_STATISTICS = gql`
  query Analytics {
    analytics {
      contactsCount
      groupsCount
      playersCount
      fansCount
      partnersCount
    }
  }
`;
