import { client } from "../config";
// import {} from "../queries";
import { LOGIN, REFRESH_TOKEN } from "../mutations";

export const login = async (variables) => {
  try {
    const { data } = await client.mutate({
      mutation: LOGIN,
      variables,
    });
    return {
      data: data?.login,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const refreshToken = async (variables) => {
  try {
    const { data } = await client.mutate({
      mutation: REFRESH_TOKEN,
      variables,
    });
    return {
      data: data?.refreshToken,
      error: false,
    };
  } catch (error) {
    return {
      data: error.message,
      error: true,
    };
  }
};
