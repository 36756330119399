import { gql } from "@apollo/client";

export const GET_ALL_LIVES = gql`
  query LiveNews($where: FilterLiveNewsInput) {
    liveNews(where: $where) {
      nodes {
        id
        content
      }
    }
  }
`;

export const GET_LIVE = gql`
  query LiveNewsEntry($liveNewsEntryId: Int!) {
    liveNewsEntry(id: $liveNewsEntryId) {
      content
      id
    }
  }
`;

export const GET_ALL_GAMES = gql`
  query Games($skip: Int!, $take: Int!) {
    games(skip: $skip, take: $take) {
      nodes {
        id
        group {
          name
          id
        }
        goalsIn
        goalsFor
        leagueAndRound
        playingAgainst {
          name
          picture {
            full_url
          }
        }
        stadium
        status
        timing
      }
    }
  }
`;

export const GET_GAME = gql`
  query Game($gameId: Float!) {
    game(id: $gameId) {
      goalsIn
      goalsFor
      group {
        name
        id
      }
      id
      playingAgainst {
        id
        name
        picture {
          full_url
        }
      }
      leagueAndRound
      stadium
      status
      timing
    }
  }
`;

export const GET_ALL_OPPONENTS = gql`
  query Opponents($skip: Int!, $take: Int!) {
    opponents(skip: $skip, take: $take) {
      totalCount
      nodes {
        id
        name
        picture {
          full_url
        }
      }
    }
  }
`;

export const GET_OPPONENT = gql`
  query Opponent($opponentId: Int!) {
    opponent(id: $opponentId) {
      id
      name
      picture {
        full_url
      }
    }
  }
`;
