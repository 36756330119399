import React from "react";
import { useParams } from "react-router-dom";

import { Header, SideBar } from "../../components";
import UpdateRoleLayout from "../../layouts/UpdateRoleLayout";

export const UpdateRole = () => {
  const { role } = useParams();
  return (
    <>
      <Header />
      <UpdateRoleLayout role={role} />
      <SideBar active={"roles"} />
    </>
  );
};
