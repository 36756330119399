import { gql } from "@apollo/client";

export const ADD_NEW_PLAYER = gql`
  mutation CreatePlayer($createPlayerInput: CreatePlayerInput!) {
    createPlayer(createPlayerInput: $createPlayerInput) {
      id
    }
  }
`;

export const UPDATE_PLAYER = gql`
  mutation UpdatePlayer($updatePlayerInput: UpdatePlayerInput!) {
    updatePlayer(updatePlayerInput: $updatePlayerInput) {
      id
    }
  }
`;

export const DELETE_PLAYER = gql`
  mutation RemovePlayer($removePlayerId: Int!) {
    removePlayer(id: $removePlayerId)
  }
`;
