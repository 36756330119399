import { gql } from "@apollo/client";

export const CREATE_LIVE = gql`
  mutation CreateLiveNews($input: CreateLiveNewsInput!) {
    createLiveNews(input: $input) {
      id
    }
  }
`;

export const UPDATE_LIVE = gql`
  mutation UpdateLiveNews($input: UpdateLiveNewsInput!) {
    updateLiveNews(input: $input) {
      id
    }
  }
`;

export const DELETE_LIVE = gql`
  mutation DeleteLiveNews($deleteLiveNewsId: Int!) {
    deleteLiveNews(id: $deleteLiveNewsId)
  }
`;

export const CREATE_GAME = gql`
  mutation CreateLiveNews($input: CreateGameInput!) {
    createGame(input: $input) {
      id
    }
  }
`;

export const UPDATE_GAME = gql`
  mutation UpdateGame($input: UpdateGameInput!, $updateGameId: Float!) {
    updateGame(input: $input, id: $updateGameId) {
      id
    }
  }
`;

export const DELETE_GAME = gql`
  mutation RemoveGame($removeGameId: Float!) {
    removeGame(id: $removeGameId)
  }
`;

export const CREATE_OPPPONENT = gql`
  mutation CreateOpponent($createOpponentInput: CreateOpponentInput!) {
    createOpponent(createOpponentInput: $createOpponentInput) {
      id
    }
  }
`;

export const UPDATE_OPPPONENT = gql`
  mutation UpdateOpponent($updateOpponentInput: UpdateOpponentInput!) {
    updateOpponent(updateOpponentInput: $updateOpponentInput) {
      id
    }
  }
`;

export const DELETE_OPPPONENT = gql`
  mutation RemoveOpponent($removeOpponentId: Int!) {
    removeOpponent(id: $removeOpponentId)
  }
`;
