import React from "react";
//Translation
import { useTranslation } from "react-i18next";

const CardOpponent = ({ name, imgSrc }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full md:w-[165px] h-[180px] border border-[#C4C4C4] rounded-xl flex flex-col justify-between items-center  p-3">
      <div className="w-[75px] h-[75px] bg-[#F4F4F4] border-[0.5px] border-[#C4C4C4] rounded-full flex justify-center items-center">
        <div className="w-[60px] h-[60px] bg-[#D9D9D9] rounded-full overflow-hidden flex justify-between items-center ">
          <img className="w-full h-full" src={imgSrc} />
        </div>
      </div>
      <span className="text-[13px] text-center capitalize mt-2 font-bold">{name}</span>
      <div className="w-full flex flex-col justify-between items-center text-center">
        <button className="h-[25px] w-[100px] md:w-[135px] bg-mainBlue rounded-sm text-[10px] text-white mt-2">
          {t("View opponent")}
        </button>
      </div>
    </div>
  );
};

export default CardOpponent;
