import React from "react";
import { useParams } from "react-router-dom";

import { Header, SideBar } from "../../components";
import InboxSlugLayout from "../../layouts/InboxSlugLayout";

export const InboxSlug = () => {
  const { inboxId } = useParams();

  return (
    <>
      <Header />
      <SideBar active={"inbox"} />
      <InboxSlugLayout inboxId={inboxId} />
    </>
  );
};
