import React from "react";
//Translation
import { useTranslation } from "react-i18next";

export const CardSponsor = ({ companyName, imgSrc }) => {
  const {t} = useTranslation();
  return (
    <div className="w-full h-fit flex flex-col items-center justify-center mb-3 ">
      <div className="w-full h-[100px] border border-[#C4C4C4] rounded-xl overflow-hidden flex justify-center items-center">
        <img src={imgSrc} alt={imgSrc} />
      </div>
      <span className="text-[14px] mt-6 capitalize">{companyName}</span>
      <button
        className="h-[25px] w-[135px] bg-mainBlue rounded-sm text-[10px] text-white mt-5"
      >
        {t("View")}
      </button>
    </div>
  );
};
