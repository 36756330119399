import React from "react";
import { SettingsIcon } from "../../svg";
// import DeleteModel from "../DeleteGroupModale";
import { Link } from "react-router-dom";
import Model from "../Model";
import Spinner from "../Spinner";
//Translation
import { useTranslation } from "react-i18next";

const TableGroupe = ({
  i,
  setTableId,
  group,
  groupId,
  clubImg,
  playersNumber,
  trainerNumber,
  trainingTimeNumber,
  // editPlayersNumber,
  // editTrainerNumber,
  // editTrainingTimeNumber,
  editGroup,
  deleteGroup,
  loading,
  setLoading,

  deletePopupIsOpen,
  setDeletePopupIsOpen,
}) => {
  const groupTableHeadItems = [
    "Group",
    "Image",
    "Players",
    "Trainers",
    "Training time",
    "Edit Group",
  ];
  const {t} = useTranslation()
  return (
    <>
      <Model
        isOpen={deletePopupIsOpen}
        title={"Are you sure you want to delete this group ?"}
        width={600}
      >
        <div className="w-full flex items-center gap-[1rem]">
          <button
            className="h-[40px] w-[180px] bg-[#C04337] rounded text-white text-[12px]"
            onClick={() => deleteGroup(groupId)}
          >
            {loading ? <Spinner c={"text-white"} /> : t("Delete")}
          </button>
          <button
            className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
            onClick={() => setDeletePopupIsOpen(false)}
          >
            {t("Discard")}
          </button>
        </div>
      </Model>
      <table className="flex flex-col" key={i}>
        <tr className="flex">
          {groupTableHeadItems.map((item) => (
            <th>{`${t(item)}`}</th>
          ))}
        </tr>
        <tr>
          <td>{group}</td>
          <td>
            <div className="flex justify-center object-fill py-3 px-2 ">
              {clubImg ? (
                <img className="h-[72px] w-[150px]" src={`${clubImg?.full_url}`} />
              ) : (
                <p>no picture</p>
              )}
            </div>
          </td>
          <td>
            <Link to={`/team/players/${groupId}`}>
              <div className="flex justify-center items-center text-mainBlue">
                  <span className="text-[15px] underline font-bold mr-2">
                    {playersNumber}
                  </span>
                    <img src="/svg/editIcon.svg" />
              </div>
            </Link>
          </td>
          <td>
            <Link to={`/team/trainer/${groupId}`}>
              <div className="flex justify-center items-center text-mainBlue">
                <span className="text-[15px] underline font-bold mr-2">
                  {trainerNumber}
                </span>
                <img src="/svg/editIcon.svg" />
              </div>
            </Link>
          </td>
          <td>
            <Link to={`/team/trainingTime/${groupId}`}>
              <div className="flex justify-center items-center text-mainBlue">
                <span className="text-[15px] underline font-bold mr-2">
                {trainingTimeNumber}
                </span>
                  <img src="/svg/editIcon.svg" />
              </div>
            </Link>
          </td>
          <td>
            <Link to={`/team/groups/profile/${groupId}`}>
              <div className="flex justify-center items-center">
                  <button className="mr-3">
                    <SettingsIcon fill={"#133D6E"} width={24} height={24} />
                  </button>
              </div>
            </Link>
          </td>
        </tr>
      </table>
    </>
  );
};

export default TableGroupe;
