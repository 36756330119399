
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import Spinner from "../../components/Spinner";
import CarouselSkeleton from "../../components/Skeleton/CarouselSkeleton";
import Editor from "../../components/Editor";
import Model from "../../components/Model";
import CardCarousel from "../../components/CardCarousel";
import Toast from "../../components/Toast";
//hooks
import { useAuth } from "../../hooks";
//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";
//Translation
import { useTranslation } from "react-i18next";
// graphql
import {
  getKantine,
  updateKantine,
  uploadImage,
} from "../../graphql/services";
import DoneAllIcon from '@mui/icons-material/DoneAll';

const KantineLayout = () => {
  // start refresh Tokens
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);
  // END refresh Tokens

  // start Toast
  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };
  // End Toast

  const inputRef = useRef();
  const [file, setfile] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingRfrsh, setLoadingRfrsh] = useState(false);
  const [kantine,setKantine] = useState({title:"", apiImgs:""})
  const [textEditorvalue, setTextEditorvalue] = useState("");
  const [allImagesIds,setAllimagesIds] = useState([])
  const [isDelete,setIsDelete] = useState(false)
  const [selected,setSelected] = useState(null)

  

  const handleChange = (e) => setKantine({ ...kantine, title: e.target.value});
  
  //fetch kantine data
  const fetchKantine = async () => {
    setLoadingRfrsh(true);
    const { data } = await getKantine();
    setKantine({...kantine, 
        title: data?.kantine?.title, 
        apiImgs: data?.kantine?.pictures
    });
    setTextEditorvalue(data?.kantine?.description)
    setLoadingRfrsh(false);
  };

  useEffect(()=> {
    fetchKantine()
  },[])

  useEffect(()=> {
    fetchKantine()
  },[loading])

  useEffect (()=>{
    if (kantine.apiImgs) {
        setAllimagesIds(()=> kantine.apiImgs.map((item)=> parseInt(item.id))) 
    }
  },[kantine])

  const updateKantineAction = async () => {
    setloading(true)
    if (file) {
        const resUpload = await uploadImage(file[0])
        allImagesIds.push(resUpload.id);
        const {error} = await updateKantine({
            updateKantineInput: {
              title: kantine.title,
              description: textEditorvalue,
              pictures: allImagesIds,
            }
          })
          if (error) {
            setErrorMessage(error);
            setToastERROR(true);
            setToastType("error");
            handleClick();
            setloading(false);
            setfile(null)
          } else {
            setToastSuccess(t("Kantine saved successfully"));
            setToastERROR(false);
            setToastType("success");
            handleClick();
            setloading(false);
            setfile(null)
          }
    } else {
        const {error} = await updateKantine({
            updateKantineInput: {
              title: kantine.title,
              description: textEditorvalue,
              pictures: allImagesIds
            }
          })
          if (error) {
            setErrorMessage(error);
            setToastERROR(true);
            setToastType("error");
            handleClick();
            setloading(false);
          } else {
            setToastSuccess(t("Kantine saved successfully"));
            setToastERROR(false);
            setToastType("success");
            handleClick();
            setloading(false);
          }
    }
  }

  // to delete img from carousel
  const openDeletPopup = (selected) => {
    if (kantine?.apiImgs?.length <= 1) {
        setErrorMessage(t("you can not delete image; the kantine must has at least 1 picture"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
        setfile(null)
      return;
    } else {
        setIsDelete(true)
        setSelected(selected)
    }
  }

  const handleDeleteCarousel = async (selectedImg) => {
      const filteredImages = kantine.apiImgs.filter((item)=> item.id !== selectedImg?.id)
      setKantine({...kantine,apiImgs:filteredImages})
      setIsDelete(false)
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex flex-wrap justify-between gap-10">
          <div className="text-[20px]">{t("Kantine")}</div>
          <div className="text-[12px]  flex items-center gap-[.5rem]">
            <button
              onClick={() => navigate("/dashboard")}
              className="h-[40px] w-[100px] sm:w-[180px] border border-black rounded"
            >
              {t("Cancel")}
            </button>
            <button
              onClick={updateKantineAction}
              className="h-[40px] w-[100px] ml-3 lg:m-0 sm:w-[180px] bg-black rounded text-white"
            >
              {loading ? <Spinner c={"text-white"} /> : t("Save")}
            </button>
          </div>
        </div>
        <div className="bg-white mt-10 flex flex-col gap-5 px-[24px] py-[32px]">
        <div className="min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-center justify-center lg:justify-start">
          <div className=" relative flex justify-center items-center w-full md:w-fit ">
            <button
              onClick={() => inputRef.current.click()}
              className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-[5]"
            >
              <img src="/svg/camera.svg" />
            </button>
            <div className=" relative h-[137px] w-[206px] bg-[#ddd] ">
              {file ? (
                <img
                  src={
                    typeof file === "object"
                      ? URL.createObjectURL(file[0])
                      : file[0]
                  }
                  alt="profile pic"
                  className="object-contain h-full w-full relative z-[0]"
                />
              ) : Image.picture ? (
                <img
                  src={Image.picture}
                  alt="profile pic"
                  className="object-contain w-full h-full relative z-[0]"
                />
              ) : (
                ""
              )}
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
              <div className="hidden lg:block absolute h-[240px] w-[1px] right-[-30px] top-[-50px] bg-[#D8D8D8]">
                {/* this div is for the vertical line */}
              </div>
            </div>
          </div>
          <div className="flex gap-5 flex-1 pl-3 overflow-scroll">
            {loadingRfrsh ? (
              <CarouselSkeleton />
            ) : (
              kantine && kantine.apiImgs &&
              kantine.apiImgs.map((item, i) => (
                <CardCarousel
                  handleDeleteCarousel={()=> openDeletPopup(item)}
                  companyName={`# ${++i}`}
                  imgSrc={item?.full_url}
                  item={item}
                />
              ))
            )}
          </div>
        </div>
        <hr className="bg-[#aaa] h-[3px]"/>
        <form className="flex-1 flex flex-col text-[12px] mt-5">
              <span className="font-bold mb-2">{t("Kantine title")}</span>
              <input
                type="text"
                name="title"
                value={kantine.title || ""}
                onChange={handleChange}
                placeholder="Title"
                className="w-full sm:w-[300px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                required
              />
              <span className="font-bold mb-2">{t("Content")}</span>
              <Editor
                value={textEditorvalue} // Pass value
                onChange={setTextEditorvalue} // Pass onChange function
                placeholder={t("Start typing...")}     
                heightEditor={"410px"}
              />
        </form>
        </div>
      </div>
      <Model title={t("Are you sure to delete this picture ?")} isOpen={isDelete} width={"700px"}>
        <div className="flex gap-3">
          <DoneAllIcon/>
          <h1 className="text-mainBlue">{t("Make sure to save after confirm")}</h1>
        </div>
        <div className="w-full p-[1.5rem] flex items-center justify-end gap-[1rem]">
          <button
            type="button"
            onClick={() => setIsDelete(false)}
            className="w-[7rem] border border-[#E1E1E1] py-[.6rem] rounded-[4px]"
          >
            {t("Cancel")}
          </button>

          <button
            disabled={loading}
            onClick={() => handleDeleteCarousel(selected)}
            className="w-[7rem] border py-[.6rem] text-white bg-mainBlue  rounded-[4px]"
          >
            {t("confirm")}
          </button>
        </div>
      </Model>
    </>
  );
};

export default KantineLayout;
