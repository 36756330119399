import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import CardFeed from "../../components/CardFeed";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// graphql
import { getAllFeeds } from "../../graphql/services";

// components
import Toast from "../../components/Toast";

//Translation
import { useTranslation } from "react-i18next";
import FeedsSkeleton from "../../components/Skeleton/FeedsSkeleton";
import Pagination from "../../components/Pagination";

const FeedsListLayout = () => {
  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  // GET feeds
  const [feeds, setfeeds] = useState(null);
  const [order, setOrder] = useState("DESC");
  const [searchedFeed, setSearchedFeed] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [feedsTotCount, setfeedsTotCount] = useState(null);

  const fetchFeeds = async (skip) => {
    setLoading(true);
    const { data, error, message } = await getAllFeeds(
      {
        take: pageSize,
        skip: Number(skip) ? Number(skip) * pageSize : 0,
        order: {
          updatedAt: order
        },
        where: {
          search: searchedFeed,
          isFeatured: null,
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch feeds failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setfeeds(data?.news?.nodes);
      setfeedsTotCount(data?.news?.totalCount);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchFeeds(currentPage);
  }, [order, searchedFeed, currentPage]);

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center w-[170px]">
            {t("Feeds List")}
          </div>
          <div className="text-[12px]">
            <Link to={"/feeds/addNewFeed"}>
              <button className="h-[40px] w-[120px] sm:w-[220px] bg-[#2B9D96] rounded text-white">
                {t("Add new feed")}
              </button>
            </Link>
          </div>
        </div>
        <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
          <form className="flex justify-between flex-wrap gap-5 text-[12px]">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold">{t("Search")}</span>
              <input
                type="text"
                name="Search"
                onChange={(e) => setSearchedFeed(e.target.value)}
                placeholder="Search any"
                className="w-[237px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
          </form>
          <div className="mt-5 md:mt-10 flex flex-wrap items-center gap-5">
            <span className="font-bold">{t("Sort By")}</span>
            <div className="flex flex-wrap gap-5">
              <button
                className={`${
                  order === "ASC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("DESC")}
              >
                {t("Added date (newest)")}
              </button>
              <button
                className={`${
                  order === "DESC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("ASC")}
              >
                {t("Added date (oldest)")}
              </button>
            </div>
          </div>
          <div className="mt-10 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 md:gap-3 lg:gap-4">
            {loading ? (
              <FeedsSkeleton />
            ) : feeds && feeds.length !== 0 ? (
              feeds.map((feed, i) => (
                <Link to={`/feeds/feedsList/profile/${feed.id}`} key={i}>
                  <CardFeed
                    i={i}
                    isFeatured={feed?.isFeatured}
                    imgSrc={feed?.picture?.full_url}
                    title={feed.title}
                    description={feed.content}
                  />
                </Link>
              ))
            ) : (
              t("No feeds matched")
            )}
          </div>
          {/* pagination */}
            {!searchedFeed && feeds && feedsTotCount > 5 ? (
              <div className="w-full flex justify-center mt-10">
                <Pagination
                  count={feedsTotCount}
                  pagesize={pageSize}
                  setPage={(e) => {
                    setCurrentPage(e);
                  }}
                  page={Number(currentPage) ? Number(currentPage) : 0}
                  showPages={3}
                />
              </div>
            ) : null}
        </div>
      </div>
    </>
  );
};

export default FeedsListLayout;
