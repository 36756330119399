import { gql } from "@apollo/client";

export const GET_ALL_PLAYERS = gql`
  query AllPlayers($where: FilterPlayerInput, $skip: Int!, $take: Int!, $order: SortByFilterArgs) {
    allPlayers(where: $where, skip: $skip, take: $take, order: $order) {
      totalCount
      nodes {
        id
        firstName
        picture {
          full_url
        }
        lastName
        number
        position
        group {
          id
          name
        }
      }
    }
  }
`;

export const GET__PLAYERS_BY_GR = gql`
  query AllPlayers($where: FilterPlayerInput, $take: Int!) {
    allPlayers(where: $where, take: $take) {
      id
      group {
        name
        id
      }
    }
  }
`;


export const GET_PLAYER = gql`
  query Player($playerId: Int!) {
    player(id: $playerId) {
      firstName
      lastName
      number
      picture {
        full_url
      }
      position
      dateOfBirth
      group {
        name
        id
      }
    }
  }
`;
