import React from 'react'
import { Header, SideBar } from '../../components'
import LiveLayout from '../../layouts/LiveLayout'

export const Live = () => {
  return (
    <>
      <Header/>
      <LiveLayout/>
      <SideBar active={'live'}/>  
    </>
  )
}
