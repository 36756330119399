import { Skeleton } from '@mui/material'
import React from 'react'

const SponsorsSkeleton = () => {
  return (
    <> 
      {[...Array(4)].map(()=> (
      <div className="w-[200px] h-fit flex flex-col items-center justify-center mb-10 ">
        <Skeleton sx={{width:"100%" , height:"150px" , borderRadius:"20px"}}/>
        <Skeleton sx={{width:"90px",marginTop:"10px"}}/>
        <Skeleton sx={{width:"150px" , height:"45px" , marginTop:"5px"}}/>
      </div>))}
    </>
  )
}

export default SponsorsSkeleton