import React from "react";
import { useNavigate } from "react-router-dom";

//store
import { useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

//Translation
import { useTranslation } from "react-i18next";

const SettingMenuTablett = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className="">
        <button
          onClick={handleLogout}
          className="h-[35px] w-[100px] text-white text-[12px] bg-[#C04337] rounded my-10"
        >
          {t("SIGN OUT")}
        </button>
    </div>
  );
};

export default SettingMenuTablett;