import { createSlice } from "@reduxjs/toolkit";

const settingsMenuSlice = createSlice({
  name: "settingsMenu",
  initialState: {
    settingsMenuIsOpened: false,
  },
  reducers: {
    setSettingsMenuIsOpened: (state) => {
      state.settingsMenuIsOpened = !state.settingsMenuIsOpened;
    },
  },
});

export const { setSettingsMenuIsOpened } = settingsMenuSlice.actions;

export default settingsMenuSlice.reducer;
