import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { addNewProduct, deleteProduct, getAllProducts, updateProduct } from '../../graphql/services/shop.service';
import { uploadImage } from '../../graphql/services';

// components
import Model from '../../components/Model';
import Toast from '../../components/Toast';
import Spinner from "../../components/Spinner";


const  ShopLayout = () => {
    //const TOKEN = useSelector((state) => state.auth.access_token);
    const { t } = useTranslation();
    //toast
    const [errorMessage, setErrorMessage] = useState(null);
    const [toastType, setToastType] = useState(null);
    const [toastERROR, setToastERROR] = useState(null);
    const [toastSuccess, setToastSuccess] = useState(null);
    const [openToast, setOpenToast] = useState(false);
    const handleClick = () => {
      setOpenToast(true);
    };
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpenToast(false);
      setErrorMessage(null);
    };


    const [open, setOpen] = useState([{action:"", value:false}])//for Model [create - update]
    const inputRef = useRef();
    const [file, setfile] = useState(null);
    const [loading, setloading] = useState(false);
    const [loadingD, setloadingD] = useState(false);//loading when delete
    const [form, setform] = useState({
        name: null,
        description:null,
        price:null,
        pictures: null,
      });
      const [products, setProducts] = useState(null)
      const handleChange = (e) => setform({ ...form, [e.target.name]: e.target.value });
      const createProduct = async () => {
        if (!form.name || !form.description ||!form.price || !file) {
            setErrorMessage(t("Please fill up all inputs with image"));
            setToastERROR(true);
            setToastType("error");
            handleClick();
            setloading(false);
            return;
        }
        setloading(true)
        const resUpload = await uploadImage(file[0])
          const {error} = await addNewProduct(
            {
              createProductDto: {
                name: form.name,
                description: form.description,
                price: parseInt(form.price),
                pictures: resUpload?.id,
                stock: 1,
                status: "PUBLISHED"
              }
            },{}
          )
          if (error) {
            setErrorMessage(error);
            setToastERROR(true);
            setToastType("error");
            handleClick();
            setloading(false);
          } else {
            setToastSuccess(t("Product created successfully"));
            setToastERROR(false);
            setToastType("success");
            handleClick();
            setloading(false);
            setOpen(false);
            setform({...form,name:null, description:null, price:null, pictures:null});
            setfile(null)
        }
      }

      const fetchProduct = async ()=> {
        const { data,error } = await getAllProducts()
        if (error) {
          console.log(error);
        } else { 
          setProducts(data?.products)
        }
      }
      useEffect(()=> {
        fetchProduct()
      },[loading,loadingD])
      const removeProduct = async (id) => {
        setloadingD(true)
        const { error } = await deleteProduct({productId:parseInt(id)})
        if (error) {
          setErrorMessage(error);
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloadingD(false);
        } else {
          setToastSuccess(t("Product deleted successfully !"));
          setToastERROR(false);
          setToastType("success");
          handleClick();
          setloadingD(false);
          setOpen(false);
          setform({...form,name:null, description:null, price:null, pictures:null});
          setfile(null)
        }
      }
      const handelUpdateProduct = async () => {
        setloading(true);
        if (file) {
          const resUpload = await uploadImage(file[0])
          const { error } = await updateProduct({
            productId: parseInt(form.id),
            updateProductInput: {
              name: form.name,
              price: parseInt(form.price),
              description: form.description,
              pictures: parseInt (resUpload.id)
            }
          })
          if (error) {
              setErrorMessage(error);
              setToastERROR(true);
              setToastType("error");
              handleClick();
              setloading(false);
          } else {
              setToastSuccess(t("Product updated successfully !"));
              setToastERROR(false);
              setToastType("success");
              handleClick();
              setloading(false);
              setOpen(false);
              setform({...form,name:null, description:null, price:null, pictures:null});
              setfile(null) 
          }
        } else {
          const { error } = await updateProduct({
            productId: parseInt(form.id),
            updateProductInput: {
              name: form.name,
              price: parseInt(form.price),
              description: form.description,
              pictures:parseInt(form.pictures[0].id)
            }
          })
          if (error) {
              setErrorMessage(error);
              setToastERROR(true);
              setToastType("error");
              handleClick();
              setloading(false);
          } else {
              setToastSuccess(t("Product updated successfully !"));
              setToastERROR(false);
              setToastType("success");
              handleClick();
              setloading(false);
              setOpen(false);
              setform({...form,name:null, description:null, price:null, pictures:null});
              setfile(null) 
          } 
        }
      }

console.log("file",file);
  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />

    <div className="layoutContainer">
            <div className="flex justify-between flex-wrap gap-10">
               <h1 className="text-[20px] flex justify-between items-center w-[170px]">
                 {t("Products List")}
               </h1>
               <button 
                onClick={()=> {
                  setOpen({...open, action:"create",value:true});
                  setform({...form,id:null,name:null, description:null, price:null, pictures:null})
                }} 
                className="text-[12px] h-[40px] w-[120px] sm:w-[220px] bg-[#2B9D96] rounded text-white"
                >
                   {t("Add new product")}
               </button>
            </div>
            <div className=" mt-10 w-full grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {products && products?.nodes?.length > 0 
                  ? products?.nodes.map((item,index)=> 
               <div
                 key={index}
                 className="border border-[#DDD] cursor-pointer transition duration-100 hover:bg-[#aaa] w-full h-[200px] md:h-[250px]  rounded-2xl shadow-lg p-4 flex justify-center items-center gap-4"
                 onClick={()=>{setOpen({...open,action:"update",value:true});setform(item)}}
               >
                    <div className="h-full flex items-center ">
                      <img
                        className=" h-[130px] lg:h-[100px]  flex-1 relative"
                        src={item.pictures[0].full_url}
                        alt='product img'
                      />
                    </div>
                    <div className=" w-3/5  lg:h-[240px] flex flex-col justify-between py-4  md:px-2 gap-4 ">
                      <p className=" font-OpenSans text-primary font-bold  text-2xl  ">
                        {item?.name}
                      </p>
                      <p className=" line-clamp-2 font-OpenSans   text-base    leading-normal   text-[#000]/60">
                        {item?.description
                          ? item?.description
                          : "Ut aliquet imperdiet leo eu neque erat adipiscing ac lacus."}
                      </p>
                      <p className=" font-OpenSans text-secondary  font-semibold  text-[40px] line-clamp-1 w-[100px]">
                        {item?.price}
                        <span className="text-2xl font-light">€</span>
                      </p>
                    </div>
               </div>)
                  : <h1 className="font-bold">{t("no products found")}</h1>
              }
            </div>
    <Model title={open.action==="create" ? t("Add new Product") : t("Update Product")} isOpen={open.value}>
      <div className=" min-h-[280px] flex flex-wrap  gap-[50px] lg:gap-10 items-center justify-center xl:justify-start   px-[24px] ">
        <div className="relative text-center ">
            <button
              onClick={() => inputRef.current.click()}
              className=" absolute left-[50%] -translate-x-[50%] top-[-1rem] z-[1]"
            >
              <img src="/svg/camera.svg" alt='cameraIcon' />
            </button>
            <div className="h-[137px] w-[206px] bg-[#D9D9D9] overflow-hidden flex justify-center items-center">
              <input
                ref={inputRef}
                type="file"
                onChange={(e) => setfile(e.target.files)}
                className="hidden"
              />
              {file ? (
                <img
                  src={
                    typeof file === "object"
                      ? URL.createObjectURL(file[0])
                      : file[0]
                  }
                  alt="profile pic"
                  className="object-contain w-full h-full relative z-[0]"
                />
              ) : form.pictures ? (
                <img
                  src={form.pictures[0].full_url}
                  alt="profile pic"
                  className="object-contain w-full h-full  z-[0]"
                />
              ) : (
                ""
              )}
            </div>
            <div className="hidden xl:block absolute h-[240px] w-[1px] left-[250px]  top-[-50px] bg-[#D8D8D8]">
            {/* this div for the vertical line */}
          </div>
          </div>
          <form className="flex-1 flex flex-col ml-0 xl:ml-10 text-[12px]">
            <span className="mb-1">{t("Product Name")}</span>
            <input
              type="text"
              required
              value={form.name || ""}
              onChange={handleChange}
              name="name"
              placeholder={t("Enter Name")}
              className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
            />
            <span className="mb-1">{t("Product Price in €")}</span>
              <input
                type="number"
                required
                value={form.price || ""}
                onChange={handleChange}
                name="price"
                placeholder={t("Price in number")}
                className="w-[230px] h-[40px] text-[black] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
              />
            <span className="mb-1">{t("Description")}</span>
            <textarea
              required
              value={form.description || ""}
              onChange={handleChange}
              name="description"
              placeholder={t("Product description")}
              className="w-full h-[150px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
            />
          </form>
      </div>
                <div className="w-full md:text-sm text-xs p-[1.5rem] flex items-center justify-end gap-[1rem]">
                  <button
                    type="button"
                    onClick={() => {
                      setOpen(false);
                      setform({...form,id:null, name:null, description:null, price:null, pictures:null})
                      setfile(null)}}
                    className="w-[7rem] border border-[#E1E1E1] py-[.6rem] rounded-[4px]"
                  >
                    {t("Cancel")}
                  </button>
                  {open.action !== "create" &&
                    <button
                      disabled={loading}
                      onClick={()=> removeProduct(form.id)}
                      className="w-[7rem] border py-[.6rem] text-white bg-[#C04337]  rounded-[4px]"
                    >
                      {loadingD ? <Spinner c={"text-white"} /> : t("Delete")}
                    </button>
                  }
                  <button
                    disabled={loading}
                    onClick={open.action === "create" ? () => createProduct() : ()=> handelUpdateProduct()}
                    className="w-[7rem] border py-[.6rem] text-white bg-mainBlue  rounded-[4px]"
                  >
                    {loading ? <Spinner  c={"text-white"} /> : t("Save")}
                  </button>
          </div>
    </Model>
  </div>
  </>
  )
}

export default  ShopLayout