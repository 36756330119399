import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { InboxSkeleton, TableInbox } from "../../components";
import Pagination from "../../components/Pagination";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";
import { setTotUnreadMsg } from "../../store/slices/inboxSlice";

// graphql
import { changeMsgStatus, getAllMessages } from "../../graphql/services";

// components
import Toast from "../../components/Toast";

//Translation
import { useTranslation } from "react-i18next";
import MarkEmailUnreadRoundedIcon from "@mui/icons-material/MarkEmailUnreadRounded";
import QuickreplyRoundedIcon from "@mui/icons-material/QuickreplyRounded";

const InboxLayout = () => {
  const InboxTableHeadItems = [
    "Full name",
    "Message",
    "Phone",
    "Email",
    "Time / Date",
    "View",
  ];

  const { t } = useTranslation();
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const totUnreadMsg = useSelector((state) => state.inbox.totUnreadMsg);
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [messages, setmessages] = useState(null);
  const [order, setOrder] = useState("DESC");
  const [searchedMsg, setSearchedMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [messagesTotCount, setMessagesTotCount] = useState(null);

  const fetchMessages = async (skip) => {
    setLoading(true);
    const { data, error, message } = await getAllMessages(
      {
        take: pageSize,
        skip: Number(skip) ? Number(skip) * pageSize : 0,
        order: {
          createdAt: order,
        },
        where: {
          search: searchedMsg,
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch messages failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setmessages(data?.contacts?.nodes);
      setMessagesTotCount(data?.contacts?.totalCount);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages(currentPage);
  }, [order, searchedMsg, currentPage]);

  const changeMsgStat = async (msgId) => {
    const { data, error, message } = await changeMsgStatus(
      {
        updateContactStatusId: parseInt(msgId),
        updateContactEntryInput: {
          seen: true,
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Update message status failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setErrorMessage("Update message status successfully");
      setToastERROR(false);
      setToastType("error");
      handleClick();
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <div className="layoutContainer">
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center w-[155px]">
            {t("Inbox")}
          </div>
          <div className="text-[12px]"></div>
        </div>
        <div className="bg-white h-[95vh] mt-10 px-[24px] py-[32px]">
          <form className="flex justify-between flex-wrap gap-5 text-[12px]">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold mb-2">{t("Search")}</span>
              <input
                type="text"
                name="Search"
                placeholder={t("Search any")}
                onChange={(e) => setSearchedMsg(e.target.value)}
                className="w-[237px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
          </form>
          <div className="mt-10 flex flex-wrap items-center gap-5">
            <span className="font-bold">{t("Sort By")}</span>
            <div className="flex items-center flex-wrap gap-5">
              <button
                className={`${
                  order === "ASC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("DESC")}
              >
                {t("Added date (newest)")}
              </button>
              <button
                className={`${
                  order === "DESC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("ASC")}
              >
                {t("Added date (oldest)")}
              </button>
              <div className="flex items-center gap-4">
                <div className="font-bold flex justify-center flex-wrap items-center gap-2">
                  <span>{t("Messages")}</span>
                  <span className=" border border-1 border-[black] rounded-[999px] w-[80px] flex justify-between py-1 px-3 flex gap-1">
                    {messagesTotCount}
                    <QuickreplyRoundedIcon style={{ color: "#4169E1" }} />
                  </span>
                </div>
                <div className="font-bold flex justify-center flex-wrap items-center gap-2">
                  <span>{t("Unread")}</span>
                  <span className=" border border-1 border-[black] rounded-[999px] w-[80px] flex justify-between py-1 px-3 flex gap-1">
                    {totUnreadMsg}
                    <MarkEmailUnreadRoundedIcon style={{ color: "#FF8A8A" }} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex flex-row flex-wrap overflow-scroll">
            {loading ? (
              <InboxSkeleton />
            ) : (
              <table className="flex flex-col text-[12px]">
                <tr className="flex">
                  {InboxTableHeadItems.map((item) => (
                    <th>{t(item)}</th>
                  ))}
                </tr>
                {messages && messages.length !== 0
                  ? messages.map((message, i) => (
                      <TableInbox
                        i={i}
                        message={message}
                        changeMsgStat={changeMsgStat}
                      />
                    ))
                  : t("there is no messages matched at the moment")}
              </table>
            )}
          </div>
          {/* pagination */}
          {!searchedMsg && messages && messagesTotCount > 3 ? (
            <div className="w-full flex justify-center mt-10">
                <Pagination
                  count={messagesTotCount}
                  pagesize={pageSize}
                  setPage={(e) => {
                    setCurrentPage(e);
                  }}
                  page={Number(currentPage) ? Number(currentPage) : 0}
                  showPages={3}
                />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default InboxLayout;
