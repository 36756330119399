import { gql } from "@apollo/client";

export const GET_SETTINGS = gql`
  query ClubSettings {
    clubSettings {
      calendarLink
      clubName
      picture {
        full_url
      }
      tagline
      teamRank
      iban
      facebookLink
      twitterLink
      instagramLink
      linkedinLink
    }
  }
`;
