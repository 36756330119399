import { gql } from "@apollo/client";

export const ADD_NEW_SPONSOR = gql`
  mutation CreatePartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      id
    }
  }
`;

export const UPDATE_SPONSOR = gql`
  mutation UpdatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      id
    }
  }
`;

export const DELETE_SPONSOR = gql`
  mutation DeletePartner($deletePartnerId: Int!) {
    deletePartner(id: $deletePartnerId)
  }
`;
