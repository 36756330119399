import React from "react";
import { useParams } from "react-router-dom";

import { Header, SideBar } from "../../components";
import SponsorSlugLayout from "../../layouts/SponsorSlugLayout";

export const SponsorSlug = () => {
  const { sponsorId } = useParams();

  return (
    <>
      <Header />
      <SponsorSlugLayout sponsorId={sponsorId} />
      <SideBar active={"sponsorsMembers"} />
    </>
  );
};
