import React from "react";
import { Header, SideBar } from "../../components";
import RolesLayout from "../../layouts/RolesLayout";

export const Roles = () => {
  return (
    <>
      <Header />
      <RolesLayout />
      <SideBar active={"roles"} />
    </>
  );
};
