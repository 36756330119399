import React from 'react'
import { Header, SideBar } from '../../components'
import AddNewTrainingTimeLayout from '../../layouts/AddNewTrainingTimeLayout'

export const AddNewTrainingTime = () => {
  return (
    <>
        <Header/>
        <AddNewTrainingTimeLayout/>
        <SideBar active={'addNewTrainingTime'}/>
    </>
  )
}