import { createSlice } from "@reduxjs/toolkit";

const inboxSlice = createSlice({
  name: "inbox",
  initialState: {
    totUnreadMsg: null
  },
  reducers: {
    setTotUnreadMsg: (state,action) => {
      state.totUnreadMsg = action.payload;
    },
  },
});

export const { setTotUnreadMsg } = inboxSlice.actions;

export default inboxSlice.reducer;
