import React, { useState, useEffect, useRef } from "react";
import { LiveSlugSkl, Model } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import DropDownIcon from "../../svg/DropDownIcon";

//hooks
import { useAuth, useOnClickOutside, reorderGroupList } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import {
  getGame,
  updateGame,
  deleteGame,
  getGroups,
  getAllOpponents,
  addNewOpponent,
  uploadImage,
} from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const LiveSlugLayout = ({ liveId }) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const adminGroup = JSON.parse(localStorage.getItem("adminGroup"));
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const [groups, setgroups] = useState(null);
  const fetchGroups = async () => {
    const { data, error, message } = await getGroups(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch groups failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setgroups(reorderGroupList(data?.groups?.nodes));
    }
  };

  const [opponents, setopponents] = useState(null);
  const fetchOpponents = async () => {
    const { data, error, message } = await getAllOpponents(
      {
        skip: 0,
        take: 100,
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch opponents failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setopponents(data?.opponents?.nodes);
    }
  };

  useEffect(() => {
    fetchGroups();
    fetchOpponents();
  }, []);

  const [form, setform] = useState({
    group: "",
    leagueAndRound: "",
    playingAgainst: "",
    stadium: "",
    timing: "",
  });

  const inputRef = useRef();
  const [file, setfile] = useState(null);
  const [opponent, setopponent] = useState(null);
  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const fetchGame = async (id) => {
    setloading(true);
    const { data, error, message } = await getGame(
      {
        gameId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch game failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      }
    } else {
      setform({
        group: data.game.group.id,
        leagueAndRound: data.game.leagueAndRound,
        playingAgainst: data.game.playingAgainst,
        stadium: data.game.stadium,
        timing: data.game.timing,
      });
      setopponent(data.game.playingAgainst.name);
      setfile(data.game.playingAgainst.picture.full_url);
      setloading(false);
    }
  };

  useEffect(() => {
    if (liveId) {
      fetchGame(liveId);
    }
  }, [liveId]);

  const checkfield = (form) => {
    let checked = false;
    if (form.group === "") {
      setErrorMessage(t("Select group"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (!opponent && form.playingAgainst === "") {
      setErrorMessage(t("Enter playing Against"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (form.leagueAndRound === "") {
      setErrorMessage(t("Enter league and round"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else if (form.stadium === "") {
      setErrorMessage(t("Enter stadium"));
      setToastERROR(true);
      setToastType("error");
      handleClick();
    } else {
      checked = true;
    }
    return checked;
  };

  const [loading, setloading] = useState(false);
  const handleUpdateGame = async (id) => {
    if (checkfield(form)) {
      if (form.playingAgainst === "") {
        if (!file) {
          setErrorMessage(t("Please add opponent logo"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
        } else {
          setloading(true);
          const resUpload = await uploadImage(file[0]);
          const {
            data: opponentData,
            error,
            message,
          } = await addNewOpponent(
            {
              createOpponentInput: {
                name: opponent,
                picture: parseInt(resUpload.id),
              },
            },
            TOKEN
          );
          if (error) {
            if (message === "Unauthorized") {
              dispatch(logout());
              navigate("/login");
            } else {
              setErrorMessage(t("Add opponent Failed"));
              setToastERROR(true);
              setToastType("error");
              handleClick();
              setloading(false);
            }
          } else {
            const { data, error, message } = await updateGame(
              {
                input: {
                  group: parseInt(form.group),
                  leagueAndRound: form.leagueAndRound,
                  playingAgainst: parseInt(opponentData.id),
                  stadium: form.stadium,
                  timing: form.timing,
                },
              },
              TOKEN
            );
            if (error) {
              if (message === "Unauthorized") {
                dispatch(logout());
                navigate("/login");
              } else {
                setErrorMessage(t("Update game Failed"));
                setToastERROR(true);
                setToastType("error");
                handleClick();
                setloading(false);
              }
            } else {
              setToastSuccess(t("Update game successfully"));
              setToastERROR(false);
              setToastType("success");
              handleClick();
              setloading(false);
              setform({
                content: "",
              });
            }
          }
        }
      } else {
        setloading(true);
        const { data, error, message } = await updateGame(
          {
            updateGameId: parseInt(id),
            input: {
              group: parseInt(form.group),
              leagueAndRound: form.leagueAndRound,
              playingAgainst: parseInt(form.playingAgainst.id),
              stadium: form.stadium,
              timing: form.timing,
            },
          },
          TOKEN
        );
        if (error) {
          if (message === "Unauthorized") {
            dispatch(logout());
            navigate("/login");
          } else {
            setErrorMessage(t("Update game Failed"));
            setToastERROR(true);
            setToastType("error");
            handleClick();
            setloading(false);
          }
        } else {
          setToastSuccess(t("Update game successfully"));
          setToastERROR(false);
          setToastType("success");
          handleClick();
          setloading(false);
        }
      }
    }
  };

  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const handleDeleteGame = async () => {
    setdeleteLoading(true);
    const { data, error, message } = await deleteGame(
      {
        removeGameId: parseInt(liveId),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Delete game Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setdeleteLoading(false);
      }
    } else {
      setToastSuccess(t("Delete game successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setdeleteLoading(false);
      Navigate(-1);
    }
  };

  const [isOpponent, setisOpponent] = useState(false);
  const handleOpponent = (opponent) => {
    setform({ ...form, playingAgainst: opponent });
    setopponent(opponent.name);
    setfile(opponent.picture.full_url);
    setTimeout(() => {
      setisOpponent(false);
    }, 50);
  };

  const handlechangeOpponent = (value) => {
    let isOpponent = false;
    const searchText = value?.toLowerCase();
    if (searchText) {
      for (const opponent of opponents) {
        const text = opponent.name.toLowerCase();
        if (text.includes(searchText)) {
          isOpponent = true;
        }
        if (!opponents?.some((opp) => opp.name.toLowerCase() === searchText)) {
          setform({ ...form, playingAgainst: "" });
          setfile(null);
        }
      }
    } else {
      isOpponent = false;
    }
    setisOpponent(isOpponent);
  };

  useEffect(() => {
    if (opponents) handlechangeOpponent(opponent);
  }, [opponents, opponent]);

  const opponetList = useRef();
  useOnClickOutside(opponetList, () => {
    setisOpponent(false);
  });

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {loading ? (
        <LiveSlugSkl />
      ) : (
        <div className="layoutContainer">
          {/* trigger Model when Clicking on Delete */}
          <Model
            isOpen={deletePopupIsOpen}
            title={t("Are u sure to delete this game ?")}
            width={600}
          >
            <div className="w-full flex items-center gap-[1rem]">
              <button
                className="h-[40px] w-[180px] bg-[#C04337] rounded text-white mr-3 text-[12px]"
                onClick={handleDeleteGame}
              >
                {deleteLoading ? <Spinner c={"text-white"} /> : t("Delete")}
              </button>
              <button
                className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
                onClick={() => setDeletePopupIsOpen(false)}
              >
                {t("Discard")}
              </button>
            </div>
          </Model>
          <div className="flex flex-wrap justify-between gap-10">
            <div className="text-[16px] md:text-[20px] flex justify-between items-center ">
              <button onClick={() => Navigate(-1)}>
                <img src="/svg/backIcon.svg" />
              </button>
              <Link to={"/live"}>
                <span className="mx-2">{t("Comment")}</span>
              </Link>
              <DropDownIcon fill={"#133D6E"} />
              <span className="ml-2">#1</span>
            </div>
            <div className="text-[12px] flex items-center gap-[.5rem]">
              <button
                className="h-[40px] w-[100px] lg:w-[180px] border border-black rounded"
                onClick={() => Navigate(-1)}
              >
                {t("Cancel")}
              </button>
              <button
                className="h-[40px] w-[100px] lg:w-[180px] bg-[#C04337] rounded text-white"
                onClick={() => setDeletePopupIsOpen((prev) => !prev)}
              >
                {t("Delete")}
              </button>
              <button
                onClick={() => handleUpdateGame(liveId)}
                className="h-[40px] w-[100px] lg:w-[180px] bg-black rounded text-white"
              >
                {loading ? <Spinner c={"text-white"} /> : t("Save")}
              </button>
            </div>
          </div>
          <div className="bg-white h-fit mt-10 p-10">
            <div className="flex flex-col text-[12px]">
              <div className="flex flex-col text-[12px] mb-4">
                <div className="flex flex-wrap lg:justify-start justify-center gap-10 text-[12px] mb-10 ">
                  <div className="flex flex-col text-[12px]">
                    <span className="mb-1">{t("Group")}</span>
                    <select
                      name="group"
                      value={form.group || ""}
                      onChange={handleChange}
                      className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                    >
                      {!adminGroup && <option value={""}>Select group</option>}
                      {groups &&
                        groups?.map((group, i) =>
                          adminGroup ? (
                            adminGroup.id === group.id && (
                              <option value={group.id}>{group.name}</option>
                            )
                          ) : (
                            <option value={group.id}>{group.name}</option>
                          )
                        )}
                    </select>
                    <div className="flex flex-col">
                      <span className="mb-1">{t("Playing Against")} :</span>
                      <div className="flex gap-[.5rem]">
                        <div className="relative">
                          <input
                            type="text"
                            value={opponent || ""}
                            onChange={(e) => setopponent(e.target.value)}
                            placeholder="Playing Against"
                            className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                            required
                          />
                          <div
                            ref={opponetList}
                            className={`absolute top-[40px] left-0 w-full ${
                              !isOpponent && "hidden"
                            }`}
                          >
                            <div
                              className={`flex flex-col bg-white w-[230px] max-h-[300px] overflow-y-auto items-start border-[2px] border-[#ecedee] rounded-[0_0_5px_5px]`}
                            >
                              {opponents &&
                                opponents.map(
                                  (opp, i) =>
                                    opp.name
                                      .toLowerCase()
                                      .includes(opponent?.toLowerCase()) && (
                                      <button
                                        onClick={() => handleOpponent(opp)}
                                        className="p-[5px] flex items-center gap-[.5rem] w-full border-b border-b-[#ecedee]"
                                      >
                                        <div className="w-[25px] h-[25px] bg-[#ecedee] rounded-full overflow-hidden">
                                          <img
                                            src={opp.picture.full_url}
                                            className="object-cover"
                                            alt=""
                                          />
                                        </div>
                                        <span className="font-bold capitalize">
                                          {opp.name}
                                        </span>
                                      </button>
                                    )
                                )}
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => inputRef.current.click()}
                          className="h-[40px] w-[40px] rounded-full overflow-hidden flex justify-center items-center cursor-pointer"
                        >
                          {file ? (
                            form.playingAgainst === "" ? (
                              <img
                                src={
                                  typeof file === "object"
                                    ? URL.createObjectURL(file[0])
                                    : file[0]
                                }
                                alt="profile pic"
                                className="object-cover w-full h-full relative z-[0]"
                              />
                            ) : (
                              <img
                                src={file}
                                alt="profile pic"
                                className="object-cover w-full h-full relative z-[0]"
                              />
                            )
                          ) : (
                            <div className="flex flex-col items-center">
                              {" "}
                              <img
                                src="/svg/upload.svg"
                                alt="uplaod svg"
                                className="w-[22px] "
                              />
                              <span>Logo</span>
                            </div>
                          )}
                          <input
                            ref={inputRef}
                            type="file"
                            onChange={(e) => setfile(e.target.files)}
                            className="hidden"
                          />
                        </div>
                      </div>
                    </div>
                    <span className="mb-1">{t("League and round")} :</span>
                    <input
                      type="text"
                      name="leagueAndRound"
                      value={form.leagueAndRound || ""}
                      onChange={handleChange}
                      placeholder="League and round"
                      className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                      required
                    />
                  </div>
                  <div className="flex flex-col lg:ml-10 mr-[50px] text-[12px]">
                    <span className="mb-1">{t("Stadium")}</span>
                    <input
                      type="text"
                      name="stadium"
                      value={form.stadium || ""}
                      onChange={handleChange}
                      placeholder="Stadium"
                      className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                      required
                    />
                    <span className="mb-1">{t("Match timing")}</span>
                    <input
                      type="datetime-local"
                      name="timing"
                      value={form.timing || ""}
                      onChange={handleChange}
                      className="w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                    />
                  </div>
                </div>
                <Link to={`/live/LiveDetail/${liveId}`} className="">
                  <img src="/svg/live.svg" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LiveSlugLayout;
