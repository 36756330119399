import React from "react";
import { Header, SideBar } from "../../components";
import EventsLayout from "../../layouts/EventsLayout";

export const Events = () => {
  return (
    <div>
      <Header />
      <EventsLayout />
      <SideBar active={"events"} />
    </div>
  );
};
