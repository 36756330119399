import React from "react";
//Translation
import { useTranslation } from "react-i18next";

const TableManageFans = ({ i, fan, setTableRow }) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td className="h-[2rem]">{fan.firstName}</td>
      <td>{fan.lastName}</td>
      <td>
        <div className="flex justify-center items-center">
          <button
            className="mr-3 text-[#21861E] font-[600]"
            onClick={() => setTableRow({ id: fan.id, status: "APPROVED" })}
          >
            {t("Approve")}
          </button>
          <button
            className="text-[#C04337] font-[600]"
            onClick={() => setTableRow({ id: fan.id, status: "REFUSED" })}
          >
            {t("Refuse")}
          </button>
        </div>
      </td>
    </tr>
  );
};

export default TableManageFans;
