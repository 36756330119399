import { client } from "../config";
import {
  ADD_STAFF,
  ADD_TRAINER,
  ADD_TRAINING_TIME,
  CREATE_GROUP,
  DELETE_GROUP,
  DELETE_STAFF,
  DELETE_TRAINER,
  DELETE_TRAINING_TIME,
  UPDATE_GROUP,
  UPDATE_STAFF,
  UPDATE_TRAINER,
  UPDATE_TRAINING_TIME,
} from "../mutations";
import {
  ALL_TRAINERS,
  GET_ALL_STAFF,
  GET_ALL_TRAINERS,
  GET_GROUP,
  GET_GROUPS,
  GET_STAFF,
  GET_TRAINER,
  GET_TRAINING_TIME,
  GET_TRAINING_TIMES,
  GET_TRAINING_TIMES_BY_TEAM,
} from "../queries";

export const createGroup = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_GROUP,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getGroups = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_GROUPS,
      context: { accessToken },
      variables,
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateGroup = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_GROUP,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteGroup = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_GROUP,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getTrainersById = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_TRAINERS,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
export const getALLTrainers = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: ALL_TRAINERS,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getStaffs = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_ALL_STAFF,
      context: { accessToken },
      variables,
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
export const getTrainingTimes = async (values, accessToken) => {
  try {
    const result = await client.query({
      query: GET_TRAINING_TIMES,
      variables: values,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
export const getTrainingTimesByGrId = async (values, accessToken) => {
  try {
    const result = await client.query({
      query: GET_TRAINING_TIMES,
      variables: values,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const addTrainer = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_TRAINER,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const addStaff = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_STAFF,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const addTrainingTime = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: ADD_TRAINING_TIME,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getStaff = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_STAFF,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateStaff = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_STAFF,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteStaff = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_STAFF,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getTrainer = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_TRAINER,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateTrainer = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_TRAINER,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteTrainer = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_TRAINER,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getGroup = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_GROUP,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const updateTrainingTime = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_TRAINING_TIME,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const deleteTrainingTime = async (variables, accessToken) => {
  try {
    const { data } = await client.mutate({
      mutation: DELETE_TRAINING_TIME,
      variables,
      context: { accessToken },
    });
    return {
      data: data,
      error: false,
    };
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};

export const getTrainingTime = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_TRAINING_TIME,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
export const getTrainingTimeByGrID = async (variables, accessToken) => {
  try {
    const result = await client.query({
      query: GET_TRAINING_TIMES_BY_TEAM,
      variables,
      context: { accessToken },
      fetchPolicy: "no-cache",
    });
    return result;
  } catch (error) {
    return {
      data: null,
      error: true,
      message: error.message,
    };
  }
};
