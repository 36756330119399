import React, { useState, useEffect, useRef } from "react";
import DropDownIcon from "../../svg/DropDownIcon";
import { useNavigate } from "react-router-dom";
import { FansSkeleton, Model, TableManageFans } from "../../components";

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";

// graphql
import { manageFan, getAllFans } from "../../graphql/services";

//Translation
import { useTranslation } from "react-i18next";

const ManageFansLayout = () => {
  const fansTableHeadItems = ["First name", "Last name", "Edit"];

  const { t } = useTranslation();
  // const approuver = () => console.log('approuver');
  // const refuse = () => console.log('refuse');

  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  // get fans
  const [fans, setfans] = useState(null);
  const [fansTotCount, setFansTotCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [searchedFans, setSearchedFans] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const fetchFans = async (skip) => {
    setLoading(true);
    const { data, error, message } = await getAllFans(
      {
        take: pageSize,
        skip: Number(skip) ? Number(skip) * pageSize : 0,
        order: {
          createdAt: order,
        },
        where: {
          search: searchedFans,
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch fans failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setLoading(false);
      }
    } else {
      setfans(data?.fans?.nodes);
      setFansTotCount(data?.fans?.totalCount);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFans(currentPage);
  }, [order, searchedFans, currentPage]);

  const [table, setTableRow] = useState({ id: null, status: null });
  const [manageLoading, setmanageLoading] = useState(false);
  const handleManageFan = async (id, status) => {
    if (id && status) {
      setmanageLoading(true);
      const { data, error, message } = await manageFan(
        {
          updateFanInput: {
            status: status,
            id: parseInt(id),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("update fan status Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setmanageLoading(false);
        }
      } else {
        setToastSuccess(
          status === "APPROVED"
            ? t("Approve fan successfully")
            : t("Refuse fan successfully")
        );
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setmanageLoading(false);
        setTableRow({ id: null, status: null });
        fetchFans();
      }
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      <Model
        isOpen={table.id}
        title={
          table.status &&
          (table.status === "APPROVED"
            ? t("Are u sure to approve this Fans ?")
            : t("Are u sure to refuse this Fans ?"))
        }
        width={600}
      >
        <div className="w-full flex items-center gap-[1rem]">
          <button
            className="h-[40px] w-[180px] bg-[#C04337] rounded text-white mr-3 text-[12px]"
            onClick={() => handleManageFan(table.id, table.status)}
          >
            {manageLoading ? (
              <Spinner c={"text-white"} />
            ) : (
              table.status &&
              (table.status === "APPROVED" ? t("Approve") : t("Refuse"))
            )}
          </button>
          <button
            className="h-[40px] w-[180px] bg-black rounded text-white text-[12px]"
            onClick={() => setTableRow({ id: null, status: null })}
          >
            {t("Discard")}
          </button>
        </div>
      </Model>
      <div className="layoutContainer">
        <div className="flex justify-between flex-wrap gap-10">
          <div className="text-[20px] flex justify-between items-center gap-1 h-[40px]">
            {t("Fans")}
            <div className="mx-1">
              <DropDownIcon fill={"#133D6E"} />
            </div>
            {t("Manage Fans")}
          </div>
          <div className="text-[12px]"></div>
        </div>
        <div className="bg-white h-fit mt-10 px-[24px] py-[32px]">
          <form className="flex justify-between flex-wrap gap-5 text-[12px]">
            <div className="flex justify-between items-center flex-wrap gap-4">
              <span className="font-bold mb-2">{t("Search")}</span>
              <input
                type="text"
                name="Search"
                onChange={(e) => setSearchedFans(e.target.value)}
                placeholder="Search any"
                className="w-[237px] h-[40px] text-[black] bg-[#F8F8F8]  p-[10px] border border-[#D8D8D8] rounded-[4px]"
                required
              />
            </div>
          </form>
          <div className="mt-5 md:mt-10 flex flex-wrap items-center gap-5">
            <span className="font-bold">{t("Sort By")}</span>
            <div className="flex flex-wrap gap-5">
              <button
                className={`${
                  order === "ASC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("DESC")}
              >
                {t("Added date (newest)")}
              </button>
              <button
                className={`${
                  order === "DESC" ? "active-button" : "disabled-button"
                }`}
                onClick={() => setOrder("ASC")}
              >
                {t("Added date (oldest)")}
              </button>
            </div>
          </div>
          <div className="mt-10 flex flex-row flex-wrap overflow-scroll">
            {loading ? (
              <FansSkeleton />
            ) : (
              <table className="flex flex-col text-[12px]">
                <tr className="flex">
                  {fansTableHeadItems.map((item) => (
                    <th>{t(item)}</th>
                  ))}
                </tr>
                {fans && fans.lenght !== 0
                  ? fans.map((fan, i) => (
                      <TableManageFans
                        i={i}
                        fan={fan}
                        setTableRow={setTableRow}
                      />
                    ))
                  : t("there is no fans to manage at the moment")}
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageFansLayout;
