// defining your client.
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

const httpLink = createUploadLink({
  uri: "https://staged-api-floridsdorf.smartiniaclient.com/graphql",
});

const authLink = setContext((_, context) => {
  // pass accessToken to the context
  const { accessToken } = context;
  return {
    headers: {
      ...context.headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
