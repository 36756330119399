import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DropDownIcon from "../../svg/DropDownIcon";

//Translation
import { useTranslation } from "react-i18next";
import { LiveDetailSkl, RowLiveComment } from "../../components";

// graphql
import {
  getAllLives,
  addNewLive,
  deleteLive,
  getGame,
  updateGame,
} from "../../graphql/services";

// components
import Toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//hooks
import { useAuth } from "../../hooks";

//store
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/authSlice";

const LiveDetailSlugLayout = ({ liveDetailId }) => {
  const TOKEN = useSelector((state) => state.auth.access_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { access_token, refresh_token } = useSelector((state) => {
    return state.auth;
  });
  const [new_token] = useAuth(refresh_token, access_token);
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(new_token);
  }, [new_token]);

  useEffect(() => {
    if (access_token === "" && (token === null || token === "")) {
      dispatch(logout());
      navigate("/login");
    }
  }, [access_token]);

  const [errorMessage, setErrorMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [toastERROR, setToastERROR] = useState(null);
  const [toastSuccess, setToastSuccess] = useState(null);

  //toast
  const [openToast, setOpenToast] = useState(false);
  const handleClick = () => {
    setOpenToast(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
    setErrorMessage(null);
  };

  const { t } = useTranslation();

  const [form, setform] = useState({
    firstTeameGoal: 0,
    seconfTeameGoal: 0,
    goalMinute: 0,
    event: "",
    eventMinut: 0,
    player: "",
    comment: "",
  });

  const [lives, setlives] = useState(null);
  const fetchLives = async (id) => {
    setloading(true);
    const { data, error, message } = await getAllLives(
      {
        skip: 0,
        take: 100,
        where: {
          game: parseInt(id),
        },
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch live details failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
      }
    } else {
      setlives(data?.liveNews?.nodes);
      setloading(false);
    }
  };

  const [loadingEvent, setloadingEvent] = useState(false);
  const [game, setgame] = useState(null);
  const fetchGame = async (id) => {
    const { data, error, message } = await getGame(
      {
        gameId: parseInt(id),
      },
      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage("Fetch game failed");
        setToastERROR(true);
        setToastType("error");
        handleClick();
      }
    } else {
      setgame({
        firstTeame: data?.game.group.name,
        seconfTeam: data?.game.playingAgainst.name,
        secondTeamLogo: data?.game.playingAgainst.picture.full_url,
        status: data?.game.status,
      });
      setform({
        ...form,
        firstTeameGoal: data?.game.goalsFor,
        seconfTeameGoal: data?.game.goalsIn,
      });
    }
  };

  useEffect(() => {
    if (liveDetailId) {
      fetchLives(liveDetailId);
      fetchGame(liveDetailId);
    }
  }, [liveDetailId,loadingEvent]);

  const handleChange = (e) =>
    setform({ ...form, [e.target.name]: e.target.value });

  const [loading, setloading] = useState(false);
  const handleCreatreLive = async (type) => {
    if (type === "goal") {
      setloading(true);
      const { data, error, message } = await updateGame(
        {
          updateGameId: parseInt(liveDetailId),
          input: {
            goalsFor: parseInt(form.firstTeameGoal),
            goalsIn: parseInt(form.seconfTeameGoal),
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Add comment Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        const { data, error, message } = await addNewLive(
          {
            input: {
              game: parseInt(liveDetailId),
              content:
                type === "goal"
                  ? (form.goalMinute ? form.goalMinute + "' | " : "")+ 
                    game.firstTeame +
                    "  " +
                    form.firstTeameGoal +
                    " - " +
                    form.seconfTeameGoal +
                    "  " +
                    game.seconfTeam
                  : form.eventMinut +
                    "' | " +
                    form.event +
                    " " +
                    form.player +
                    " " +
                    form.comment,
            },
          },
          TOKEN
        );
        if (error) {
          if (message === "Unauthorized") {
            dispatch(logout());
            navigate("/login");
          } else {
            setErrorMessage(t("Add comment Failed"));
            setToastERROR(true);
            setToastType("error");
            handleClick();
            setloading(false);
          }
        } else {
          setToastSuccess(t("Add comment successfully"));
          setToastERROR(false);
          setToastType("success");
          handleClick();
          setloading(false);
          setloadingEvent(false);
          fetchLives(liveDetailId);
          reset();
        }
      }
    } else {
      setloadingEvent(true);
      const { data, error, message } = await addNewLive(
        {
          input: {
            game: parseInt(liveDetailId),
            content:
              type === "goal"
                ? (form.goalMinute ? form.goalMinute + "' | " : "") +
                  game.firstTeame +
                  "  " +
                  form.firstTeameGoal +
                  " - " +
                  form.seconfTeameGoal +
                  "  " +
                  game.seconfTeam
                : form.eventMinut +
                  "' | " +
                  form.event +
                  " " +
                  form.player +
                  " " +
                  form.comment,
          },
        },
        TOKEN
      );
      if (error) {
        if (message === "Unauthorized") {
          dispatch(logout());
          navigate("/login");
        } else {
          setErrorMessage(t("Add comment Failed"));
          setToastERROR(true);
          setToastType("error");
          handleClick();
          setloading(false);
        }
      } else {
        setToastSuccess(t("Add comment successfully"));
        setToastERROR(false);
        setToastType("success");
        handleClick();
        setloading(false);
        setloadingEvent(false);
        fetchLives(liveDetailId);
        reset();
      }
    }
  };
  useEffect(()=>{
    fetchGame(liveDetailId)
  },[loading])

  const reset = () => {
    setform({
      ...form,
      goalMinute: 0,
      event: "",
      eventMinut: 0,
      player: "",
      comment: "",
    });
  };

  const [startGameLoading, setStartGameLoading] = useState(false);
  const [gameIsStarted, setGameIsStarted] = useState(false);

  const handleStartGame = async (type) => {
    setStartGameLoading(true);
    const { data, error, message } = await updateGame(
      {
        input: {
          status: "PLAYING",
        },
        updateGameId: parseInt(liveDetailId),
      },

      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Update game status Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
        setStartGameLoading(false);
      }
    } else {
      setToastSuccess(t("game status updated successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setloading(false);
      setStartGameLoading(false);
      fetchLives(liveDetailId);
      fetchGame(liveDetailId);
    }
  };

  const [endGameLoading, setendGameLoading] = useState(false);
  const handleEndGame = async (type) => {
    setendGameLoading(true);
    const { data, error, message } = await updateGame(
      {
        input: {
          status: "ENDED",
        },
        updateGameId: parseInt(liveDetailId),
      },

      TOKEN
    );
    if (error) {
      if (message === "Unauthorized") {
        dispatch(logout());
        navigate("/login");
      } else {
        setErrorMessage(t("Update game status Failed"));
        setToastERROR(true);
        setToastType("error");
        handleClick();
        setloading(false);
        setStartGameLoading(false);
      }
    } else {
      setToastSuccess(t("Update game status successfully"));
      setToastERROR(false);
      setToastType("success");
      handleClick();
      setloading(false);
      setendGameLoading(false);
      fetchLives(liveDetailId);
    }
  };

  return (
    <>
      <Toast
        open={openToast}
        handleClose={handleClose}
        type={toastType}
        message={errorMessage}
        errorTxt={toastERROR}
        toastSuccess={toastSuccess}
      />
      {loading ? (
        <LiveDetailSkl />
      ) : (
        <div className="layoutContainer">
          <div className="flex flex-wrap justify-between gap-5">
            <div className="text-[20px] flex gap-2 items-center">
              <div className="flex flex-wrap items-center gap-4 sm:gap-2">
                <div className="flex items-center gap-[1rem]">
                  <div className="w-[25px]">
                    <img
                      src="/svg/Dashboard-logo.svg"
                      className="w-full"
                      alt=""
                    />
                  </div>
                  <span>
                    {game && game.firstTeame}
                    <span className="font-bold ml-2 md:mx-4">vs</span>
                  </span>
                </div>
                <div className="flex items-center gap-[.5rem]">
                  <div className="w-[30px]">
                    <img
                      src={game && game.secondTeamLogo}
                      className="w-full"
                      alt=""
                    />
                  </div>
                  <span>{game && game.seconfTeam}</span>
                </div>
              </div>
            </div>
            <div className="text-[12px] flex items-center gap-3">
              <button
                onClick={() => navigate(-1)}
                className="h-[40px] w-[150px] md:w-[100px] sm:w-[180px] border border-black rounded"
              >
                {t("Cancel")}
              </button>
              {game && game.status === "ENDED" && (
                <button
                  onClick={() => {
                    handleStartGame();
                  }}
                  className="h-[40px] w-[150px] md:w-[120px] sm:w-[180px] bg-black rounded text-white"
                >
                  {startGameLoading ? (
                    <Spinner c={"text-white"} />
                  ) : (
                    t("Edit game result")
                  )}
                </button>
              )}
              {game && game.status === "PENDING" && (
                <button
                  onClick={() => {
                    handleStartGame();
                  }}
                  className="h-[40px] w-[150px] md:w-[120px] sm:w-[180px] bg-black rounded text-white"
                >
                  {startGameLoading ? (
                    <Spinner c={"text-white"} />
                  ) : (
                    t("Start game")
                  )}
                </button>
              )}
              {game && game.status === "PLAYING" && (
                <button
                  onClick={() => {
                    handleEndGame();
                  }}
                  className="h-[40px] w-[150px] md:w-[120px] sm:w-[180px] bg-black rounded text-white"
                >
                  {endGameLoading ? (
                    <Spinner c={"text-white"} />
                  ) : (
                    t("End the game")
                  )}
                </button>
              )}
            </div>
          </div>
            {(game?.status === "PENDING") &&
            <div className=" bg-BgBlue w-fit text-center p-4 text-white rounded mt-10 flex flex-col md:flex-row items-center gap-2 justify-center">
              <InfoOutlinedIcon/>
              <p className=" font-bold">{t("This game doesn't started yet - Add game Event by clicking start game")}</p>
            </div>}
            {(game?.status === "ENDED") &&
            <div className=" bg-BgBlue w-fit text-center p-4 text-white rounded mt-10 flex flex-col md:flex-row items-center gap-2 justify-center">
              <InfoOutlinedIcon/>
              <p className=" font-bold">{t("This game has been Ended - you can adjust game if you mark wrong events")}</p>
            </div>}
          <div className={`${(game?.status === "PENDING" || game?.status === "ENDED") && "pointer-events-none cursor-not-allowed opacity-50 "}`}>
          {game && (
            <div className={`bg-white mt-10 h-fit flex flex-wrap justify-between  gap-[50px] lg:gap-10 items-center  lg:justify-start px-[16px] md:px-[24px] py-[32px]`}>
              <div className="flex flex-wrap gap-4 justify-center xl:justify-between items-center w-full">
                <div className="flex">
                  <div className="flex items-center">
                    <div className="flex flex-wrap items-center justify-center gap-3 ">
                      <span className="text-[#133D6E] text-[15px] font-[700]">
                        {game.firstTeame}
                      </span>
                      <input
                        type="number"
                        pattern="[0-9]*"
                        min="0"
                        name="firstTeameGoal"
                        value={form.firstTeameGoal || 0}
                        onChange={handleChange}
                        className=" w-[120px] md:w-[100px] h-[40px] text-[black] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] md:ml-6 ml-0 mr-2 md:mr-0"
                        required
                      />
                  </div>
                  <span className="text-[#979797] text-[24px] md:ml-3 ml-0">vs</span>
                </div>
                <div className="flex flex-wrap-reverse justify-center items-center gap-3">
                  <input
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    name="seconfTeameGoal"
                    value={form.seconfTeameGoal || 0}
                    onChange={handleChange}
                    className="w-[120px] md:w-[100px] h-[40px] text-[black] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] ml-3"
                    required
                  />
                  <span className="text-[#133D6E] w-[110px] truncate text-[15px] text-center font-[700] md:ml-3">
                    {game.seconfTeam}
                  </span>
                </div>
              </div>
              <div className="flex flex-wrap justify-center gap-5">
                <div className=" flex flex-wrap justify-center items-center gap-3">
                  <span className="text-[12px]  font-bold mr-3">Minute</span>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    name="goalMinute"
                    value={form.goalMinute}
                    onChange={handleChange}
                    className="w-[300px] md:w-[230px] h-[40px] text-[#757575] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] mb-2"
                    required
                  />
                </div>
                  <button
                    onClick={() => handleCreatreLive("goal")}
                    className="h-[40px] w-full  md:w-[120px] bg-mainBlue rounded text-white text-[12px]"
                  >
                    {loading ? <Spinner c={"text-white"} /> : t("Add")}
                  </button>
              </div>
            </div>
          </div>
        )}
        <div className="bg-white text-[12px] mt-10 h-fit w-fit md:w-full flex flex-wrap justify-between items-end px-[24px] py-[32px]">
          <div className="flex flex-wrap justify-center items-center gap-5">
            <div className="flex flex-col gap-2">
              <span className="text-[12px]">Event</span>
              <select
                name="event"
                value={form.event}
                onChange={handleChange}
                className="w-[260px] md:w-[180px] h-[40px] text-black bg-[#F8F8F8]  px-[10px] border border-[#D8D8D8] rounded-[4px]"
              >
                <option value={""} disabled>
                  select event
                </option>
                <option value="Goal">Goal</option>
                <option value="Red Card">Red Card</option>
                <option value="yellow Card">yellow Card</option>
              </select>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-[12px]">Player</span>
              <input
                type="text"
                name="player"
                value={form.player}
                onChange={handleChange}
                placeholder="Player name"
                className="w-[260px] md:w-[180px] h-[40px] text-[black] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] "
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-[12px]">Minute</span>
              <input
                type="number"
                pattern="[0-9]*"
                min="0"
                name="eventMinut"
                value={form.eventMinut}
                onChange={handleChange}
                className="w-[260px] md:w-[180px] h-[40px] text-[black] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] "
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-[12px]">Comment</span>
              <input
                type="text"
                name="comment"
                value={form.comment}
                onChange={handleChange}
                placeholder="comment"
                className="w-[260px] md:w-[180px] h-[40px] text-[black] bg-[#F8F8F8] p-[10px] border border-[#D8D8D8] rounded-[4px] "
                required
              />
            </div>
          </div>
          <button
            onClick={() => {
              handleCreatreLive("event");
            }}
            className="h-[40px] w-full  md:w-[120px] bg-mainBlue rounded text-white text-[12px] mt-5"
          >
            {loadingEvent ? <Spinner c={"text-white"} /> : t("Add")}
          </button>
        </div>
        <div className="bg-white mt-10 h-fit flex flex-wrap items-center justify-center lg:justify-start px-8 py-5">
          {lives &&
            lives.map((live, i) => (
              <RowLiveComment
                i={i}
                live={live}
                TOKEN={TOKEN}
                fetchLives={fetchLives}
              />
            ))}
        </div>
      </div>
      </div>
      )}
    </>
  );
};

export default LiveDetailSlugLayout;
